const axios = require("axios").default;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 120000,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const createEntry = (data) => instance.post("/entry", data);
const verify = (data) => instance.post("/entry/verify", data);
const getEntry = (id, data) => instance.get(`/entry/${id}`, data);
const getEntryPaymentUrl = (id) => instance.get(`/eudonet/${id}/payment-url`);
const sendEntry = (id) => instance.post(`/entry/${id}/send`);
const updateEntry = (id, data) => instance.put(`/entry/${id}`, data);
const continueEntry = (data) => instance.post(`/entry/continue`, data);
const duplicateEntry = (data) => instance.post(`/entry/duplicate`, data);
const finalizeEntry = (data) => instance.post(`/entry/finalize`, data);
const getCatalog = (catalog_id = "10403") =>
  instance.get(`/eudonet/catalog`, {
    params: { catalog_id: catalog_id },
  });
const addFile = (id, data) =>
  instance.post(`/entry/${id}/upload`, data, {
    headers: { "content-type": "multipart/form-data" },
  });
const removeFile = (id, data) =>
  instance.delete(`/entry/${id}/upload`, { params: data });

const getUserProgress = (data) =>
  instance.get("/user/progress", { params: data });

// renewal
const getRenewalCode = (data) =>
  instance.get("/renewal/code", { params: data });

const getInitialRenewalValues = () =>
  instance.get(`/eudonet/renewal-initial-values`);

const api = {
  getUserProgress,
  createEntry,
  verify,
  continueEntry,
  duplicateEntry,
  finalizeEntry,
  getEntry,
  updateEntry,
  getCatalog,
  getEntryPaymentUrl,
  addFile,
  removeFile,
  sendEntry,
  getRenewalCode,
  getInitialRenewalValues,
};
export default api;
