import { colors } from "../../../theme";
import { EFormSubType, EFormType } from "../../../types";
import steps from "./steps";
import { LogoAssurance as logoSrc } from "../../../assets/svg";

const assuranceSimpleSchema = {
  label: "Assurance",
  title: "Demande d’adhésion à la CNCEF Assurance",
  tableOfContentTitle: "Parcours d’une demande d’adhésion à la CNCEF Assurance",
  color: colors.green,
  logo: logoSrc,
  steps,
  type: EFormType.Assurance,
  subtype: EFormSubType.Simple,
  footerCertif: "Agréée ACPR",
};
export default assuranceSimpleSchema;
