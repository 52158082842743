import React from "react";
import { ETextVariant, IAttachmentLabelsSection } from "../../types";
import { Fieldset, Section, Text, List, ListItem } from "../../components";

interface Props {
  attachmentSections: IAttachmentLabelsSection[];
}
const FirstStepAttachments: React.FC<Props> = ({ attachmentSections }) => {
  return attachmentSections.length > 0 ? (
    <Section
      titleVariant={ETextVariant.Title3}
      title="Nous vous invitons à vous munir des pièces justificatives que vous devrez déposer sur votre demande en fin de parcours."
    >
      <Text variant={ETextVariant.Body2}>
        Seuls les formats DOCX, DOC, XLS, JPEG, PDF, PNG et GIF sont autorisés.
      </Text>
      {attachmentSections.map((attachmentSection, index) => (
        <Fieldset key={index}>
          <Text variant={ETextVariant.Title4}>{attachmentSection.title}</Text>
          <List>
            {attachmentSection.labels.map((label, labelIndex) =>
              label ? <ListItem key={labelIndex}>{label}</ListItem> : null
            )}
          </List>
        </Fieldset>
      ))}
    </Section>
  ) : null;
};

export default FirstStepAttachments;
