import React from "react";
import { IToggleProps } from "../../types";
import { Wrapper, Label, StyledToggle } from "./styled-components";

const Toggle: React.FC<IToggleProps> = ({ active, label, handleChange }) => {
  return (
    <Wrapper type="button" onClick={handleChange}>
      <Label active={active}>{label}</Label>
      <StyledToggle active={active} />
    </Wrapper>
  );
};

export default Toggle;
