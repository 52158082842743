import {
  IStep,
  EInputType,
  ESectionVariant,
  EHumanType,
  ETextVariant,
} from "../../../types";
import Yup from "../../../i18n/yup";
import { stringF, stringR } from "../../../helpers/validation";
import { factoryHuman } from "../common";

const step4: IStep = {
  // ÉTAPE 4
  title: "Gouvernance et dirigeants du CIF",
  description: "Renseignements sur le ou les dirigeant(s)",
  fields: [
    {
      ///// SECTION
      title: "Gouvernance du CIF (si personne morale)",
      visible: [
        {
          name: "entity__type",
          value: "Personne morale",
        },
      ],
      fields: [
        {
          initialValue: "",
          label: "Préciser l'organe de direction",
          tooltip:
            "Les organes de direction : gérants, direction financière, conseil d'administration, assemblée générale, etc.",
          required: true,
          name: "governance__direction",
          type: EInputType.TextArea,
          validation: Yup.mixed().when("entity__type", {
            is: "Personne morale",
            then: stringR,
          }),
        },
        {
          initialValue: "",
          label: "Préciser l'organe de surveillance, le cas échéant",
          name: "governance__supervision",
          type: EInputType.TextArea,
          validation: stringF,
        },
      ],
    },
    {
      ///// SECTION
      title:
        "Dirigeants du CIF au sens de l'article L.541-2 du code monétaire et financier",
      variant: ESectionVariant.NoPaddingBottom,
      tooltip:
        "Article L.541-2 du code monétaire et financier : « Les conseillers en investissements financiers personnes physiques, ainsi que les personnes physiques ayant le pouvoir de gérer ou d'administrer les personnes morales habilitées en tant que conseillers en investissements financiers répondent à des conditions d'âge et d'honorabilité fixées par décret, ainsi que des conditions de compétence professionnelle fixées par le règlement général de l'Autorité des marchés financiers. »",
      fields: [
        {
          variant: ETextVariant.Body1,
          content: (
            <>
              <br />
              Pour en savoir plus, consultez l'
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000032786696"
              >
                article L.541-2
              </a>{" "}
              du code monétaire et financier.
              <br />
              <br />
            </>
          ),
        },
        {
          variant: ETextVariant.Body2,
          content: "POUR LE OU LES DIRIGEANT(S) :",
        },
        {
          repeatable: "director_cif",
          pdfLabel: "Dirigeant",
          addButtonLabel: "Ajouter un(e) dirigeant(e)",
          fields: factoryHuman(EHumanType.DirectorCIF),
        },
      ],
    },
  ],
};
export default step4;
