import styled from "styled-components";
import { breakpoints, colors } from "../../theme";

export const Wrapper = styled.div<{ hasTitle: boolean }>`
  margin: 3rem 0;
  padding-left: 22%;
  background: linear-gradient(90deg, #eeeeef 50%, ${colors.greyLightest} 50%);

  ${(props) =>
    props.hasTitle &&
    `
    margin-top: 12rem;
    `}

  ${breakpoints.max.lg} {
    padding-left: 10rem;
  }
  ${breakpoints.max.md} {
    margin-top: 3rem;
    padding-left: 0;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  position: relative;
  margin-left: -18rem;
  margin-bottom: 4rem;

  p {
    line-height: 1.4;
  }

  ${breakpoints.max.lg} {
    margin-left: -10rem;
  }

  ${breakpoints.max.md} {
    margin-left: 0;
  }
`;
export const Content = styled.div<{ hasTitle: boolean }>`
  padding: 4rem 6rem 4rem 9rem;
  position: relative;
  background-color: ${colors.greyLightest};
  max-width: 1200px;

  ${(props) =>
    props.hasTitle &&
    `
    padding-left: 6rem;
    `}

  ${breakpoints.max.md} {
    padding: 3rem;
  }
`;

export const IconWrapper = styled.div<{ hasTitle: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  width: 7.6rem;
  height: 6.7rem;
  position: absolute;
  left: -3.8rem;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 7px 30px #aeb7ae36;

  ${(props) =>
    props.hasTitle &&
    `
    top: -5rem;
    transform: translateY(0);
    `}

  ${breakpoints.max.md} {
    display: none;
  }
`;
