import React, { useState, useEffect } from "react";
import { Button, Text, Modal } from "../../components";
import {
  ButtonVariant,
  EFieldContainerVariant,
  EInputSize,
  EInputType,
  ISMSModal,
} from "../../types";
import Yup from "../../i18n/yup";
import { StepInput } from "../../containers";
import api from "../../config/api";
import { Form, Formik } from "formik";
import { colors } from "../../theme";

const VALIDATION_SCHEMA = Yup.object().shape({
  code: Yup.number().min(1000).max(9999).required(),
});

const SMSCodeModal: React.FC<ISMSModal> = ({
  isOpen = false,
  handleClose,
  userPhone,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [initalValues, setInitalValues] = useState<{
    code: string;
    phone?: string;
  }>({
    code: "",
    phone: "",
  });

  const onSubmit = async (values: any) => {
    setErrorMessage("");
    setIsLoading(true);
    api
      .verify(values)
      .then((res) => {
        const url = res?.data?.url;
        if (url) {
          window.location.href = url;
        } else {
          handleError();
        }
      })
      .catch((error) => {
        const message = error?.response?.data?.message;
        handleError(message);
        setIsLoading(false);
      });
  };

  const handleError = (e: any = null) =>
    setErrorMessage(typeof e === "string" ? e : "Une erreur est survenue");

  useEffect(() => {
    setInitalValues({
      code: "",
      phone: userPhone,
    });
  }, [userPhone, isOpen]);

  return (
    <Modal
      title="Avant de commencer"
      isLoading={isLoading}
      open={isOpen}
      handleClose={handleClose}
    >
      <h2>Un code vient de vous être envoyé par SMS.</h2>
      {errorMessage ? <Text color={colors.red}>{errorMessage}</Text> : null}
      <Formik
        initialValues={initalValues}
        onSubmit={onSubmit}
        validationSchema={VALIDATION_SCHEMA}
        enableReinitialize
      >
        <Form>
          <StepInput
            label="Code"
            name="code"
            type={EInputType.Number}
            size={EInputSize.Regular}
            containerVariant={EFieldContainerVariant.Small}
            placeholder="XXXX"
            initialValue=""
          />
          <Button
            type="submit"
            variant={ButtonVariant.Secondary}
            mt
            isLoading={isLoading}
          >
            Continuer
          </Button>
        </Form>
      </Formik>
    </Modal>
  );
};

export default SMSCodeModal;
