import React from "react";
import { ETextVariant, EContainerVariant } from "../../types";
import {
  Content,
  TitleWrapper,
  TooltipWrapper,
  Wrapper,
} from "./styled-components";
import { Container, Tooltip, Text } from "../../components";

interface Props {
  title?: string;
  description?: string;
  tooltip?: string | React.ReactNode;
  children: React.ReactNode;
  extraMargin?: boolean;
  containerVariant?: EContainerVariant;
}

const Fieldset: React.FC<Props> = ({
  title = "",
  description = "",
  children,
  tooltip = "",
  extraMargin = false,
  containerVariant = EContainerVariant.FullWidth,
}) => {
  return (
    <Wrapper extraMargin={extraMargin}>
      <Container variant={containerVariant}>
        <Content>
          {title && (
            <TitleWrapper>
              <Text variant={ETextVariant.Title4}>{title}</Text>
              {tooltip ? (
                <TooltipWrapper>
                  <Tooltip>{tooltip}</Tooltip>
                </TooltipWrapper>
              ) : null}
            </TitleWrapper>
          )}
          {description && (
            <TitleWrapper>
              <Text variant={ETextVariant.Body1}>{description}</Text>
            </TitleWrapper>
          )}
          {children}
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Fieldset;
