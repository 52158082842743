import React from "react";
import { EInputSize, EInputType, IOption } from "../../types";
import { Wrapper } from "./styled-components";
import { OptionGroupItem } from "../../components";

interface Props {
  disabled?: boolean;
  name: string;
  type: EInputType.Checkbox | EInputType.Radio;
  options?: IOption[];
  size?: EInputSize;
}
const OptionGroup: React.FC<Props> = ({
  name,
  options = [],
  disabled = false,
  type,
  size = EInputSize.Regular,
}) => {
  return (
    <Wrapper>
      {options.map((option, index) => (
        <OptionGroupItem
          key={index}
          name={name}
          type={type}
          value={option.value}
          tooltip={option.tooltip}
          disabled={disabled}
          label={option.label}
          size={size}
        />
      ))}
    </Wrapper>
  );
};

export default OptionGroup;
