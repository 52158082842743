import styled from "styled-components";
import { breakpoints, colors, fonts } from "../../theme";
import { CheckWhiteIcon } from "../../assets/svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 12rem;
`;

export const Label = styled.p<{ active: boolean; completed: boolean }>`
  color: ${colors.greyLight};
  font-size: ${fonts.size.m};
  line-height: 1.3;
  text-align: center;
  margin-bottom: 0.8rem;

  ${(props) =>
    props.active &&
    `
    color: ${colors.primary};
    font-size: ${fonts.size.l};
    font-weight: 700;
  `}

  ${(props) =>
    props.completed &&
    `
        color: ${props.theme.colors.accent};
      `}

  ${breakpoints.max.md} {
    display: none;
  }
`;
export const Index = styled.div<{ active: boolean; completed: boolean }>`
  font-size: ${fonts.size.l};
  font-weight: ${fonts.weight.heading};
  text-align: center;
  display: block;
  background-color: ${colors.greyLight};
  color: ${colors.white};
  border-radius: 100%;
  width: 3.8rem;
  height: 3.8rem;
  line-height: 3.8rem;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: url(${CheckWhiteIcon}) 50% 50% no-repeat;
    background-size: 1.8rem;
    background-color: ${(props) => props.theme.colors.accent};
    opacity: ${(props) => (props.completed ? 1 : 0)};
    transition: opacity 0.2s ease;
  }

  ${(props) =>
    props.active &&
    `
			border-color: ${props.theme.colors.accent};
      background-color: ${props.theme.colors.accent};
		`}

  ${(props) =>
    props.completed &&
    `
        &:before{
          opacity:1;
        }
      `}
  
  ${breakpoints.max.md} {
    box-sizing: content-box;
    border: solid 0.3rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-color: ${(props) =>
      props.active || props.completed
        ? props.theme.colors.accent
        : colors.greyLight};
    color: ${(props) =>
      props.active
        ? props.theme.colors.accent
        : props.completed
        ? colors.white
        : colors.white};

    background-color: ${(props) =>
      props.active
        ? colors.white
        : props.completed
        ? props.theme.colors.accent
        : colors.greyLight};

    &:before {
      display: none;
    }
  }
`;
