import React from "react";
import { Field } from "formik";
import { EInputSize, EInputType } from "../../types";
import {
  Wrapper,
  Label,
  Input,
  Check,
  Text,
  TooltipWrapper,
} from "./styled-components";
import { Tooltip } from "../../components";

interface Props {
  disabled?: boolean;
  name: string;
  type?: EInputType.Checkbox | EInputType.Radio;
  tooltip?: string | React.ReactNode;
  label?: string | React.ReactNode;
  value: string;
  size?: EInputSize;
  center?: boolean;
}
const OptionGroupItem: React.FC<Props> = ({
  name,
  value,
  tooltip = "",
  label = "",
  disabled = false,
  center = false,
  type = EInputType.Radio,
  size = EInputSize.Regular,
}) => {
  return (
    <Wrapper center={center} size={size}>
      <Field type={type} name={name} value={value} disabled={disabled}>
        {({ field }: any) => (
          <Label disabled={disabled}>
            <Input {...field} disabled={disabled} type={type} />
            <Check checked={field.checked} disabled={disabled} type={type} />
            {label ? (
              <Text checked={field.checked} disabled={disabled}>
                {label}
              </Text>
            ) : null}
          </Label>
        )}
      </Field>
      {tooltip ? (
        <TooltipWrapper>
          <Tooltip>{tooltip}</Tooltip>
        </TooltipWrapper>
      ) : null}
    </Wrapper>
  );
};

export default OptionGroupItem;
