import { colors } from "../../../theme";
import { EFormSubType, EFormType } from "../../../types";
import factorySteps from "../common/renewal/factorySteps";
import {
  LogoAssuranceCredit as logoSrc,
  Logo as logoFooterSrc,
} from "../../../assets/svg";

const assuranceCreditRenewalSchema = {
  label: "Assurance & Crédit",
  title: (
    <>
      Renouvellement de vos adhésions
      <br />
      aux associations professionnelles
      <br />
      CNCEF Assurance & CNCEF Crédit
    </>
  ),
  tableOfContentTitle:
    "Parcours d’un renouvellement aux associations CNCEF Assurance & CNCEF Crédit",
  color: colors.greenLight,
  logo: logoSrc,
  footerLogo: logoFooterSrc,
  steps: factorySteps(EFormType.AssuranceCredit),
  type: EFormType.AssuranceCredit,
  subtype: EFormSubType.Renewal,
  footerCertif: "Associations professionnelles agréées par l'ACPR",
};
export default assuranceCreditRenewalSchema;
