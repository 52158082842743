import React, { Suspense } from "react";
import { EIconNames } from "../../types/icons";

interface Props {
  width?: number;
  height?: number;
  color?: string;
  name?: EIconNames;
}

const Icon: React.FC<Props> = ({
  width = 20,
  height = 20,
  name = EIconNames.Add,
  color = "#000",
}) => {
  const IconSource = React.lazy(() => import(`./svg/${name}`));
  return name ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      aria-labelledby={name}
      role="presentation"
    >
      <g fill={color} stroke={color}>
        <Suspense fallback={<g />}>
          <IconSource />
        </Suspense>
      </g>
    </svg>
  ) : null;
};

export default Icon;
