import React from "react";
import {
  EIconNames,
  ESectionSize,
  ESectionVariant,
  ETextVariant,
} from "../../types";
import {
  Content,
  TitleWrapper,
  TooltipWrapper,
  IconWrapper,
  Wrapper,
} from "./styled-components";
import { Tooltip, Text, Container, Icon } from "../../components";
import { useTheme } from "styled-components";

interface Props {
  title?: string;
  tooltip?: string | React.ReactNode;
  children: React.ReactNode;
  variant?: ESectionVariant;
  size?: ESectionSize;
  titleVariant?:
    | ETextVariant.Title1
    | ETextVariant.Title3
    | ETextVariant.Title5;
  titleIcon?: EIconNames;
}

const Section: React.FC<Props> = ({
  title = "",
  children,
  tooltip = "",
  variant = ESectionVariant.Primary,
  size = ESectionSize.FullWidth,
  titleVariant = ETextVariant.Title1,
  titleIcon = null,
}) => {
  const theme = useTheme();
  const ConditionalContainer =
    size === ESectionSize.FullWidth ? Container : "div";

  const getTitleColor = () => {
    if (variant === ESectionVariant.Tertiary) {
      return theme.colors.accent;
    } else {
      return theme.colors.primary;
    }
  };
  return (
    <Wrapper size={size}>
      <ConditionalContainer>
        <Content withTitle={!!title} variant={variant}>
          {title && (
            <TitleWrapper titleVariant={titleVariant} sectionVariant={variant}>
              <Text variant={titleVariant} color={getTitleColor()}>
                {title}
              </Text>
              {tooltip ? (
                <TooltipWrapper>
                  <Tooltip>{tooltip}</Tooltip>
                </TooltipWrapper>
              ) : null}
              {titleIcon ? (
                <IconWrapper sectionVariant={variant}>
                  <Icon
                    name={titleIcon}
                    width={32}
                    height={32}
                    color={theme.colors.accent}
                  />
                </IconWrapper>
              ) : null}
            </TitleWrapper>
          )}
          {children}
        </Content>
      </ConditionalContainer>
    </Wrapper>
  );
};

export default Section;
