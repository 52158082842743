import styled from "styled-components";
import { colors, fonts, breakpoints } from "../../theme";

export const Wrapper = styled.div`
  position: relative;
  text-align: left;
`;
export const TooltipWrapper = styled.div<{ isOpen: boolean }>`
  background-color: ${colors.white};
  box-shadow: 0px 7px 30px #aeb7ae36;
  width: 25rem;
  max-width: 90vw;
  padding: 2rem;
  line-height: 1.6;
  font-size: ${fonts.size.s};
  position: absolute;
  display: ${(props) => (props.isOpen ? "block" : "none")};
  left: 50%;
  bottom: calc(100% + 2.2rem);
  transform: translateX(-50%);
  z-index: 2;

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  ${breakpoints.max.md} {
    position: fixed;
    bottom: auto;
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

export const IconWrapper = styled.button`
  font-size: 0;
  cursor: pointer;
`;
