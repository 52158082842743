import {
  IStep,
  EInputType,
  EContainerVariant,
  ETextVariant,
  EInputSize,
} from "../../../types";
import Yup from "../../../i18n/yup";
import { stringR, checkboxGroup } from "../../../helpers/validation";
import { regionsAndFrance, boolOptions } from "../common/options";

const step5: IStep = {
  // ÉTAPE 5
  title: "Périmètre d’activité",
  description: "Détail sur la nature de votre activité",
  fields: [
    {
      ///// SECTION
      title: "Périmètre d’activité",
      fields: [
        {
          initialValue: "",
          label: "Activité principale",
          required: true,
          name: "activities__is_main",
          type: EInputType.Radio,
          options: boolOptions,
          validation: stringR,
        },
        {
          fieldsetTitle: "Type(s) d'activité proposée *",
          fields: [
            {
              initialValue: "",
              label: "",
              pdfLabel: "Type",
              name: "activities__immo__types",
              type: EInputType.Checkbox,
              size: EInputSize.Large,
              options: [
                {
                  label: "Expert en immobilier défiscalisant",
                  value: "Expert en immobilier défiscalisant",
                },
                {
                  label: "Administrateur de biens",
                  value: "Administrateur de biens",
                },
                { label: "Aménageur foncier", value: "Aménageur foncier" },
                {
                  label: "Conseil en gestion de patrimoine",
                  value: "Conseil en gestion de patrimoine",
                },
                {
                  label: "Conseil en immobilier d’entreprise",
                  value: "Conseil en immobilier d’entreprise",
                },
                {
                  label: "Diagnostiqueur immobilier",
                  value: "Diagnostiqueur immobilier",
                },
                { label: "Marchand de biens", value: "Marchand de biens" },
                { label: "Promoteur", value: "Promoteur" },
                {
                  label: "Syndic de copropriété",
                  value: "Syndic de copropriété",
                },
                { label: "Autre", value: "Autre" },
              ],
              validation: checkboxGroup,
            },
            {
              initialValue: "",
              label: "Si autre, précisez",
              name: "activities__has_other__type",
              type: EInputType.Text,
              validation: Yup.string(),
              visible: [
                {
                  name: "activities__immo__types",
                  value: "Autre",
                },
              ],
            },
          ],
        },
        {
          initialValue: "",
          label: "Régions dans lesquelles vous exercez votre activité",
          required: true,
          name: "activities__region",
          placeholder: "Choisissez une région",
          type: EInputType.Select,
          options: regionsAndFrance,
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Présentation de l'activité",
      fields: [
        {
          fieldsetTitle: "",
          containerVariant: EContainerVariant.Small,
          fields: [
            {
              variant: ETextVariant.Body1,
              content:
                "Fournir une description narrative des activités envisagées qui fasse apparaître à minima :",
            },
            {
              listItems: [
                "Les motivations de la demande d’adhésion",
                "La représentativité de l’activité Immobilière au sein de la structure",
                "La liste de vos partenaires bancaires",
                "Le ou les outil(s) utilisé(s)",
                "Comment vous nous avez connus",
              ],
            },
          ],
        },
        {
          initialValue: "",
          label: "Description",
          name: "activities___description",
          type: EInputType.TextArea,
          validation: Yup.string(),
        },
      ],
    },
  ],
};
export default step5;
