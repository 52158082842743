import React from "react";
import { useTheme } from "styled-components";
import { EIconNames, ETextVariant } from "../../types";
import { Fieldset, BigSection, Text } from "../../components";
import FormHelper from "../../helpers/FormHelper";

interface Props {
  formHelper: FormHelper;
}
const FirstStepTableOfContents: React.FC<Props> = ({ formHelper }) => {
  const theme = useTheme();
  const title = formHelper.tableOfContentTitle;
  return (
    <BigSection color={formHelper.color} icon={EIconNames.Eye} title={title}>
      {formHelper.steps.map((step, index) =>
        index === 0 ? null : (
          <Fieldset key={index}>
            <Text variant={ETextVariant.Title4} color={theme.colors.accent}>
              Étape {index} : {step.title}
            </Text>
            <Text>{step.description}</Text>
          </Fieldset>
        )
      )}
      {formHelper.parcoursDescription && (
        <Text variant={ETextVariant.Body2}>
          {formHelper.parcoursDescription}
        </Text>
      )}
    </BigSection>
  );
};

export default FirstStepTableOfContents;
