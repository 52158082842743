import React from "react";
import { Box, Button, Modal } from "../components";
import { ButtonVariant } from "../types";

interface Props {
  handleClose: () => void;
  hasEntryInProgress?: boolean;
  hasEntryCompleted?: boolean;
  continueEntry: () => void;
  createEntry: () => void;
  duplicateEntry: () => void;
  isLoading?: boolean;
  isOpen?: boolean;
}

const StartModal: React.FC<Props> = ({
  isOpen = false,
  handleClose,
  hasEntryCompleted = false,
  hasEntryInProgress = false,
  continueEntry,
  createEntry,
  duplicateEntry,
  isLoading = false,
}) => {
  return (
    <Modal
      title="Avant de commencer"
      isLoading={isLoading}
      open={isOpen}
      handleClose={handleClose}
    >
      <h2>
        Il semble que vous avez déjà commencé et/ou terminé un formulaire.
      </h2>
      <Box jc="space-between">
        {hasEntryInProgress ? (
          <Button mt onClick={continueEntry}>
            Reprendre le formulaire en cours
          </Button>
        ) : (
          hasEntryCompleted && (
            <>
              <Button mt onClick={duplicateEntry}>
                Commencer un nouveau formulaire prérempli
              </Button>
              <Button
                mt
                variant={ButtonVariant.Secondary}
                onClick={createEntry}
              >
                Commencer un nouveau formulaire
              </Button>
            </>
          )
        )}
      </Box>
    </Modal>
  );
};

export default StartModal;
