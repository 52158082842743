import styled from "styled-components";
import { breakpoints, colors } from "../../theme";

export const Wrapper = styled.div<{ completion: number }>`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  margin: 5.5rem auto;

  ${breakpoints.max.md} {
    margin: 2.5rem auto;
  }

  &:after,
  &:before {
    content: "";
    height: 0.6rem;
    position: absolute;
    z-index: -2;
    width: 100%;
    left: 0%;
    background-color: ${colors.greyLight};
    bottom: 1.4rem;
    transition: all 0.2s ease;

    ${breakpoints.max.md} {
      bottom: 1.2rem;
    }
  }
  &:after {
    width: ${(props) => props.completion}px;
    background-color: ${(props) => props.theme.colors.accent};
  }
`;

export const Corner = styled.span<{ first?: boolean }>`
  height: 2rem;
  position: absolute;
  bottom: 1.4rem;
  background-color: white;
  ${(props) =>
    props.first
      ? `
        width: 2rem;
        left: 0;
      `
      : `
        width: 5rem;
        right: 0;
        z-index:-1;
      `}

  ${breakpoints.max.md} {
    display: none;
  }
`;
