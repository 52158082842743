import { colors } from "../../../theme";
import { EFormSubType, EFormType } from "../../../types";
import steps from "./steps";
import { Logo as logoSrc } from "../../../assets/svg";

const cncefSchema = {
  label: "",
  title: "Demande d’adhésion à la CNCEF",
  tableOfContentTitle: "Parcours d’une demande d’adhésion à la CNCEF",
  color: colors.primary,
  logo: logoSrc,
  steps,
  type: EFormType.Cncef,
  subtype: EFormSubType.Classic,
};
export default cncefSchema;
