import styled from "styled-components";
import { breakpoints, colors } from "../../theme";
import { ESectionSize, ESectionVariant, ETextVariant } from "../../types";

export const TooltipWrapper = styled.div`
  font-size: 0;
  margin-left: 2rem;
  margin-top: 1.1rem;
`;
export const Wrapper = styled.div<{ size: ESectionSize }>`
  margin: 5rem 0;

  table {
    margin: 5rem -3rem;
  }

  ${(props) =>
    props.size === ESectionSize.Half &&
    `
      width: 50%;
      margin: 2rem auto 0 auto;

      ${breakpoints.max.md} {
        margin: 2rem auto;
        width: 100%;
      }
    `}
`;
export const TitleWrapper = styled.div<{
  titleVariant: ETextVariant;
  sectionVariant: ESectionVariant;
}>`
  display: flex;
  position: relative;

  ${(props) =>
    props.sectionVariant === ESectionVariant.Tertiary &&
    `
      flex-direction: column;
      align-items: center;
      margin-bottom: 6rem;
    `}
`;
export const Content = styled.div<{
  variant: ESectionVariant;
  withTitle: boolean;
}>`
  padding: 4rem 3rem;
  position: relative;
  background-color: ${colors.greyLightest};

  ${(props) =>
    props.withTitle &&
    `
      padding-top: 0;

      &:before {
        position: absolute;
        z-index: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 2.2rem;
        background-color: ${colors.white};
        margin-right: 1.5rem;
      }
  `}
  ${(props) =>
    props.variant === ESectionVariant.NoPaddingBottom &&
    `
      padding-bottom: 0;
    `}

    ${(props) =>
    props.variant === ESectionVariant.Secondary &&
    `
      padding-top: 3rem;
      background-color: ${colors.white};
        ${breakpoints.max.md} {
          padding-top: 0;
        }
    `}

    ${(props) =>
    props.variant === ESectionVariant.Tertiary &&
    `
      padding-bottom: 6rem;
      text-align:center;
      &:before {
        height: 5.2rem;
      }
    `}
 

  & > :last-child {
    margin-bottom: 0;
  }
`;

export const IconWrapper = styled.div<{
  sectionVariant: ESectionVariant;
}>`
  margin-left: 2rem;

  ${(props) =>
    props.sectionVariant === ESectionVariant.Tertiary &&
    `
    margin-left: 0;
    `}
`;
