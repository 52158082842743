import { EFormType } from "../../../../types";
import { step1 } from "../../common/";
import factoryRenewalStep1 from "./factoryRenewalStep1";
import factoryRenewalStep2 from "./factoryRenewalStep2";
import factoryRenewalStep3 from "./factoryRenewalStep3";

const factorySteps = (type: EFormType = EFormType.Credit) => [
  step1,
  factoryRenewalStep1(type),
  factoryRenewalStep2(type),
  factoryRenewalStep3(type),
];

export default factorySteps;
