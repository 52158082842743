import { IStep, EInputType, EInputSize } from "../../../types";
import Yup from "../../../i18n/yup";
import {
  checkboxGroup,
  date,
  oriasRegistrationNumberR,
  stringR,
} from "../../../helpers/validation";
import { boolOptions } from "../common/options";
import { dateMask } from "../../../helpers/masks";

const step3: IStep = {
  title:
    "Activité antérieure de conseiller en investissements financiers (en tant que dirigeants/salariés CIF)",
  description: "Renseignements sur votre activité antérieure.",
  fields: [
    {
      ///// SECTION
      title: "Activité",
      fields: [
        {
          initialValue: "",
          label: "Avez-vous une activité antérieure ?",
          required: true,
          name: "previous_activity__was_CIF",
          type: EInputType.Radio,
          options: boolOptions,
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Dénomination Orias",
          required: true,
          name: "previous_activity__denomiation_orias",
          tooltip:
            "Telle qu'elle apparaît sur le site de l'Orias ou votre attestation",
          type: EInputType.Text,
          validation: Yup.mixed().when("previous_activity__was_CIF", {
            is: "Oui",
            then: stringR,
          }),
          visible: [
            {
              name: "previous_activity__was_CIF",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "Numéro d'immatriculation Orias",
          required: true,
          name: "previous_activity__registration_number_orias",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: Yup.mixed().when("previous_activity__was_CIF", {
            is: "Oui",
            then: oriasRegistrationNumberR,
          }),
          visible: [
            {
              name: "previous_activity__was_CIF",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "Nom de l’association professionnelle précédente",
          required: true,
          name: "previous_activity__association_name",
          placeholder: "Nom de l’association précédente",
          type: EInputType.Text,
          validation: Yup.mixed().when("previous_activity__was_CIF", {
            is: "Oui",
            then: stringR,
          }),
          visible: [
            {
              name: "previous_activity__was_CIF",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "Date d'adhésion à l'association professionnelle précédente",
          required: true,
          name: "previous_activity__subscription_date",
          placeholder: "JJ / MM / AAAA",
          type: EInputType.Text,
          size: EInputSize.Small,
          mask: dateMask,
          validation: Yup.mixed().when("previous_activity__was_CIF", {
            is: "Oui",
            then: date,
          }),
          visible: [
            {
              name: "previous_activity__was_CIF",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: [],
          label: "Préciser les raisons de l'arrêt du statut CIF",
          required: true,
          name: "previous_activity__cause",
          type: EInputType.Checkbox,
          size: EInputSize.Large,
          options: [
            {
              label: "Changement d’association",
              value: "Changement d’association",
              tooltip:
                "Dans cette situation, le programme d’activité antérieurement vérifié par l’ancienne association doit être transmis. Dans ce cas, le CIF doit renseigner uniquement la fiche de changement d’association professionnelle (transmise obligatoirement par l’association sortante) lors de la dernière étape.",
            },
            {
              label: "Arrêt d’activité",
              value: "Arrêt d’activité",
            },
          ],
          validation: Yup.mixed().when("previous_activity__was_CIF", {
            is: "Oui",
            then: checkboxGroup,
          }),
          visible: [
            {
              name: "previous_activity__was_CIF",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label:
            "Avez-vous été mis en cause directement ou indirectement lors d'un contrôle dans cette activité antérieure par votre association ou l'AMF ?",
          name: "previous_activity__was_controlled",
          type: EInputType.Radio,
          options: boolOptions,
          required: true,
          validation: Yup.mixed().when(
            ["previous_activity__was_CIF", "previous_activity__cause"],
            {
              is: (
                previous_activity__was_CIF: string,
                previous_activity__cause: string[]
              ) => {
                return (
                  previous_activity__was_CIF === "Oui" &&
                  previous_activity__cause.includes("Changement d’association")
                );
              },
              then: stringR,
            }
          ),
          visible: [
            {
              name: "previous_activity__was_CIF",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label:
            "Le cas échéant, avez-vous été licencié pour manquement aux règles professionnelles applicables à l’activité de CIF ?",
          name: "previous_activity__was_fired",
          type: EInputType.Radio,
          options: boolOptions,
          required: true,
          validation: Yup.mixed().when(
            [
              "previous_activity__was_CIF",
              "previous_activity__cause",
              "previous_activity__was_controlled",
            ],
            {
              is: (
                previous_activity__was_CIF: string,
                previous_activity__cause: string[],
                previous_activity__was_controlled: string
              ) =>
                previous_activity__was_CIF === "Oui" &&
                previous_activity__cause.includes("Changement d’association") &&
                previous_activity__was_controlled === "Oui",
              then: stringR,
            }
          ),
          visible: [
            {
              name: "previous_activity__was_CIF",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "Si arrêt d’activité du statut CIF, préciser le motif",
          name: "previous_activity__cessation_cause",
          placeholder: "Description du motif…",
          type: EInputType.TextArea,
          visible: [
            {
              name: "previous_activity__cause",
              value: "Arrêt d’activité",
            },
          ],
        },
      ],
    },
  ],
};

export default step3;
