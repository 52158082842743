import React from "react";
import { Lottie } from "@crello/react-lottie";
import { ELotties } from "../../types";
import { Wrapper } from "./styled-components";

interface Props {
  url: ELotties;
  loop?: boolean;
  width?: number;
  height?: number;
}

const LottieContainer: React.FC<Props> = ({
  url,
  loop = false,
  width = 120,
  height = 120,
}) => {
  return (
    <Wrapper>
      <Lottie
        config={{
          animationData: null,
          path: url,
          autoplay: true,
          loop,
        }}
        height={`${height}px`}
        width={`${width}px`}
      />
    </Wrapper>
  );
};

export default LottieContainer;
