import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ConfirmationModalProvider } from "./context/ConfirmationModalContext";
import reportWebVitals from "./reportWebVitals";
import Theme from "./theme/Theme";
import { ThemeProvider } from "./context/ThemeContext";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Theme>
        <ConfirmationModalProvider>
          <App />
        </ConfirmationModalProvider>
      </Theme>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
