import {
  IStep,
  EInputType,
  ETextVariant,
  EFieldContainerVariant,
  ESectionVariant,
  EContainerVariant,
} from "../../../types";
import {
  stringR,
  checkboxGroup,
  files,
  filesR,
} from "../../../helpers/validation";
import { constants } from "../common";

const step8: IStep = {
  // ÉTAPE 8
  title: "Pièces justificatives",
  description:
    "Transmission des pièces justificatives et finalisation de la demande",
  fields: [
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          variant: ETextVariant.Body1,
          content: "J'atteste sur l'honneur",
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__1",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "Ne faire l’objet d’aucune procédure contentieuse ou disciplinaire en cours *",
              value:
                "Ne faire l’objet d’aucune procédure contentieuse ou disciplinaire en cours *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__2",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "Je m’engage à informer la CNCEF de tout élément de nature à affecter ma situation *",
              value:
                "Je m’engage à informer la CNCEF de tout élément de nature à affecter ma situation *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__3",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "Je reconnais avoir pris connaissance des statuts (disponibles sur le site www.cncef.org), du code de bonne conduite que j’approuve et m’engage à respecter *",
              value:
                "Je reconnais avoir pris connaissance des statuts (disponibles sur le site www.cncef.org), du code de bonne conduite que j’approuve et m’engage à respecter *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label:
            "J’autorise la CNCEF à faire figurer mes coordonnées dans l’annuaire de la CNCEF",
          required: true,
          name: "authorization__coordinates",
          type: EInputType.Radio,
          containerVariant: EFieldContainerVariant.BigLabel,
          options: [
            {
              label: "Oui",
              value: "Oui",
            },
            {
              label: "Non",
              value: "Non",
            },
          ],
          validation: stringR,
        },
      ],
    },

    {
      ///// SECTION
      title: "Pour la structure",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        {
          initialValue: [],
          label:
            "Extrait Kbis datant de moins de 3 mois si inscrit au Registre du commerce et des sociétés",
          required: true,
          name: "file__structure__kbis",
          type: EInputType.File,
          validation: filesR,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Avis de situation SIRENE si non-inscrit au Registre du commerce et des sociétés",
          name: "file__structure__siren",
          type: EInputType.File,
          validation: filesR,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: "Attestation assurance RCP",
          required: true,
          name: "file__structure__rcp",
          type: EInputType.File,
          validation: filesR,
          max: constants.maxFile,
        },
      ],
    },
    {
      ///// SECTION
      title: "Pour le ou les dirigeant (s) et salarié (s)",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        {
          initialValue: [],
          label: "Copie du ou des diplômes",
          name: "file__human__degree",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Copie d'une pièce d'identité recto / verso en cours de validité",
          name: "file__human__id",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: "Déclaration d'honorabilité",
          name: "file__human__declaration",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
      ],
    },
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__4",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "J’atteste sur l’honneur que toutes les données indiquées sur le dossier d’adhésion, rempli par mes soins, sont véridiques *",
              value:
                "J’atteste sur l’honneur que toutes les données indiquées sur le dossier d’adhésion, rempli par mes soins, sont véridiques *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__5",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "En cochant cette case, j’accepte les conditions d’adhésion demandées par la CNCEF. *",
              value:
                "En cochant cette case, j’accepte les conditions d’adhésion demandées par la CNCEF. *",
            },
          ],
          validation: checkboxGroup,
        },
      ],
    },
  ],
};

export default step8;
