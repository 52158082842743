import { IStep, EInputType, EInputSize } from "../../../types";
import { regionsAndFrance } from "../common/options";
import { factoryActivities } from "../common";

const step6: IStep = {
  title: "Autres activités",
  description: "Renseignements sur vos autres activités réglementées",
  fields: [
    {
      ///// SECTION
      title: "Activités",
      fields: [...factoryActivities],
    },
    {
      ///// SECTION
      title: "Autre activités",
      fields: [
        {
          initialValue: [],
          label: "",
          pdfLabel: "Activités",
          name: "other_activities",
          type: EInputType.Checkbox,
          size: EInputSize.Large,
          options: [
            {
              label: "Conseil en stratégie",
              value: "Conseil en stratégie",
            },
            {
              label:
                "Analyse financière, établissement de business plans et évaluation",
              value:
                "Analyse financière, établissement de business plans et évaluation",
            },
            {
              label:
                "Conseil en fusions, acquisitions et autres formes de rapprochement",
              value:
                "Conseil en fusions, acquisitions et autres formes de rapprochement",
            },
            {
              label: "Accompagnement en levée de fonds",
              value: "Accompagnement en levée de fonds",
            },
            {
              label:
                "Conseil en gestion de trésorerie et structuration d’opérations de financement de bas de bilan",
              value:
                "Conseil en gestion de trésorerie et structuration d’opérations de financement de bas de bilan",
            },
            {
              label: "Conseil en gestion opérationnelle",
              value: "Conseil en gestion opérationnelle",
            },
            { label: "Aucune autre activé", value: "Aucune autre activé" },
          ],
        },
        {
          initialValue: "",
          label: "Régions dans lesquelles vous exercez votre activité ",
          name: "activities__region",
          placeholder: "Choisissez une région",
          type: EInputType.Select,
          options: regionsAndFrance,
        },
      ],
    },
  ],
};

export default step6;
