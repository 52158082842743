import { colors } from "../../../theme";
import { EFormSubType, EFormType } from "../../../types";
import steps from "./steps";
import { LogoAssurance as logoSrc } from "../../../assets/svg";

const assuranceFinalSchema = {
  label: "Assurance",
  title: "Finalisation de votre demande d’adhésion à la CNCEF Crédit",
  tableOfContentTitle: "Parcours d’une demande d’adhésion à la CNCEF Crédit",
  color: colors.green,
  logo: logoSrc,
  steps,
  type: EFormType.Assurance,
  subtype: EFormSubType.Final,
  footerCertif: "Agréée ACPR",
};
export default assuranceFinalSchema;
