import { colors } from "../../../theme";
import { EFormSubType, EFormType } from "../../../types";
import factorySteps from "../common/renewal/factorySteps";
import { LogoCredit as logoSrc } from "../../../assets/svg";

const creditRenewalSchema = {
  label: "Crédit",
  title:
    "Renouvellement de votre adhésion à l'association professionnelle CNCEF Crédit",
  tableOfContentTitle:
    "Parcours d’un renouvellement à l'association CNCEF Crédit",
  color: colors.greenLight,
  logo: logoSrc,
  steps: factorySteps(EFormType.Credit),
  type: EFormType.Credit,
  subtype: EFormSubType.Renewal,
  footerCertif: "Association professionnelle agréée par l'ACPR",
};
export default creditRenewalSchema;
