import {
  IStep,
  EInputType,
  EInputSize,
  ETextVariant,
  ESectionVariant,
  EContainerVariant,
} from "../../../types";
import Yup from "../../../i18n/yup";
import { stringR } from "../../../helpers/validation";

const step3bis: IStep = {
  title: "Actionnariat et participations",
  description:
    "Renseignements sur l'actionnariat et la participation au sein de votre structure. ",
  fields: [
    {
      ///// SECTION
      title: "Actionnariat et participations",
      fields: [
        {
          initialValue: "",
          label: "Montant du capital social",
          required: true,
          name: "share_holding_and_participation__capital",
          placeholder: "000 000 000 000",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Actionnariat (Si personne morale)",
      variant: ESectionVariant.NoPaddingBottom,
      visible: [
        {
          name: "entity__type",
          value: "Personne morale",
        },
      ],
      fields: [
        {
          fieldsetTitle: "",
          containerVariant: EContainerVariant.Small,
          fields: [
            {
              variant: ETextVariant.Body1,
              content: (
                <>
                  Mentionner l’identité des actionnaires de la société, directs
                  ou indirects, personnes physiques ou morales, qui détiennent
                  une{" "}
                  <strong>
                    participation qualifiée<sup>1</sup>
                  </strong>
                  , c’est-à-dire supérieure à 10 % du capital ou des droits de
                  vote ou conférant une influence notable.
                </>
              ),
            },
            {
              variant: ETextVariant.Notice,
              content: (
                <>
                  <strong>
                    <sup>1</sup>Participation qualifiée
                  </strong>{" "}
                  : le fait de détenir, dans une entreprise d’investissement,
                  une participation directe ou indirecte qui représente au moins
                  10 % du capital ou des droits de vote, conformément aux
                  articles 9 et 10 de la directive 2004/109/CE du Parlement
                  européen et du Conseil, compte tenu des conditions régissant
                  leur agrégation énoncées à l’article 12, paragraphes 4 et 5,
                  de ladite directive, ou qui permet d’exercer une influence
                  notable sur la gestion de l’entreprise d’investissement dans
                  laquelle est détenue la participation.
                </>
              ),
            },
          ],
        },
        {
          repeatable: "share_holding_legal_person",
          pdfLabel: "Personne ou entreprise",
          addButtonLabel: "Ajouter une personne ou une entreprise",
          removeButtonLabel: "Supprimer",
          validation: Yup.mixed().when("entity__type", {
            is: "Personne morale",
            then: Yup.array().of(
              Yup.object().shape({
                denomination: stringR,
                repartition_share: stringR,
                repartition_capital_percent: stringR,
                repartition_vote: stringR,
                head_office_location: stringR,
              })
            ),
          }),
          fields: [
            {
              initialValue: "",
              label:
                "Dénomination sociale pour les entreprises et nom, prénom pour les personnes physiques",
              required: true,
              name: "denomination",
              placeholder: "",
              type: EInputType.TextArea,
            },
            {
              initialValue: "",
              label: "Répartition en nombre de parts ou d’actions",
              required: true,
              name: "repartition_share",
              placeholder: "000",
              type: EInputType.Text,
              size: EInputSize.Small,
            },
            {
              initialValue: "",
              label: "Répartition en % du capital",
              required: true,
              name: "repartition_capital_percent",
              placeholder: "10%",
              type: EInputType.Text,
              size: EInputSize.Small,
            },
            {
              initialValue: "",
              label: "Répartition en % des droits de vote",
              required: true,
              name: "repartition_vote",
              placeholder: "000",
              type: EInputType.Text,
              size: EInputSize.Small,
            },
            {
              initialValue: "",
              label:
                "Lieu du siège social ou nationalité des actionnaires ou associés",
              required: true,
              name: "head_office_location",
              placeholder: "France",
              type: EInputType.Text,
              size: EInputSize.Large,
            },
          ],
        },
      ],
    },
    {
      ///// SECTION
      title: "Participations",
      fields: [
        {
          fieldsetTitle: "",
          containerVariant: EContainerVariant.Small,
          fields: [
            {
              variant: ETextVariant.Body1,
              content:
                "Lorsque la société détient des participations ou des filiales (directes et indirectes), le programme d’activité décrit les activités des entreprises détenues, les relations d’affaires et les moyens éventuellement partagés.",
            },
            {
              variant: ETextVariant.Body2,
              content:
                "Le cas échéant, compléter les tableaux de l’actionnariat et des participations par la présentation ci-dessous et/ou à l'aide d’un organigramme du groupe, qui fasse apparaître les relations d’affaires au sein de celui-ci à importer lors de la dernière étape de ce formulaire.",
            },
          ],
        },
        {
          initialValue: "",
          label: "Description",
          name: "participations__comment",
          placeholder:
            "Les activités détenues, les relations d'affaires et les moyens éventuellement partagés",
          type: EInputType.TextArea,
          validation: Yup.string(),
        },
      ],
    },
  ],
};

export default step3bis;
