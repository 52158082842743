import React from "react";
import { Box, Button, Modal } from "../components";
import useConfirmationModal from "../hooks/useConfirmationModal";
import { ButtonVariant } from "../types";

const ConfirmationModal: React.FC = () => {
  const {
    confirmationModalProps,
    closeConfirmationModal,
  } = useConfirmationModal();
  const {
    isOpen = false,
    title = "Confirmation",
    button = "Compris",
    description = "",
    logout = false,
    isLoading = false,
  } = confirmationModalProps;
  const handleClose = () => {
    if (logout) {
      localStorage.setItem("token", "");
      document.location.reload();
    }
    closeConfirmationModal();
  };

  return isOpen ? (
    <Modal
      title={title}
      isLoading={isLoading}
      open={isOpen}
      handleClose={handleClose}
    >
      {description}
      <Box jc="space-between">
        <Button mt variant={ButtonVariant.Secondary} onClick={handleClose}>
          {button}
        </Button>
      </Box>
    </Modal>
  ) : null;
};

export default ConfirmationModal;
