import { IOption } from "../../../types";
export const boolOptions: IOption[] = [
  {
    label: "Oui",
    value: "Oui",
  },
  {
    label: "Non",
    value: "Non",
  },
];
export const percentOptions: IOption[] = [
  {
    label: "0%",
    value: "0%",
  },
  {
    label: "< 50%",
    value: "< 50%",
  },
  {
    label: "> 50%",
    value: "> 50%",
  },
];
export const regions: IOption[] = [
  {
    label: "Auvergne-Rhône-Alpes",
    value: "Auvergne-Rhône-Alpes",
  },
  {
    label: "Bourgogne-Franche-Comté",
    value: "Bourgogne-Franche-Comté",
  },
  {
    label: "Bretagne",
    value: "Bretagne",
  },
  {
    label: "Centre-Val de Loire",
    value: "Centre-Val de Loire",
  },
  {
    label: "Corse",
    value: "Corse",
  },
  {
    label: "Grand Est",
    value: "Grand Est",
  },
  {
    label: "Hauts-de-France",
    value: "Hauts-de-France",
  },
  {
    label: "Île-de-France",
    value: "Île-de-France",
  },
  {
    label: "Normandie",
    value: "Normandie",
  },
  {
    label: "Nouvelle-Aquitaine",
    value: "Nouvelle-Aquitaine",
  },
  {
    label: "Occitanie",
    value: "Occitanie",
  },
  {
    label: "Pays de la Loire",
    value: "Pays de la Loire",
  },
  {
    label: "Provence-Alpes-Côte d'Azur",
    value: "Provence-Alpes-Côte d'Azur",
  },
  {
    label: "Guadeloupe",
    value: "Guadeloupe",
  },
  {
    label: "Martinique",
    value: "Martinique",
  },
  {
    label: "Guyane",
    value: "Guyane",
  },
  {
    label: "La Réunion",
    value: "La Réunion",
  },
  {
    label: "Mayotte",
    value: "Mayotte",
  },
];

export const regionsAndFrance: IOption[] = [
  {
    label: "Toute la France",
    value: "Toute la France",
  },
  ...regions,
];

export const countriesEU: IOption[] = [
  { label: "Allemagne", value: "Allemagne" },
  { label: "Autriche", value: "Autriche" },
  { label: "Belgique", value: "Belgique" },
  { label: "Bulgarie", value: "Bulgarie" },
  { label: "Chypre", value: "Chypre" },
  { label: "Croatie", value: "Croatie" },
  { label: "Danemark", value: "Danemark" },
  { label: "Espagne", value: "Espagne" },
  { label: "Estonie", value: "Estonie" },
  { label: "Finlande", value: "Finlande" },
  { label: "France", value: "France" },
  { label: "Grèce", value: "Grèce" },
  { label: "Hongrie", value: "Hongrie" },
  { label: "Irlande", value: "Irlande" },
  { label: "Italie", value: "Italie" },
  { label: "Lettonie", value: "Lettonie" },
  { label: "Lituanie", value: "Lituanie" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Malte", value: "Malte" },
  { label: "Pays-Bas", value: "Pays-Bas" },
  { label: "Pologne", value: "Pologne" },
  { label: "Portugal", value: "Portugal" },
  { label: "République tchèque", value: "République tchèque" },
  { label: "Roumanie", value: "Roumanie" },
  { label: "Slovaquie", value: "Slovaquie" },
  { label: "Slovénie", value: "Slovénie" },
  { label: "Suède", value: "Suède" },
];
