import React from "react";
import { Tooltip, OptionGroupItem } from "../../components";
import { IFieldTable } from "../../types";
import {
  StyledTable,
  LabelWrapper,
  TooltipWrapper,
  StyledErrorMessage,
} from "./styled-components";

const Table: React.FC<IFieldTable> = ({
  title,
  col1,
  col2,
  fields,
  values,
}) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th>{title}</th>
          <th>{col1}</th>
          <th>{col2}</th>
        </tr>
      </thead>
      <tbody>
        {fields.map(({ label, tooltip = "", name, required = false }) => (
          <tr key={name}>
            <th>
              <LabelWrapper>
                {label}
                {required ? "\u00a0*" : ""}
                {tooltip ? (
                  <TooltipWrapper>
                    <Tooltip>{tooltip}</Tooltip>
                  </TooltipWrapper>
                ) : null}
              </LabelWrapper>
              <StyledErrorMessage component="div" name={name} />
            </th>
            {values.map((value, index) => (
              <th key={index}>
                <OptionGroupItem key={index} name={name} center value={value} />
              </th>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default Table;
