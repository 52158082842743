import React, { useEffect, useState } from "react";
import {
  EFieldContainerVariant,
  EInputSize,
  EInputType,
  IInput,
} from "../types";
import {
  Input,
  FieldContainer,
  Select,
  OptionGroup,
  TextArea,
  Loader,
} from "../components";
import { FileInputContainer } from "../containers";

interface Props extends IInput {
  prefix?: string;
  required?: boolean;
}
const StepInput: React.FC<Props> = ({
  tooltip,
  label,
  required = false,
  name,
  placeholder = "",
  prefix = "",
  options,
  disabled = false,
  containerVariant,
  type = EInputType.Text,
  size = EInputSize.Regular,
  max = 1,
  mask,
}) => {
  const nameWithPrefix = prefix + name;

  const [isLoading, setIsLoading] = useState(false);
  const [newOptions, setNewOptions] = useState(
    typeof options === "function" ? [] : options
  );

  useEffect(() => {
    if (typeof options === "function") {
      setIsLoading(true);
      options().then((res) => {
        const data = res?.data?.data;
        setNewOptions(data);
        setIsLoading(false);
      });
    }
  }, [options]);

  const renderInput = () => {
    if (isLoading) {
      return <Loader width={44} height={44} />;
    } else if (type === EInputType.Select) {
      return (
        <Select
          disabled={disabled}
          name={nameWithPrefix}
          placeholder={placeholder}
          options={newOptions}
        />
      );
    } else if (type === EInputType.Checkbox || type === EInputType.Radio) {
      return (
        <OptionGroup
          name={nameWithPrefix}
          type={type}
          options={newOptions}
          size={size}
          disabled={disabled}
        />
      );
    } else if (type === EInputType.TextArea) {
      return <TextArea name={nameWithPrefix} placeholder={placeholder} />;
    } else if (type === EInputType.File) {
      return <FileInputContainer name={nameWithPrefix} max={max} />;
    } else {
      return (
        <Input
          type={type}
          name={nameWithPrefix}
          placeholder={placeholder}
          size={size}
          mask={mask}
          disabled={disabled}
        />
      );
    }
  };

  const getContainerVariant = () => {
    if (containerVariant) {
      return containerVariant;
    }
    if (type === EInputType.Checkbox || type === EInputType.Radio) {
      return EFieldContainerVariant.OptionDefault;
    }
    if (type === EInputType.TextArea) {
      return EFieldContainerVariant.TextArea;
    }
    if (type === EInputType.File) {
      return EFieldContainerVariant.File;
    }
    return EFieldContainerVariant.Default;
  };

  return (
    <FieldContainer
      label={label}
      required={required}
      name={nameWithPrefix}
      tooltip={tooltip}
      variant={getContainerVariant()}
    >
      {renderInput()}
    </FieldContainer>
  );
};

export default StepInput;
