import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import { useTheme } from "styled-components";

import {
  ErrorFocus,
  FirstStep,
  FormikPatchTouched,
  LastStep,
  PaymentStep,
} from "../containers";
import { Box, Container, Text, Button, Stepper } from "../components";
import { populateValues, canUserPay } from "../helpers";
import { ETextVariant, EContainerVariant, ButtonVariant } from "../types";
import useConfirmationModal from "../hooks/useConfirmationModal";
import FirstStepHeader from "./FirstStep/FirstStepHeader";
import BeforeLastWarningModal from "./FirstStep/BeforeLastWarningModal";
import api from "../config/api";

const INITIAL_STEP = 0;

const Wizard = ({ children, completed, formHelper, onSubmit, isLoading }) => {
  const theme = useTheme();
  const { setConfirmationModalProps } = useConfirmationModal();
  const initialValues = formHelper.initialValues;
  const [snapshot, setSnapshot] = useState(formHelper.initialValues);
  const [stepNumber, setStepNumber] = useState(INITIAL_STEP);
  const [shouldAlertUser, setShouldAlertUser] = useState(true);
  const [entryId, setEntryId] = useState(null);
  const [showPaymentStep, setShowPaymentStep] = useState(false);
  const [showBeforeLastStepConfirm, setShowBeforeLastStepConfirm] = useState(
    true
  );
  const [
    openBeforeLastStepConfirmModal,
    setOpenBeforeLastStepConfirmModal,
  ] = useState(false);

  const steps = React.Children.toArray(children);
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);

  const step = steps[stepNumber];
  const stepTitles = formHelper.stepTitles;
  const currentStepTitle = stepTitles[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;
  const isBeforeLastStep = stepNumber === totalSteps - 2;

  const alertUser = useCallback(
    (e) => {
      if (
        shouldAlertUser &&
        stepNumber > 0 &&
        stepNumber <= totalSteps - 1 &&
        !completed
      ) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    [stepNumber, totalSteps, completed, shouldAlertUser]
  );

  const handleSubmit = async (values, bag) => {
    let success = true;

    if (showBeforeLastStepConfirm && isBeforeLastStep) {
      return setOpenBeforeLastStepConfirmModal(true);
    }

    if (formHelper.isSimple) {
      setShowPaymentStep(canUserPay(values));
    }

    if (step.props.onSubmit) {
      success = await step.props.onSubmit(entryId, values, bag);
      setShouldAlertUser(success);
    }

    if (isLastStep) {
      return onSubmit(entryId, values, bag);
    } else if (success) {
      next();
    }
  };

  const next = () => {
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const checkPageParams = async () => {
    const token = query.get("t");
    const id = query.get("id");

    // clear url params
    history.replace({
      search: "",
    });

    if (token && id) {
      // set token
      setToken(token);

      // fetch entry and set initialvalues
      initForm(id);
    }
  };

  const fetchDynamicInitialValues = () =>
    new Promise((resolve, reject) => {
      if (formHelper.isRenewal) {
        api
          .getInitialRenewalValues()
          .then((res) => {
            const data = res?.data?.data;
            if (data) {
              resolve(data);
            } else {
              resolve({});
            }
          })
          .catch(() => {
            reject(new Error("No data found"));
          });
      } else {
        resolve({});
      }
    });

  const initForm = (id) => {
    Promise.all([fetchDynamicInitialValues(), fetchEntry(id)])
      .then(([dynamicInitialValues, entry]) => {
        const allInitialValues = { ...initialValues, ...dynamicInitialValues };
        setEntryId(entry.id);
        setStepNumber(entry.step);
        setSnapshot({
          ...populateValues(allInitialValues, JSON.parse(entry.values)),
          id: entry.id,
        });
      })
      .catch(() => {
        handleFetchError();
      });
  };

  const setToken = (token) => localStorage.setItem("token", token);
  const fetchEntry = (id) =>
    new Promise((resolve, reject) => {
      api
        .getEntry(id)
        .then((res) => {
          const entry = res?.data?.entry;
          if (entry) {
            resolve(entry);
          } else {
            reject(new Error("Entry not found"));
          }
        })
        .catch(() => reject(new Error("Error fetch entry")));
    });

  const showPreviousStep = () => {
    return stepNumber < totalSteps - 1 && stepNumber > 1;
  };

  const handleFetchError = () => {
    setConfirmationModalProps({
      isOpen: true,
      title: "Le lien utilisé est expiré",
      description:
        "Merci de rentrer votre email à nouveau pour recevoir un nouveau lien.",
      error: true,
    });
  };

  const getSubmitButtonLabel = (formik) => {
    let label = "";
    if (isLastStep && formHelper.isSimple) {
      if (canUserPay(formik.values)) {
        label = "CALCULER ET PAYER MA COTISATION";
      } else {
        label = "ENVOYER LES INFORMATIONS";
      }
    } else if (isLastStep) {
      label = "VALIDER ET FINALISER LA DEMANDE";
    } else {
      label = "ENREGISTRER ET PASSER À L’ETAPE SUIVANTE";
    }
    return label;
  };

  const goToPreviousStep = () => {
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  useEffect(() => {
    checkPageParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stepNumber]);

  // Alert user if he try to leave the page
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [stepNumber, alertUser]);

  return (
    <div
      style={{
        "--form-primary-color": formHelper.color,
      }}
    >
      {stepNumber === 0 && <FirstStepHeader formHelper={formHelper} />}

      {!formHelper.isSimple && (
        <>
          <Container variant={EContainerVariant.Large}>
            <Stepper
              currentIndex={stepNumber + 1 + (completed ? 1 : 0)}
              items={stepTitles}
            />
          </Container>
        </>
      )}
      {stepNumber === 0 && <FirstStep formHelper={formHelper} />}

      {completed && !showPaymentStep && !!entryId && (
        <LastStep formHelper={formHelper} />
      )}
      {completed && showPaymentStep && !!entryId && (
        <PaymentStep formHelper={formHelper} entryId={entryId} />
      )}

      {!completed && stepNumber > 0 && (
        <Formik
          initialValues={snapshot}
          onSubmit={handleSubmit}
          validationSchema={step.props.validationSchema}
          enableReinitialize
        >
          {(formik) => (
            <div>
              <Form>
                <ErrorFocus>
                  <FormikPatchTouched />
                  <>
                    {!formHelper.isSimple && (
                      <Text
                        variant={ETextVariant.Title2}
                        color={theme.colors.accent}
                        center
                        padding
                      >
                        Étape {stepNumber} : {currentStepTitle}
                      </Text>
                    )}
                    {step}
                    {showPreviousStep() && (
                      <Box jc="center">
                        <Button
                          disabled={formik.isSubmitting || isLoading}
                          onClick={goToPreviousStep}
                          variant={ButtonVariant.Tertiary}
                          type="button"
                        >
                          {"<"} ÉTAPE PRÉCÉDENTE
                        </Button>
                      </Box>
                    )}
                    <Box jc="center">
                      <Button
                        disabled={formik.isSubmitting || isLoading}
                        isLoading={isLoading}
                        type="submit"
                        mt
                      >
                        {getSubmitButtonLabel(formik)}
                      </Button>
                    </Box>
                  </>
                </ErrorFocus>
              </Form>

              {isBeforeLastStep && (
                <BeforeLastWarningModal
                  isOpen={openBeforeLastStepConfirmModal}
                  handleCancel={() => setOpenBeforeLastStepConfirmModal(false)}
                  handleConfirm={() => {
                    setOpenBeforeLastStepConfirmModal(false);
                    setShowBeforeLastStepConfirm(false);
                  }}
                />
              )}
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};

Wizard.propTypes = {};

export default Wizard;
