import React from "react";
import { IStep } from "../types";
import { StepField } from "../containers";

interface Props {
  step: IStep;
  validationSchema: any;
  onSubmit: (entryId: number, values: any, bag: any) => void;
}

const Step: React.FC<Props> = ({ step }) => {
  return (
    <div>
      {step.fields.map((field, index) => (
        <StepField key={index} field={field} />
      ))}
    </div>
  );
};

export default Step;
