import {
  EInputType,
  EInputSize,
  EFormType,
  ECatalogueID,
} from "../../../../types";
import { isAssurance, isCredit } from "../../../../helpers";
import { stringR, checkboxGroup } from "../../../../helpers/validation";
import { boolOptions } from "../options";
import api from "../../../api";

const factoryRenewalStep2 = (type: EFormType = EFormType.Credit) => ({
  title: "Distribution et produits",
  description: "Renseignements sur la distribution et les produits",
  fields: [
    ...(isAssurance(type)
      ? [
          {
            ///// SECTION
            title: "Distribution IAS",
            fields: [
              {
                initialValue: "",
                label: "Type de clientèle majoritaire",
                required: true,
                name: "distribution__client_type_ias",
                type: EInputType.Radio,
                options: () =>
                  api.getCatalog(ECatalogueID.distribution__client_type_ias),
                validation: stringR,
              },
              {
                initialValue: "",
                label: "Modalité de distribution",
                required: true,
                name: "distribution__modality_ias",
                type: EInputType.Radio,
                options: () =>
                  api.getCatalog(ECatalogueID.distribution__modality_ias),
                validation: stringR,
              },
              {
                initialValue: "",
                label: "Type de distribution majoritaire",
                required: true,
                name: "distribution__distribution_type_ias",
                size: EInputSize.Large,
                type: EInputType.Radio,
                options: () =>
                  api.getCatalog(
                    ECatalogueID.distribution__distribution_type_ias
                  ),
                validation: stringR,
              },
              {
                initialValue: "",
                label:
                  "Pratiquez vous du démarchage téléphonique ? (appels non-sollicités)",
                required: true,
                name: "distribution__telemarketing_ias",
                type: EInputType.Radio,
                options: boolOptions,
                validation: stringR,
              },
            ],
          },

          {
            ///// SECTION
            title: "Produits IAS",
            fields: [
              {
                initialValue: "",
                label:
                  "Produit représentant la part la plus importante du CA (un seul choix possible)",
                required: true,
                name: "distribution__main_ca_share_ias",
                type: EInputType.Radio,
                size: EInputSize.Large,
                options: () =>
                  api.getCatalog(
                    ECatalogueID.distribution__10_percent_ca_share_ias
                  ),
                validation: stringR,
              },
              {
                initialValue: [],
                label: "Produits représentant plus de 10% du CA",
                required: true,
                name: "distribution__10_percent_ca_share_ias",
                type: EInputType.Checkbox,
                size: EInputSize.Large,
                options: () =>
                  api.getCatalog(
                    ECatalogueID.distribution__10_percent_ca_share_ias
                  ),
                validation: checkboxGroup,
              },
            ],
          },

          {
            ///// SECTION
            title: "Fournisseur de produits IAS",
            fields: [
              {
                initialValue: "",
                label:
                  "Nombre de partenariats actifs (ayant donné lieu à la souscription d'au moins un produit au cours de l'année)",
                required: true,
                name: "distribution__subscription_count_ias",
                type: EInputType.Radio,
                size: EInputSize.Large,
                options: () =>
                  api.getCatalog(
                    ECatalogueID.distribution__subscription_count_ias
                  ),
                validation: stringR,
              },
            ],
          },
        ]
      : []),
    ...(isCredit(type)
      ? [
          {
            ///// SECTION
            title: "Distribution IOBSP",
            fields: [
              {
                initialValue: "",
                label: "Type de clientèle majoritaire",
                required: true,
                name: "distribution__client_type_iobsp",
                validation: stringR,

                type: EInputType.Radio,
                options: () =>
                  api.getCatalog(ECatalogueID.distribution__client_type_iobsp),
              },
              {
                initialValue: "",
                label: "Modalité de distribution",
                required: true,
                name: "distribution__modality_iobsp",
                type: EInputType.Radio,
                validation: stringR,
                options: () =>
                  api.getCatalog(ECatalogueID.distribution__modality_iobsp),
              },
              {
                initialValue: "",
                label: "Type de distribution majoritaire",
                required: true,
                name: "distribution__distribution_type_iobsp",
                type: EInputType.Radio,
                size: EInputSize.Large,
                validation: stringR,
                options: () =>
                  api.getCatalog(
                    ECatalogueID.distribution__distribution_type_iobsp
                  ),
              },
              {
                initialValue: "",
                label:
                  "Pratiquez vous du démarchage téléphonique ? (appels non-sollicités)",
                required: true,
                name: "distribution__telemarketing_iobsp",
                type: EInputType.Radio,
                options: boolOptions,
                validation: stringR,
              },
            ],
          },

          {
            ///// SECTION
            title: "Produits IOBSP",
            fields: [
              {
                initialValue: "",
                label:
                  "Produit représentant la part la plus importante du CA (un seul choix possible)",
                required: true,
                name: "distribution__main_ca_share_iobsp",
                type: EInputType.Radio,
                size: EInputSize.Large,
                options: () =>
                  api.getCatalog(
                    ECatalogueID.distribution__main_ca_share_iobsp
                  ),
              },
              {
                initialValue: [],
                label: "Produits représentant plus de 10% du CA",
                required: true,
                name: "distribution__10_percent_ca_share_iobsp",
                type: EInputType.Checkbox,
                size: EInputSize.Large,
                options: () =>
                  api.getCatalog(
                    ECatalogueID.distribution__main_ca_share_iobsp
                  ),
                validation: checkboxGroup,
              },
            ],
          },

          {
            ///// SECTION
            title: "Fournisseur de produits IOBSP",
            fields: [
              {
                initialValue: "",
                label:
                  "Nombre de partenariats actifs (ayant donné lieu à la souscription d'au moins un produit au cours de l'année)",
                required: true,
                name: "distribution__subscription_count_iobsp",
                type: EInputType.Radio,
                size: EInputSize.Large,
                options: () =>
                  api.getCatalog(
                    ECatalogueID.distribution__subscription_count_iobsp
                  ),
                validation: stringR,
              },
            ],
          },
        ]
      : []),
  ],
});

export default factoryRenewalStep2;
