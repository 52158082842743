import Yup from "../../../i18n/yup";
import { EInputSize, EInputType } from "../../../types";

const activitiesTypes = [
  {
    name: "activities__type__conseil_investissement_financiers",
    value: "Conseil en Investissement Financiers",
    subLabel: "Nom de l'association agréée par l'AMF ",
  },
  {
    name: "activities__type__conseil_investissement_participatifs",
    value: "Conseil en Investissement participatif",
    subLabel: "Numéro d'inscription Orias",
  },
  {
    name: "activities__type__intermediaire_investissement_participatifs",
    value: "Intermédiaire en financement participatif",
    subLabel: "Numéro d'inscription Orias",
  },
  {
    name: "activities__type__intermediaire_assurance",
    value: "Intermédiaire en assurance",
    subLabel: "Numéro d'inscription Orias",
  },
  {
    name: "activities__type__intermediaire_operation_de_banque",
    value: "Intermédiaire en Opérations de Banque",
    subLabel: "Numéro d'inscription Orias",
  },
  {
    name: "activities__type__agent_immobilier",
    value: "Agent immobilier Loi Hoguet/Mandataire",
    subLabel: "Numéro de votre carte professionnelle",
  },
].map(({ name, value, subLabel }) => [
  {
    initialValue: "",
    label: "",
    name: name,
    type: EInputType.Checkbox,
    options: [
      {
        value: value,
        label: value,
      },
    ],
  },
  {
    initialValue: "",
    label: subLabel,
    name: `${name}__precision`,
    type: EInputType.Text,
    size: EInputSize.Large,
    validation: Yup.mixed().when(name, (values: any) => {
      return values && values.includes(value)
        ? Yup.string().required()
        : Yup.string();
    }),

    visible: [
      {
        name: name,
        value: value,
      },
    ],
  },
]);
const factoryActivities = activitiesTypes.reduce((acc, currentValue) => {
  acc.push(...currentValue);
  return acc;
}, []);

export default factoryActivities;
