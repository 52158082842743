import { useEffect, useState, useCallback } from "react";
import { useFormikContext } from "formik";
import isObject from "lodash/isObject";

const ErrorFocus = ({ children }) => {
  const formik = useFormikContext();
  const [submitCount, setSubmitCount] = useState(formik.submitCount);

  const getFirstErrorKey = useCallback((object, keys = []) => {
    const firstErrorKey = Object.keys(object)[0];
    if (isObject(object[firstErrorKey])) {
      return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
    }
    return [...keys, firstErrorKey].join(".");
  }, []);

  useEffect(() => {
    if (
      !formik.isValid &&
      formik.submitCount > submitCount &&
      formik.isSubmitting
    ) {
      const firstErrorKey = getFirstErrorKey(formik.errors);
      if (global.window.document.getElementsByName(firstErrorKey).length) {
        global.window.document.getElementsByName(firstErrorKey)[0].focus();
      }
      setSubmitCount(formik.submitCount);
    }
  }, [
    formik.submitCount,
    formik.isValid,
    formik.isSubmitting,
    formik.errors,
    submitCount,
    getFirstErrorKey,
  ]);
  return children;
};

export default ErrorFocus;
