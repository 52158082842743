import { numberR, stringR } from "../../../helpers/validation";
import {
  IStep,
  EInputType,
  EInputSize,
  EContainerVariant,
  EHumanType,
  EComparator,
  ETextVariant,
} from "../../../types";
import Yup from "../../../i18n/yup";
import { factoryHuman } from "../common";

const step7: IStep = {
  // ÉTAPE 7
  title: "Organisation et moyen(s)",
  description:
    "Renseignements sur les moyens mis en place pour exercer votre activité au sein de votre structure dont les salariés et collaborateurs",
  fields: [
    {
      ///// SECTION
      title: "Outils",
      fields: [
        {
          fieldsetTitle: "",
          containerVariant: EContainerVariant.Small,
          fields: [
            {
              listItems: [
                "Bases de données et/ou outils utilisés",
                "Abonnements souscrits (presse, site informations, …)",
                "Formation continue suivie au cours de l’année dernière : (thèmes,nombre d’heures)",
              ],
            },
          ],
        },
        {
          initialValue: "",
          label: "Précisez les outils utilisés",
          name: "tools",
          required: true,
          type: EInputType.TextArea,
          size: EInputSize.Large,
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Salarié(e) / Collaborateur(s)",
      tooltip:
        "Ces personnes seront référencées sur l’annuaire public en ligne et recevront l’actualité de l’association.",
      fields: [
        {
          fieldsetTitle: "",
          containerVariant: EContainerVariant.Small,
          fields: [
            {
              variant: ETextVariant.Body3,
              content:
                "Si le nombre total de salariés M&A est supérieur ou égal à 10, la liste des salariés vous sera demandé en dernière étape du formulaire. Sinon, merci de renseigner les informations des salariés ci dessous.",
            },
          ],
        },
        {
          initialValue: "",
          label: "Nombre total de salariés dans votre structure",
          required: true,
          name: "employees_total_count",
          type: EInputType.Number,
          size: EInputSize.Small,
          validation: numberR,
        },
        {
          initialValue: "",
          label:
            "Nombre de personnes conseillant directement les clients dans le domaine du M&A",
          required: true,
          name: "employees_france_count",
          type: EInputType.Number,
          size: EInputSize.Small,
          validation: numberR,
        },
        {
          initialValue: "",
          label: "",
          required: true,
          name: "__test_employees_france",
          type: EInputType.Hidden,
          size: EInputSize.Small,
          validation: Yup.mixed().when(
            ["employees_france_count", "employees_france"],
            {
              is: (employees_france_count: any, employees_france: any) => {
                return (
                  employees_france_count < 10 &&
                  employees_france.length !== employees_france_count
                );
              },
              then: Yup.string().required(
                "Merci de renseigner les informations pour autant de salariés que précisé au dessus."
              ),
            }
          ),
        },
        {
          repeatable: "employees_france",
          pdfLabel: "Salarié(e)",
          addButtonLabel: "Ajouter un(e) salarié(e)",
          initialEmpty: true,
          fields: factoryHuman(EHumanType.EmployeeFrance),
          visible: [
            {
              comparator: EComparator.inf,
              name: "employees_france_count",
              value: 10,
            },
          ],
        },
      ],
    },
  ],
};

export default step7;
