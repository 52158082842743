import React from "react";
import { ISection } from "../types";
import { StepField } from "../containers";
import { Section } from "../components";

const StepSection: React.FC<ISection> = ({
  title,
  tooltip,
  variant,
  fields,
}) => {
  return (
    <Section title={title} variant={variant} tooltip={tooltip}>
      {fields.map((field, index) => (
        <StepField key={index} field={field} />
      ))}
    </Section>
  );
};

export default StepSection;
