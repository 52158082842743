import React from "react";
import { EContainerVariant, EFormType, ETextVariant } from "../types";
import { useTheme } from "styled-components";

import { Box, Container, Section, Button, Text } from "../components";
import FormHelper from "../helpers/FormHelper";

interface Props {
  formHelper: FormHelper;
}
const Laststep: React.FC<Props> = ({ formHelper }) => {
  const theme = useTheme();
  return (
    <>
      <Text variant={ETextVariant.Title2} color={theme.colors.accent} center>
        Votre demande a été envoyée.
      </Text>
      <Container variant={EContainerVariant.Small}>
        <Section>
          <Text variant={ETextVariant.Title5}>
            {formHelper.type === EFormType.Patrimoine
              ? "Votre programme d'activité"
              : "Votre formulaire "}{" "}
            a bien été soumis, un exemplaire de ce dernier vous sera transmis au
            format pdf par email. <br />
            Notre équipe reviendra vers vous dans les prochains jours.
          </Text>
        </Section>
      </Container>

      <Box jc="center">
        <a href="https://www.cncef.org/">
          <Button>Aller sur le site de la CNCEF</Button>
        </a>
      </Box>
    </>
  );
};

export default Laststep;
