import {
  IStep,
  EInputType,
  EInputSize,
  ERepeaterButtonVariant,
} from "../../../types";
import Yup from "../../../i18n/yup";

const step3: IStep = {
  title: "Actionnariat",
  description:
    "Renseignements sur votre activité antérieure, l’actionnariat et la participation au sein de votre structure",
  fields: [
    {
      ///// SECTION
      title: "Actionnariat et participations",
      fields: [
        {
          fieldsetTitle:
            "Principaux actionnaires ou associés de la personne morale ",
          fields: [
            {
              repeatable: "share_holding_main_shareholders",
              addButtonLabel: "Ajouter un actionnaire",
              removeButtonLabel: "Supprimer",
              variant: ERepeaterButtonVariant.Small,
              initialEmpty: true,
              fields: [
                {
                  initialValue: "",
                  label: "Nom",
                  name: "denomination",
                  placeholder: "Jeanne Dupont",
                  type: EInputType.Text,
                  validation: Yup.string(),
                },
                {
                  initialValue: "",
                  label: "Répartition en % des droits de vote",
                  name: "repartition_vote",
                  placeholder: "15%",
                  type: EInputType.Text,
                  size: EInputSize.Small,
                  validation: Yup.string(),
                },
              ],
            },
          ],
        },
        {
          fieldsetTitle: "Ventilation du CA",
          fields: [
            {
              initialValue: "",
              label: "% commissions",
              name: "ventilation_ca__commissions",
              placeholder: "10%",
              type: EInputType.Text,
              size: EInputSize.Small,
            },
            {
              initialValue: "",
              label: "% honoraire",
              name: "ventilation_ca__fees",
              placeholder: "10%",
              type: EInputType.Text,
              size: EInputSize.Small,
            },
          ],
        },
      ],
    },
  ],
};

export default step3;
