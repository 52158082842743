export interface IUserProgress {
  entry_in_progress: boolean;
  entry_completed: boolean;
}

export enum ECatalogueID {
  network_name = "10403",
  distribution__client_type_ias = "5318",
  distribution__modality_ias = "5346",
  distribution__distribution_type_ias = "5319",
  distribution__main_ca_share_ias = "5324",
  distribution__10_percent_ca_share_ias = "5325",
  distribution__subscription_count_ias = "5326",
  distribution__client_type_iobsp = "5328",
  distribution__modality_iobsp = "5347",
  distribution__distribution_type_iobsp = "5329",
  distribution__main_ca_share_iobsp = "5349",
  distribution__subscription_count_iobsp = "5330",
}
