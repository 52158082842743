import React from "react";
import { Wrapper, Label, Index } from "./styled-components";

interface Props {
  index: number;
  active: boolean;
  completed: boolean;
  label: string;
  onClick: () => void;
}

const StepperItem: React.FC<Props> = ({
  index,
  active = false,
  completed = false,
  label,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <Label active={active} completed={completed}>
        {label}
      </Label>
      <Index active={active} completed={completed}>
        {index === 0 ? "" : index}
      </Index>
    </Wrapper>
  );
};

export default StepperItem;
