import { createGlobalStyle } from "styled-components";

import colors from "./colors";
import fonts from "./fonts";

const GlobalStyles = createGlobalStyle`

    //typography    
    html {
        /* font smoothing */
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;

        /* base font family */
        font-family: ${fonts.family.primary};

        /* set base font-size to equiv "10px", which is adapted to rem unit */
        font-size: 62.5%;

        /* base line height */
        line-height: ${fonts.lineHeight.base};

        /* base font weight */
        font-weight: ${fonts.weight.base};

        /* base letter spacing */
        letter-spacing: 0;

        --form-primary-color: ${colors.red}
    }

    body {
        font-size: ${fonts.size.m};
    }

    //colors
    html {
        background: ${colors.htmlBackgroundColor};
    }
    body {
        background: ${colors.bodyBackgroundColor};
        color: ${colors.text};
    }    

    a{
        color: inherit;
        text-decoration:none;
    }
    
`;

export default GlobalStyles;
