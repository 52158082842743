import React from "react";
import { colors } from "../../theme";
import { ETextVariant } from "../../types";
import { StyledText } from "./styled-components";

interface Props {
  children: React.ReactNode;
  color?: string;
  center?: boolean;
  padding?: boolean;
  variant?: ETextVariant;
}

const Text: React.FC<Props> = ({
  variant = ETextVariant.Body1,
  color = colors.primary,
  center = false,
  padding = false,
  children,
}) => {
  return (
    <StyledText
      variant={variant}
      color={color}
      center={center}
      padding={padding}
    >
      {children}
    </StyledText>
  );
};

export default Text;
