import styled from "styled-components";
import { colors, fonts } from "../../theme";
import { ButtonVariant } from "../../types";

export const Label = styled.span<{
  variant: ButtonVariant;
}>`
  ${(props) =>
    (props.variant === ButtonVariant.Tertiary ||
      props.variant === ButtonVariant.Quaternary) &&
    `
      position: relative;
      overflow: hidden;
      padding-bottom: 0.8rem;
      padding-top: 0.4rem;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.5rem;
        transform: translateX(-105%);
        background-color: ${props.theme.colors.accent};
        transition: 0.3s;
      }
  `}
  ${(props) =>
    props.variant === ButtonVariant.Quaternary &&
    `
    &:before {
     background-color: ${colors.primary};
    }

    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
`;

export const StyledButton = styled.button<{
  variant: ButtonVariant;
  type: string;
  mt: boolean;
  isLoading: boolean;
}>`
  overflow: hidden;
  font-size: ${fonts.size.s};
  font-weight: 500;
  line-height: 1.1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  transition: all 0.3s;
  cursor: pointer;
  color: ${colors.white};
  position: relative;
  text-decoration: none;

  ${(props) =>
    props.isLoading &&
    `
        opacity: 0.7;
        cursor: wait;
        pointer-events: none;
  `};

  ${(props) =>
    props.mt &&
    `
      margin-top: 4rem;
    `}

  ${(props) =>
    props.variant === ButtonVariant.Primary &&
    `
       background-color: ${props.theme.colors.accent};
    `}
  ${(props) =>
    props.variant === ButtonVariant.Secondary &&
    `
          background-color: ${colors.primary};
      `}

  
  ${(props) =>
    (props.variant === ButtonVariant.Primary ||
      props.variant === ButtonVariant.Secondary) &&
    `
    padding: 1.9rem 6.8rem 1.7rem 6.8rem;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateX(-100%) translateY(-50%);
      width: 4rem;
      height: 0.4rem;
      background-color: ${colors.white};
      transition: 0.3s;
    }
    &:hover ${ContentWrapper} {
      transform: translateX(2rem);
    }
    &:hover:before {
      transform: translateX(0) translateY(-50%);
    }
  `}

  ${(props) =>
    props.variant === ButtonVariant.Tertiary &&
    `
      color: ${props.theme.colors.accent};

    `}


    ${(props) =>
    props.variant === ButtonVariant.Quaternary &&
    `
        color: ${colors.primary};
  
      `}
  
    ${(props) =>
    (props.variant === ButtonVariant.Tertiary ||
      props.variant === ButtonVariant.Quaternary) &&
    `
      font-size: ${fonts.size.m};
      background-color: ${colors.white};
        &:hover ${Label}:before {
          transform: translateX(0);
        }
    `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
`;
export const IconLeft = styled(IconWrapper)`
  margin-right: 0.8rem;
`;
export const IconRight = styled(IconWrapper)`
  margin-left: 0.8rem;
`;

export const LoaderWrapper = styled.div<{ isLoading: boolean; color: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  background-color: ${(props) => props.color};
  z-index: -1;
  opacity: 0;

  ${(props) =>
    props.isLoading &&
    `
        z-index: 1;
        opacity: 1;
    `};
`;
