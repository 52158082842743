import { colors } from "../../../theme";
import { EFormSubType, EFormType } from "../../../types";
import steps from "./steps";
import { LogoCredit as logoSrc } from "../../../assets/svg";

const creditSchema = {
  label: "Crédit",
  title: "Demande d’adhésion à la CNCEF Crédit",
  tableOfContentTitle: "Parcours d’une demande d’adhésion à la CNCEF Crédit",
  color: colors.greenLight,
  logo: logoSrc,
  steps,
  type: EFormType.Credit,
  footerCertif: "Agréée ACPR",
  subtype: EFormSubType.Classic,
};
export default creditSchema;
