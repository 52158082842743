import styled from "styled-components";
import inputStyles from "../../theme/inputStyles";
import { colors } from "../../theme";

export const StyledWrapper = styled.div`
  .form-control {
    ${inputStyles};
    border-radius: none;
    padding-left: 55px;
  }
  .flag-dropdown {
    border-radius: 2px 0 0 2px;
    border-width: 2px;
    border-color: #f3f3f5;
    border-right: 0px;
    background-color: white;
    padding-left: 10px;
  }
  input:focus + .flag-dropdown {
    border-color: ${colors.primary};
  }
  .country-list {
    left: 0;
    width: 260px;
    overflow-y: auto;
  }
`;
