import {
  IStep,
  EInputType,
  ETextVariant,
  EFieldContainerVariant,
  EContainerVariant,
} from "../../../types";
import { stringR, checkboxGroup } from "../../../helpers/validation";
import documents from "../../documents";

const step6: IStep = {
  // ÉTAPE 6
  title: "Finalisation",
  description: "Finalisation de la demande",
  fields: [
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          variant: ETextVariant.Body1,
          content: "J'atteste sur l'honneur",
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__1",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "Ne faire l’objet d’aucune procédure contentieuse ou disciplinaire en cours *",
              value:
                "Ne faire l’objet d’aucune procédure contentieuse ou disciplinaire en cours *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__2",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "Je m’engage à informer la CNCEF Crédit de tout élément de nature à affecter ma situation *",
              value:
                "Je m’engage à informer la CNCEF Crédit de tout élément de nature à affecter ma situation *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__3",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label: (
                <>
                  Je reconnais avoir pris connaissance des{" "}
                  <a
                    href={documents.statutsCredit}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    statuts
                  </a>{" "}
                  et du{" "}
                  <a
                    href={documents.reglementInterieurCredit}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    règlement intérieur
                  </a>{" "}
                  que j’approuve et m’engage à respecter *
                </>
              ),
              value:
                "Je reconnais avoir pris connaissance des statuts et du code du règlement intérieur que j’approuve et m’engage à respecter",
            },
          ],
          validation: checkboxGroup,
        },
      ],
    },
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__4",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "J’atteste sur l’honneur que toutes les données indiquées sur le dossier d’adhésion, rempli par mes soins, sont véridiques *",
              value:
                "J’atteste sur l’honneur que toutes les données indiquées sur le dossier d’adhésion, rempli par mes soins, sont véridiques *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__5",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "En cochant cette case, j’accepte les conditions d’adhésion demandées par la CNCEF Crédit, association disposant de la reconnaissance de l’Autorité de Contrôle Prudentiel et de Résolution (ACPR). *",
              value:
                "En cochant cette case, j’accepte les conditions d’adhésion demandées par la CNCEF Crédit, association disposant de la reconnaissance de l’Autorité de Contrôle Prudentiel et de Résolution (ACPR). *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label:
            "J’autorise la CNCEF Crédit à faire figurer mes coordonnées dans l’annuaire de la CNCEF",
          name: "authorization__coordinates",
          required: true,
          type: EInputType.Radio,
          containerVariant: EFieldContainerVariant.BigLabel,
          options: [
            {
              label: "Oui",
              value: "Oui",
            },
            {
              label: "Non",
              value: "Non",
            },
          ],
          validation: stringR,
        },
      ],
    },
  ],
};

export default step6;
