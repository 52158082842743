import styled from "styled-components";
import { ERepeaterContainerVariant } from "../../types";

export const Wrapper = styled.div<{ variant: ERepeaterContainerVariant }>`
  ${(props) =>
    props.variant === ERepeaterContainerVariant.WithBackground &&
    `
      background: #f1ebeb;
      margin: 3rem auto;
      padding: 2rem 0 3rem 0;
    `}
`;
