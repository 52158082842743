import React from "react";
import { ERepeaterContainerVariant } from "../../types";
import { Wrapper } from "./styled-components";

interface Props {
  children: React.ReactNode;
  variant?: ERepeaterContainerVariant;
}

const RepeaterContainer: React.FC<Props> = ({
  children,
  variant = ERepeaterContainerVariant.Default,
}) => {
  return <Wrapper variant={variant}>{children}</Wrapper>;
};

export default RepeaterContainer;
