import React from "react";
import { EIconNames, ETextVariant } from "../../types";
import {
  Content,
  TitleWrapper,
  Wrapper,
  IconWrapper,
} from "./styled-components";
import { Text, Icon } from "../../components";
import { colors } from "../../theme";

interface Props {
  title?: string;
  color?: string;
  children: React.ReactNode;
  icon?: EIconNames;
}

const BigSection: React.FC<Props> = ({
  title = "",
  children,
  icon = EIconNames.Eye,
  color = colors.red,
}) => {
  const hasTitle = !!title;
  return (
    <Wrapper hasTitle={hasTitle}>
      <Content hasTitle={hasTitle}>
        {icon && (
          <IconWrapper hasTitle={hasTitle}>
            <Icon color={color} name={icon} width={55} height={28} />
          </IconWrapper>
        )}
        {hasTitle && (
          <TitleWrapper>
            <Text variant={ETextVariant.Title1}>{title}</Text>
          </TitleWrapper>
        )}
        {children}
      </Content>
    </Wrapper>
  );
};

export default BigSection;
