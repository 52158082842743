import React from "react";
import { StyledTextArea } from "./styled-components";
import { EInputType } from "../../types";

interface Props {
  name: string;
  placeholder?: string;
}
const TextArea: React.FC<Props> = ({ name, placeholder = "" }) => {
  return (
    <StyledTextArea
      component={EInputType.TextArea}
      name={name}
      placeholder={placeholder}
    />
  );
};

export default TextArea;
