import React from "react";
import { useTheme } from "styled-components";
import { colors } from "../../theme";
import Icon from "../Icon/Icon";
import { EIconNames } from "../../types/icons";
import {
  Wrapper,
  DropZone,
  Error,
  DropZoneContent,
  SuccessIcon,
  RemoveButton,
  SelectedFile,
  SelectedFileName,
  Progress,
  Notice,
} from "./styled-components";

interface Props {
  files: any[];
  errors?: string[];
  getRootProps: any;
  getInputProps: any;
  removeFile: (index: number) => void;
  isLoading: boolean;
  multiple?: boolean;
}

const FileInput: React.FC<Props> = ({
  files,
  errors = [],
  getRootProps,
  getInputProps,
  removeFile,
  isLoading,
  multiple = false,
}) => {
  const theme = useTheme();
  return (
    <Wrapper>
      {/* si multiple ou aucun fichier n'a été déposé */}
      {(files.length === 0 || multiple) && (
        <DropZone {...getRootProps()}>
          <input {...getInputProps()} />
          <DropZoneContent visible={!isLoading}>
            <Icon name={EIconNames.Upload} color={colors.grey} />
          </DropZoneContent>
          <DropZoneContent visible={isLoading}>
            <Progress isLoading={isLoading} />
          </DropZoneContent>
        </DropZone>
      )}
      {errors.map((error, index) => (
        <Error key={index}>{error}</Error>
      ))}
      {!errors || errors.length === 0 ? (
        <Notice>Formats autorisés : .doc, .xls, .jpeg, .pdf, .png, .gif</Notice>
      ) : null}
      {files.map((file, index) => (
        <SelectedFile key={index}>
          <SuccessIcon>
            <Icon
              name={EIconNames.CircleCheck}
              width={32}
              height={32}
              color={theme.colors.accent}
            />
          </SuccessIcon>
          <SelectedFileName>{file.path}</SelectedFileName>
          <RemoveButton type="button" onClick={() => removeFile(index)}>
            <Icon
              name={EIconNames.CircleClose}
              width={32}
              height={32}
              color={colors.grey}
            />
          </RemoveButton>
        </SelectedFile>
      ))}
    </Wrapper>
  );
};

export default FileInput;
