import React from "react";
// import PropTypes from "prop-types";
import { Text, Header, Container, LottieContainer } from "../components";
import colors from "../theme/colors";
import { EContainerVariant, ELotties, ETextVariant } from "../types";

const Maintenance = () => {
  return (
    <div>
      <Header color={colors.primary}>Formulaire d'adhésion</Header>
      <br />
      <br />
      <Container variant={EContainerVariant.Small}>
        <LottieContainer url={ELotties.Warning} />
        <Text center variant={ETextVariant.Title2}>
          Formulaire en maintenance
        </Text>
        <Text center variant={ETextVariant.Body1}>
          Le formulaire n'est pas encore disponible. Merci de revenir plus tard.
        </Text>
      </Container>
    </div>
  );
};

Maintenance.propTypes = {};

export default Maintenance;
