import {
  IStep,
  EInputType,
  IVisibleCondition,
  EInputSize,
} from "../../../types";
import Yup from "../../../i18n/yup";
import { stringR, checkboxGroup } from "../../../helpers/validation";
import { countriesEU, regionsAndFrance, boolOptions } from "../common/options";

const typeDetailValidation: any = Yup.mixed().when("activities__eu__type", {
  is: "LE (Libre Établissement)",
  then: stringR,
});
const typeDetailVisible: IVisibleCondition[] = [
  {
    name: "activities__eu__type",
    value: "LE (Libre Établissement)",
  },
];

const step5: IStep = {
  // ÉTAPE 5
  title: "Périmètre d’activité",
  description: "Détail sur la nature de votre activité",
  fields: [
    {
      ///// SECTION
      title: "Périmètre d’activité",
      fields: [
        {
          initialValue: "",
          label: "Activité principale",
          required: true,
          name: "activities__is_main",
          type: EInputType.Radio,
          options: boolOptions,
          validation: stringR,
        },
        {
          fieldsetTitle: "Type(s) de crédit(s) proposé(s) * :",
          fields: [
            {
              initialValue: [],
              label: "",
              pdfLabel: "Type",
              name: "activities__types",
              type: EInputType.Checkbox,
              size: EInputSize.Large,
              options: [
                {
                  label: "Crédit à la consommation",
                  value: "Crédit à la consommation",
                },
                { label: "Crédit immobilier", value: "Crédit immobilier" },
                {
                  label: "Regroupement de Crédit",
                  value: "Regroupement de Crédit",
                },
                {
                  label: "Prêts viagers hypothécaires",
                  value: "Prêts viagers hypothécaires",
                },
                {
                  label: "Services de paiement",
                  value: "Services de paiement",
                },
                { label: "Autre", value: "Autre" },
              ],
              validation: checkboxGroup,
            },
            {
              initialValue: "",
              label: "Si autre, précisez",
              name: "activities__has_other__type",
              type: EInputType.Text,
              validation: Yup.string(),
              visible: [
                {
                  name: "activities__types",
                  value: "Autre",
                },
              ],
            },
          ],
        },
        {
          initialValue: "",
          label: "Régions dans lesquelles vous exercez votre activité",
          required: true,
          name: "activities__region",
          placeholder: "Choisissez une région",
          type: EInputType.Select,
          options: regionsAndFrance,
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Passeport européen",
      fields: [
        {
          initialValue: "",
          label:
            "Exercez-vous votre activité d’intermédiation en assurance dans l’Espace Economique de l’Union Européenne ?",
          required: true,
          name: "activities__eu__inside_EU",
          type: EInputType.Radio,
          options: boolOptions,
          validation: Yup.string().required(),
        },
        {
          initialValue: "",
          label: "Régions dans lesquelles vous exercez votre activité",
          required: true,
          name: "activities__eu__country",
          placeholder: "Choisissez un pays",
          type: EInputType.Select,
          options: countriesEU,
          validation: Yup.mixed().when("activities__eu__inside_EU", {
            is: "Oui",
            then: stringR,
          }),
          visible: [
            {
              name: "activities__eu__inside_EU",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "",
          pdfLabel: "Type",
          name: "activities__eu__type",
          type: EInputType.Radio,
          options: [
            {
              label: "LPS (Libre Prestation de Service)",
              value: "LPS (Libre Prestation de Service)",
            },
            {
              label: "LE (Libre Établissement)",
              value: "LE (Libre Établissement)",
            },
          ],
          validation: Yup.mixed().when("activities__eu__inside_EU", {
            is: "Oui",
            then: stringR,
          }),
          visible: [
            {
              name: "activities__eu__inside_EU",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "Nom de la succursale",
          required: true,
          name: "activities__eu__branch__name",
          placeholder: "",
          type: EInputType.Text,
          validation: typeDetailValidation,
          visible: typeDetailVisible,
        },
        {
          initialValue: "",
          label: "Adresse de la succursale",
          required: true,
          name: "activities__eu__branch__address",
          placeholder: "",
          type: EInputType.Text,
          validation: typeDetailValidation,
          visible: typeDetailVisible,
        },
        {
          initialValue: "",
          label: "Nom du responsable de la succursale",
          required: true,
          name: "activities__eu__branch__responsable_name",
          placeholder: "",
          type: EInputType.Text,
          validation: typeDetailValidation,
          visible: typeDetailVisible,
        },
      ],
    },
  ],
};
export default step5;
