export enum EIconNames {
  AddPeople = "add-people",
  Add = "add",
  AlertError = "alert-error",
  AlertInfo = "alert-info",
  Check = "check",
  CircleAdd = "circle-add",
  CircleCheck = "circle-check",
  CircleClose = "circle-close",
  CircleInfo = "circle-info",
  CircleQuestion = "circle-question",
  CircleRemove = "circle-remove",
  Clock = "clock",
  Close = "close",
  Eye = "eye",
  Information = "information",
  LeftArrow = "left-arrow",
  LogoAssurance = "logo-assurance",
  LogoCredit = "logo-credit",
  LogoPatrimoine = "logo-patrimoine",
  LogoImmobilier = "logo-immobilier",
  LogoCNCEF = "logo-cncef",
  Minus = "minus",
  RightArrow = "right-arrow",
  SelectArrow = "select-arrow",
  Upload = "upload",
  undefined = "undefined",
}
