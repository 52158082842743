import React from "react";
import { colors } from "../../theme";
import { useTheme } from "styled-components";
import { Loader, Icon } from "../../components";
import { EIconNames, ButtonVariant } from "../../types";
import {
  Label,
  ContentWrapper,
  StyledButton,
  IconLeft,
  IconRight,
  LoaderWrapper,
} from "./styled-components";

interface Props {
  children: React.ReactNode;
  iconRight?: EIconNames;
  iconLeft?: EIconNames;
  variant?: ButtonVariant;
  isLoading?: boolean;
  onClick?: () => void;
  mt?: boolean;
  type?: "button" | "submit" | "reset";
}

const Button: React.FC<Props> = ({
  children,
  iconRight = null,
  iconLeft = null,
  variant = ButtonVariant.Primary,
  type = "button",
  mt = false,
  isLoading = false,
  onClick,
}) => {
  const theme = useTheme();
  const getIconColor = () => {
    if (
      variant === ButtonVariant.Primary ||
      variant === ButtonVariant.Secondary
    ) {
      return colors.white;
    } else if (variant === ButtonVariant.Tertiary) {
      return theme.colors.accent;
    } else if (variant === ButtonVariant.Quaternary) {
      return colors.primary;
    }
  };
  const getBackgroundColor = () => {
    if (variant === ButtonVariant.Primary) {
      return theme.colors.accent;
    } else if (variant === ButtonVariant.Secondary) {
      return colors.primary;
    } else {
      return colors.white;
    }
  };

  return (
    <StyledButton
      onClick={onClick}
      isLoading={isLoading}
      variant={variant}
      type={type}
      mt={mt}
    >
      <ContentWrapper>
        {iconLeft && iconLeft !== EIconNames.undefined && (
          <IconLeft>
            <Icon
              name={iconLeft}
              height={15}
              width={15}
              color={getIconColor()}
            />
          </IconLeft>
        )}
        <Label variant={variant}>{children}</Label>
        {iconRight && iconRight !== EIconNames.undefined && (
          <IconRight>
            <Icon
              name={iconRight}
              height={15}
              width={15}
              color={getIconColor()}
            />
          </IconRight>
        )}
      </ContentWrapper>
      <LoaderWrapper isLoading={isLoading} color={getBackgroundColor()}>
        <Loader color={getIconColor()} height={15} width={15} />
      </LoaderWrapper>
    </StyledButton>
  );
};

export default Button;
