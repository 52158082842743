import { IStep, EInputType, IVisibleCondition } from "../../../types";
import Yup from "../../../i18n/yup";
import { stringR } from "../../../helpers/validation";
import {
  countriesEU,
  regionsAndFrance,
  boolOptions,
  percentOptions,
} from "../common/options";

const hasOtherVisible: IVisibleCondition[] = [
  {
    name: "activities__has_other",
    value: "Oui",
  },
];
const hasOtherCondition: any = Yup.mixed().when("activities__has_other", {
  is: "Oui",
  then: stringR,
});

const typeDetailValidation: any = Yup.mixed().when("activities__eu__type", {
  is: "LE (Libre Établissement)",
  then: stringR,
});
const typeDetailVisible: IVisibleCondition[] = [
  {
    name: "activities__eu__type",
    value: "LE (Libre Établissement)",
  },
];

const step5: IStep = {
  // ÉTAPE 5
  title: "Périmètre d’activité",
  description: "Détail sur la nature de votre activité",
  fields: [
    {
      ///// SECTION
      title: "Périmètre d’activité",
      fields: [
        {
          initialValue: "",
          label: "Activité principale",
          name: "activities__is_main",
          type: EInputType.Radio,
          options: boolOptions,
          validation: stringR,
        },
        {
          initialValue: "",
          label:
            "Distribuez vous les produits d'assurance uniquement en complément de la vente d'un bien ou d'un service ?",
          name: "activities__only_sale_products",
          type: EInputType.Radio,
          options: boolOptions,
          required: true,
          validation: Yup.mixed().when(
            ["activities__is_main", "entity__category"],
            {
              is: (activities__is_main: string, entity__category: string) =>
                activities__is_main === "Non" && entity__category === "MIA",
              then: stringR,
            }
          ),
          visible: [
            {
              name: "activities__is_main",
              value: "Non",
            },
            {
              name: "entity__category",
              value: "MIA",
            },
          ],
        },
        {
          fieldsetTitle:
            "Type(s) de(s) produit(s) proposé(s) (choix du collège) :",
          fields: [
            {
              initialValue: "",
              label: "Assurance-vie/Epargne",
              required: true,
              name: "activities__percentage__life_insurance",
              type: EInputType.Radio,
              options: percentOptions,
              validation: stringR,
            },
            {
              initialValue: "",
              label: "Affinitaire",
              required: true,
              name: "activities__percentage__affinitaire",
              type: EInputType.Radio,
              options: percentOptions,
              validation: stringR,
            },
            {
              initialValue: "",
              label: "IARD/santé/prévoyance/retraite",
              required: true,
              name: "activities__percentage__iard",
              type: EInputType.Radio,
              options: percentOptions,
              validation: stringR,
            },
            {
              initialValue: "",
              label: "Comparateur",
              required: true,
              name: "activities__percentage__comparator",
              type: EInputType.Radio,
              options: percentOptions,
              validation: stringR,
            },
            {
              initialValue: "",
              label: "Assurance Emprunteur/crédit",
              required: true,
              name: "activities__percentage__loan",
              type: EInputType.Radio,
              options: percentOptions,
              validation: stringR,
            },
            {
              initialValue: "",
              label: "Autre",
              name: "activities__has_other",
              type: EInputType.Radio,
              options: boolOptions,
              validation: Yup.string(),
            },
            {
              initialValue: "",
              label: "Si autre, précisez le type",
              required: true,
              name: "activities__has_other__type",
              type: EInputType.Text,
              validation: hasOtherCondition,
              visible: hasOtherVisible,
            },
            {
              initialValue: "",
              label: "Si autre, précisez sa part",
              required: true,
              name: "activities__percentage__other",
              type: EInputType.Radio,
              options: percentOptions,
              validation: hasOtherCondition,
              visible: hasOtherVisible,
            },
          ],
        },
        {
          initialValue: "",
          label: "Régions dans lesquelles vous exercez votre activité ",
          required: true,
          name: "activities__region",
          placeholder: "Choisissez une région",
          type: EInputType.Select,
          options: regionsAndFrance,
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Passeport européen",
      fields: [
        {
          initialValue: "",
          label:
            "Exercez-vous votre activité d’intermédiation en assurance dans l’Espace Economique de l’Union Européenne ?",
          name: "activities__eu__inside_EU",
          type: EInputType.Radio,
          options: boolOptions,
          validation: Yup.string(),
        },
        {
          initialValue: "",
          label: "Régions dans lesquelles vous exercez votre activité ",
          required: true,
          name: "activities__eu__country",
          placeholder: "Choisissez un pays",
          type: EInputType.Select,
          options: countriesEU,
          validation: Yup.mixed().when("activities__eu__inside_EU", {
            is: "Oui",
            then: stringR,
          }),
          visible: [
            {
              name: "activities__eu__inside_EU",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "",
          pdfLabel: "Type",
          name: "activities__eu__type",
          type: EInputType.Radio,
          options: [
            {
              label: "LPS (Libre Prestation de Service)",
              value: "LPS (Libre Prestation de Service)",
            },
            {
              label: "LE (Libre Établissement)",
              value: "LE (Libre Établissement)",
            },
          ],
          validation: Yup.mixed().when("activities__eu__inside_EU", {
            is: "Oui",
            then: stringR,
          }),
          visible: [
            {
              name: "activities__eu__inside_EU",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "Nom de la succursale",
          required: true,
          name: "activities__eu__branch__name",
          placeholder: "",
          type: EInputType.Text,
          validation: typeDetailValidation,
          visible: typeDetailVisible,
        },
        {
          initialValue: "",
          label: "Adresse de la succursale",
          required: true,
          name: "activities__eu__branch__address",
          placeholder: "",
          type: EInputType.Text,
          validation: typeDetailValidation,
          visible: typeDetailVisible,
        },
        {
          initialValue: "",
          label: "Nom du responsable de la succursale",
          required: true,
          name: "activities__eu__branch__responsable_name",
          placeholder: "",
          type: EInputType.Text,
          validation: typeDetailValidation,
          visible: typeDetailVisible,
        },
      ],
    },
  ],
};
export default step5;
