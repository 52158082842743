import { IStep, EInputType, EInputSize } from "../../../types";
import Yup from "../../../i18n/yup";
import { checkboxGroup, numberR, stringR } from "../../../helpers/validation";
import { regionsAndFrance } from "../common/options";
import { factoryFranceClientType } from "../common";

const step4: IStep = {
  // ÉTAPE 5
  title: "Activité",
  description: "Détail sur la nature de votre activité",
  fields: [
    {
      ///// SECTION
      title: "Activité",
      fields: [
        {
          fieldsetTitle: "Type(s) de missions * :",
          fields: [
            {
              initialValue: [],
              label: "",
              pdfLabel: "Type",
              name: "activities__types",
              type: EInputType.Checkbox,
              size: EInputSize.Large,
              options: [
                {
                  label: "Cession",
                  value: "Cession",
                },
                {
                  label: "Acquisition",
                  value: "Acquisition",
                },
                {
                  label: "Recherche de financements",
                  value: "Recherche de financements",
                },
                {
                  label: "Levée de fonds",
                  value: "Levée de fonds",
                },
                {
                  label: "Evaluation financière",
                  value: "Evaluation financière",
                },
                {
                  label: "Transaction Carte T",
                  value: "Transaction Carte T",
                },
                {
                  label: "Autres conseils aux entreprises",
                  value: "Autres conseils aux entreprises",
                },
              ],
              validation: checkboxGroup,
            },
            {
              initialValue: "",
              label: "Si autre, précisez",
              name: "activities__has_other__type",
              type: EInputType.Text,
              validation: Yup.string(),
              visible: [
                {
                  name: "activities__types",
                  value: "Autres Conseils aux entreprises",
                },
              ],
            },
          ],
        },
        {
          initialValue: "",
          label: "Compétences spécifiques que vous souhaitez mentionner",
          name: "activities__competences",
          type: EInputType.TextArea,
          size: EInputSize.Large,
        },
        {
          initialValue: "",
          label: "Chiffre d'affaires total de la structure N-1",
          required: true,
          name: "activities__CA_total",
          type: EInputType.Number,
          validation: numberR,
        },
        {
          initialValue: "",
          label: "Nombre d'opérations M&A de la structure N-1",
          required: true,
          name: "activities__operation_count",
          type: EInputType.Number,
          validation: numberR,
        },
        {
          initialValue: "",
          label: "Chiffre d'affaires généré par ces opérations N-1",
          required: true,
          name: "activities__operation_CA_",
          type: EInputType.Number,
          validation: numberR,
        },
        {
          initialValue: "",
          label:
            "Merci de décrire, le cas échéant, une ou plusieurs opérations significatives que vous souhaiteriez nous détailler. Ajoutez les liens ou url vers un article de presse ou autres informations en ligne (500 caractères maximum) ",
          name: "activities__operation_presentation",
          type: EInputType.TextArea,
          size: EInputSize.Large,
        },
        {
          fieldsetTitle: "Localisation de la clientèle",
          fields: [...factoryFranceClientType],
        },
        {
          initialValue: "",
          label: "Régions dans lesquelles vous exercez votre activité",
          required: true,
          name: "activities__region",
          placeholder: "Choisissez une région",
          type: EInputType.Select,
          options: regionsAndFrance,
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Motivation d'adhésion",
      fields: [
        {
          initialValue: "",
          label: "",
          name: "activities__motivations",
          type: EInputType.TextArea,
          size: EInputSize.Large,
        },
      ],
    },
  ],
};
export default step4;
