import React from "react";
import FormHelper from "../../helpers/FormHelper";
import { EContainerVariant, EFormType, ETextVariant } from "../../types";
import FirstStepFooter from "./FirstStepFooter";
import FirstStepRenewalFooter from "./FirstStepRenewalFooter";
import FirstStepAttachments from "./FirstStepAttachments";
import FirstStepTableOfContents from "./FirstStepTableOfContents";
import { Fieldset, Text } from "../../components";

interface Props {
  formHelper: FormHelper;
}
const FirstStep: React.FC<Props> = ({ formHelper }) => {
  const getDescription = () => {
    if (formHelper.isRenewal) {
      return "Votre demande de renouvellement ne sera traitée par nos services qu’après avoir complété le formulaire, payé votre cotisation et transmis les pièces justificatives. Toutefois, nous vous rappelons qu’il est possible de réaliser le renouvellement de l’immatriculation à l’Orias en parallèle du renouvellement CNCEF.";
    } else if (formHelper.type === EFormType.Patrimoine) {
      return "Votre programme d'activité ne sera traité par nos services qu’après avoir finalisé celui-ci et transmis les pièces justificatives.";
    } else {
      return "Votre demande d'adhésion ne sera traitée par nos services qu’après avoir finalisé celle-ci et transmis les pièces justificatives.";
    }
  };
  return (
    <>
      {!formHelper.isSimple && (
        <>
          <FirstStepTableOfContents formHelper={formHelper} />
          <FirstStepAttachments
            attachmentSections={formHelper.attachmentSections}
          />
          <Fieldset containerVariant={EContainerVariant.Small}>
            <Text variant={ETextVariant.Body2}>{getDescription()}</Text>
            <Text variant={ETextVariant.Body1}>
              Pour toutes questions merci de nous adresser votre demande via
              le&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cncef.org/contact/"
              >
                formulaire de contact
              </a>{" "}
              sur notre site{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cncef.org/"
              >
                www.cncef.org
              </a>
            </Text>
          </Fieldset>
        </>
      )}
      {!formHelper.isRenewal && <FirstStepFooter formHelper={formHelper} />}
      {formHelper.isRenewal && (
        <FirstStepRenewalFooter formHelper={formHelper} />
      )}
    </>
  );
};

export default FirstStep;
