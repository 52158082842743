import styled from "styled-components";
import { fonts } from "../../theme";
import { ETextVariant } from "../../types";

export const StyledText = styled.p<{
  variant: ETextVariant;
  color: string;
  center: boolean;
  padding: boolean;
}>`
  color: ${(props) => props.color};
  text-align: ${(props) => (props.center ? "center" : "left")};

  a {
    text-decoration: underline;
  }
  ${(props) =>
    props.padding &&
    `
    padding: 0 0.5rem;
  `}

  ${(props) =>
    props.variant === ETextVariant.Title1 &&
    `
    font-size: ${fonts.size.huge};
    font-weight: 500;
    font-family: ${fonts.family.secondary};
    letter-spacing: 0.8px;
    line-height: 0.85;
  `}

  ${(props) =>
    props.variant === ETextVariant.Title2 &&
    `
      font-size: ${fonts.size.xxl};
      font-weight: 400;
    `}
  ${(props) =>
    props.variant === ETextVariant.Title3 &&
    `
      font-size: ${fonts.size.xl};
      font-weight: 500;
      font-family: ${fonts.family.secondary};
      letter-spacing: 0.5px;
    `}

    ${(props) =>
    props.variant === ETextVariant.Title4 &&
    `
        font-size: ${fonts.size.m};
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 1.1rem;
      `}
      
    ${(props) =>
    props.variant === ETextVariant.Title5 &&
    `
        font-size: ${fonts.size.m};
        margin-bottom: 1.1rem;
      `}

    ${(props) =>
    (props.variant === ETextVariant.Body1 ||
      props.variant === ETextVariant.Body2 ||
      props.variant === ETextVariant.Body3 ||
      props.variant === ETextVariant.Notice) &&
    `
      font-size: ${fonts.size.m};
      line-height: 1.4;
      margin: 2rem 0;
    `}

    ${(props) =>
    props.variant === ETextVariant.Body2 &&
    `
      font-weight: 700;
    `}

    ${(props) =>
    props.variant === ETextVariant.Notice &&
    `
      font-size: ${fonts.size.s};
      padding: 20px;
      line-height: 1.7;
      background: white;
    `}

    ${(props) =>
    props.variant === ETextVariant.Body3 &&
    `
      font-style: italic;
    `}
`;
