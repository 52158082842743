const fonts = {
  /* semantic palette */
  family: {
    primary: "'objektiv-mk1', sans-serif",
    secondary: "'big-caslon-fb', serif",
  },
  size: {
    huge: "clamp(3.4rem, (46 / 1080 * 100vw), 4.6rem)",
    xxl: "clamp(2.6rem, (39 / 1080 * 100vw), 3.9rem)",
    xl: "clamp(2.4rem, (32 / 1080 * 100vw), 3.2rem)",
    l: "1.8rem",
    m: "1.6rem",
    s: "1.4rem",
    xs: "1.2rem",
    xxs: "1rem",
  },
  weight: {
    base: 400,
    heading: 700,
  },
  lineHeight: {
    normal: 1.4,
    heading: 1.2,
  },
};
export default fonts;
