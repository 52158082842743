import React, { useCallback, useState, useMemo } from "react";
import { colors } from "../theme";
// import original module declarations
import "styled-components";

// and extend them!
declare module "styled-components" {
  export interface DefaultTheme {
    colors: {
      accent: string;
      primary: string;
    };
  }
}

const defaultTheme = {
  colors: {
    accent: colors.accent,
  },
};
const accesibilityTheme = {
  colors: {
    accent: colors.primary,
  },
};

const ThemeContext = React.createContext<
  | {
      isAccessibilityModeActive: boolean;
      switchAccessibilityMode: () => void;
      theme: any;
    }
  | undefined
>(undefined);

interface ProviderProps {
  children: React.ReactNode;
}
function ThemeProvider({ children }: ProviderProps) {
  const [
    isAccessibilityModeActive,
    setIsAccessibilityModeActive,
  ] = useState<boolean>(
    localStorage.getItem("accessibility") === "1" ? true : false
  );

  const switchAccessibilityMode = useCallback(() => {
    const newValue = !isAccessibilityModeActive;
    setIsAccessibilityModeActive(newValue);
    localStorage.setItem("accessibility", newValue ? "1" : "0");
  }, [isAccessibilityModeActive, setIsAccessibilityModeActive]);

  const theme = useMemo(() => {
    return isAccessibilityModeActive ? accesibilityTheme : defaultTheme;
  }, [isAccessibilityModeActive]);

  return (
    <ThemeContext.Provider
      value={{
        isAccessibilityModeActive,
        switchAccessibilityMode,
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
export { ThemeProvider, ThemeContext };
export default ThemeProvider;
