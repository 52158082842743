import React from "react";
import { Icon, Loader } from "../../components";
import {
  Wrapper,
  Overlay,
  Content,
  Header,
  Body,
  Title,
  Description,
  CloseButton,
  LoaderWrapper,
} from "./styled-components";
import { EIconNames } from "../../types";

interface Props {
  children: React.ReactNode;
  open: boolean;
  isLoading?: boolean;
  title: string;
  description?: string;
  handleClose: () => void;
}
const Modal: React.FC<Props> = ({
  open = false,
  handleClose,
  title,
  description = "",
  children,
  isLoading = false,
}) => {
  return (
    <Wrapper open={open}>
      <Overlay type="button" onClick={handleClose} />
      <Content>
        <LoaderWrapper isLoading={isLoading}>
          <Loader />
        </LoaderWrapper>
        <Header>
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </Header>
        <Body>{children}</Body>
        <CloseButton type="button" onClick={handleClose}>
          <Icon width={16} height={15} name={EIconNames.Close} />
        </CloseButton>
      </Content>
    </Wrapper>
  );
};

export default Modal;
