import React from "react";
import { StyledListItem } from "./styled-components";

interface Props {
  children: React.ReactNode;
}

const ListItem: React.FC<Props> = ({ children }) => {
  return <StyledListItem>{children}</StyledListItem>;
};

export default ListItem;
