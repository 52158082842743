import {
  IStep,
  EInputType,
  ETextVariant,
  EFieldContainerVariant,
  ESectionVariant,
  EContainerVariant,
} from "../../../types";
import {
  stringR,
  checkboxGroup,
  files,
  filesR,
} from "../../../helpers/validation";
import { constants } from "../common";
import documents from "../../documents";

const step6: IStep = {
  title: "Pièces justificatives",
  description:
    "Transmission des pièces justificatives et finalisation de la demande",
  fields: [
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          variant: ETextVariant.Body1,
          content: "J'atteste sur l'honneur",
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__1",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "Ne faire l’objet d’aucune procédure contentieuse ou disciplinaire en cours *",
              value:
                "Ne faire l’objet d’aucune procédure contentieuse ou disciplinaire en cours *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__2",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "Je m’engage à informer la CNCEF Assurance de tout élément de nature à affecter ma situation *",
              value:
                "Je m’engage à informer la CNCEF Assurance de tout élément de nature à affecter ma situation *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__3",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label: (
                <>
                  Je reconnais avoir pris connaissance des{" "}
                  <a
                    href={documents.statutsAssurance}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    statuts
                  </a>{" "}
                  et du{" "}
                  <a
                    href={documents.reglementInterieurAssurance}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    règlement intérieur
                  </a>{" "}
                  que j’approuve et m’engage à respecter *
                </>
              ),
              value:
                "Je reconnais avoir pris connaissance des statuts, du réglement intérieur que j’approuve et m’engage à respecter",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label:
            "J’autorise la CNCEF Assurance à faire figurer mes coordonnées dans l’annuaire de la CNCEF",
          required: true,
          name: "authorization__coordinates",
          type: EInputType.Radio,
          containerVariant: EFieldContainerVariant.BigLabel,
          options: [
            {
              label: "Oui",
              value: "Oui",
            },
            {
              label: "Non",
              value: "Non",
            },
          ],
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        {
          initialValue: [],
          label: "Attestation d'immatriculation ORIAS si déjà immatriculé(e)",
          name: "file__orias",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Extrait Kbis datant de moins de 3 mois ou avis de situation SIREN si non-inscrit au RCS",
          name: "file__kbis_or_siren",
          type: EInputType.File,
          validation: filesR,
          required: true,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: (
            <>
              Attestation RC PRO ou attestation de mandat si MIA (
              <a
                href={documents.attestationIAS}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                télécharger le modèle
              </a>
              )
            </>
          ),
          pdfLabel: "Attestation RC PRO ou attestation de mandat",
          name: "file__attestation_rcpro_or_mandat",
          type: EInputType.File,
          validation: filesR,
          required: true,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: "Attestation de Garantie financière si encaissement de fonds",
          name: "file__garantie",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Justificatif de capacité professionnelle du ou des dirigeants si non-inscrit(s) à l’ORIAS",
          name: "file__degree",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: (
            <>
              Liste du personnel IAS à jour (obligatoire si salarié déclaré) (
              <a
                href={documents.listePersonnel}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                télécharger le modèle
              </a>
              )
            </>
          ),
          pdfLabel: "Liste du personnel IAS à jour",
          name: "file__list",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: (
            <>
              Déclaration sur l’honneur concernant le contrôle d’honorabilité
              des salariés signée par le dirigeant (obligatoire si salarié
              déclaré) (
              <a
                href={documents.declarationHonneurAssurance}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                télécharger le modèle
              </a>
              )
            </>
          ),
          pdfLabel:
            "Déclaration sur l’honneur concernant le contrôle d’honorabilité des salariés signée par le dirigeant (obligatoire si salarié déclaré)",
          name: "file__declaration",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
      ],
    },
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__4",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "J’atteste sur l’honneur que toutes les données indiquées sur le dossier d’adhésion, rempli par mes soins, sont véridiques *",
              value:
                "J’atteste sur l’honneur que toutes les données indiquées sur le dossier d’adhésion, rempli par mes soins, sont véridiques *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__5",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "En cochant cette case, j’accepte les conditions d’adhésion demandées par la CNCEF Assurance, association disposant de la reconnaissance de l’Autorité de Contrôle Prudentiel et de Résolution (ACPR). *",
              value:
                "En cochant cette case, j’accepte les conditions d’adhésion demandées par la CNCEF Assurance, association disposant de la reconnaissance de l’Autorité de Contrôle Prudentiel et de Résolution (ACPR). *",
            },
          ],
          validation: checkboxGroup,
        },
      ],
    },
  ],
};

export default step6;
