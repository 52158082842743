import styled from "styled-components";
import { breakpoints, colors, fonts } from "../../theme";

export const Wrapper = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
export const Label = styled.span<{ active: boolean }>`
  font-size: ${fonts.size.s};
  color: ${(props) => (props.active ? colors.primary : colors.grey)};
  font-weight: 500;
  transition: 0.3s;
  margin-right: 1rem;
  ${breakpoints.max.md} {
    font-size: ${fonts.size.xs};
  }
`;
export const StyledToggle = styled.span<{ active: boolean }>`
  display: block;
  position: relative;
  width: 4.2rem;
  height: 2.3rem;
  margin: 0;
  border: 1px solid ${colors.greyBorder};
  border-radius: 100px;
  background: ${colors.white};
  cursor: pointer;

  &::after {
    position: absolute;
    top: 0.2rem;
    left: 0.3rem;
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 90px;
    background: ${colors.grey};
    transition: 0.3s;
    content: "";

    ${(props) =>
      props.active &&
      `
    left: calc(100% - 0.3rem);
    background-color: ${colors.primary};
    transform: translateX(-100%);
    `}
  }
`;
