import Yup from "../../../i18n/yup";
import { EInputSize, EInputType } from "../../../types";

const clientTypes = [
  {
    name: "client__non_professionnal",
    value: "Clients non-professionnels",
  },
  {
    name: "client__professionnal",
    value: "Clients professionnels",
  },
  {
    name: "client__eligible_counterparty",
    value: "Contreparties éligibles",
  },
].map(({ name, value }) => [
  {
    initialValue: "",
    label: "",
    name: name,
    type: EInputType.Checkbox,
    options: [
      {
        value: value,
        label: value,
      },
    ],
  },
  {
    initialValue: "",
    label: "Préciser la typologie des clients visés au sein de la catégorie",
    required: true,
    name: `${name}__typology`,
    placeholder: "Préciser la typologie…",
    type: EInputType.Text,
    size: EInputSize.Large,
    validation: Yup.mixed().when(name, (values: any) => {
      return values && values.includes(value)
        ? Yup.string().required()
        : Yup.string();
    }),

    visible: [
      {
        name: name,
        value: value,
      },
    ],
  },
  {
    initialValue: "",
    label: "Localisation (France / Etranger)",
    required: true,
    name: `${name}__location`,
    placeholder: "Préciser la localisation",
    type: EInputType.Text,
    size: EInputSize.Large,
    validation: Yup.mixed().when(name, (values: any) => {
      return values && values.includes(value)
        ? Yup.string().required()
        : Yup.string();
    }),

    visible: [
      {
        name: name,
        value: value,
      },
    ],
  },
]);
const factoryClientType = clientTypes.reduce((acc, currentValue) => {
  acc.push(...currentValue);
  return acc;
}, []);

export default factoryClientType;
