import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home, Maintenance } from "./pages";
import { FormPageTemplate } from "./template";
import "./theme/reset.css";
import {
  assuranceSchema,
  assuranceFinalSchema,
  creditSchema,
  creditFinalSchema,
  immobilierSchema,
  cncefSchema,
  franceSchema,
  creditRenewalSchema,
  assuranceCreditRenewalSchema,
  assuranceRenewalSchema,
  patrimoineSchema,
} from "./config/schema";

export default function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/maintenance">
            <Maintenance />
          </Route>
          <Route path="/patrimoine">
            <FormPageTemplate schema={patrimoineSchema} />
          </Route>
          <Route path="/assurance">
            <FormPageTemplate schema={assuranceSchema} />
          </Route>
          <Route path="/credit">
            <FormPageTemplate schema={creditSchema} />
          </Route>
          <Route path="/immobilier">
            <FormPageTemplate schema={immobilierSchema} />
          </Route>
          <Route path="/cncef">
            <FormPageTemplate schema={cncefSchema} />
          </Route>
          <Route path="/france">
            <FormPageTemplate schema={franceSchema} />
          </Route>
          <Route path="/credit-final">
            <FormPageTemplate schema={creditFinalSchema} />
          </Route>
          <Route path="/assurance-final">
            <FormPageTemplate schema={assuranceFinalSchema} />
          </Route>
          <Route path="/credit-renewal">
            <FormPageTemplate schema={creditRenewalSchema} />
          </Route>
          <Route path="/assurance-credit-renewal">
            <FormPageTemplate schema={assuranceCreditRenewalSchema} />
          </Route>
          <Route path="/assurance-renewal">
            <FormPageTemplate schema={assuranceRenewalSchema} />
          </Route>
        </Switch>
      </Router>
    </>
  );
}
