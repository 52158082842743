import {
  IStep,
  EInputType,
  ETextVariant,
  EFieldContainerVariant,
  ESectionVariant,
  EContainerVariant,
  EComparator,
} from "../../../types";
import {
  stringR,
  checkboxGroup,
  files,
  filesR,
} from "../../../helpers/validation";
import Yup from "../../../i18n/yup";
import { constants } from "../common";
import documents from "../../documents";

const step8: IStep = {
  // ÉTAPE 8
  title: "Pièces justificatives",
  description:
    "Transmission des pièces justificatives et finalisation de la demande",
  fields: [
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          variant: ETextVariant.Body1,
          content: "J'atteste sur l'honneur",
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__1",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "N’avoir fait l’objet d’aucune condamnation pénale, ni de sanction civile ou administrative de nature à m’interdire de gérer, administrer ou de diriger une personne morale ou d’exercer une activité commerciale au cours des cinq dernières années *",
              value:
                "N’avoir fait l’objet d’aucune condamnation pénale, ni de sanction civile ou administrative de nature à m’interdire de gérer, administrer ou de diriger une personne morale ou d’exercer une activité commerciale au cours des cinq dernières années *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__2",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "Ne faire l’objet d’aucune procédure contentieuse ou disciplinaire en cours relative aux points ci-dessus *",
              value:
                "Ne faire l’objet d’aucune procédure contentieuse ou disciplinaire en cours relative aux points ci-dessus *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__3",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "Je m'engage à informer la CNCEF France M&A de tout élément de nature à affecter ma situation. *",
              value:
                "Je m'engage à informer la CNCEF France M&A de tout élément de nature à affecter ma situation. *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__4",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label: (
                <>
                  Je reconnais avoir pris connaissance des{" "}
                  <a
                    href={documents.franceStatuts}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    statuts
                  </a>
                  , du{" "}
                  <a
                    href={documents.franceDeontologie}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    code de déontologie
                  </a>{" "}
                  et du{" "}
                  <a
                    href={documents.franceReglement}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    règlement intérieur
                  </a>{" "}
                  que j’approuve et m’engage à respecter *
                </>
              ),
              value:
                "Je reconnais avoir pris connaissance des statuts (disponibles sur le site www.cncef.org), du code de bonne conduite que j’approuve et m’engage à respecter *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label:
            "J'autorise la CNCEF France M&A à faire figurer mes coordonnées dans l'annuaire de la CNCEF",
          name: "authorization__coordinates",
          required: true,
          type: EInputType.Radio,
          containerVariant: EFieldContainerVariant.BigLabel,
          options: [
            {
              label: "Oui",
              value: "Oui",
            },
            {
              label: "Non",
              value: "Non",
            },
          ],
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Pièces à joindre au dossier",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        {
          initialValue: [],
          required: true,
          label:
            "Extrait kbis datant de moins de 3 mois ou Avis de situation SIREN si non-inscrit au RCS",
          name: "file__kbis_or_siren",
          type: EInputType.File,
          validation: filesR,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          required: true,
          label: "CV du ou des mandataires sociaux",
          name: "file__cv",
          type: EInputType.File,
          validation: filesR,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Deux recommandations écrites/ mails attestant l’honorabilité et le professionnalisme (pairs, clients..)",
          name: "file__recommendation",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: "Liste des salariés",
          required: true,
          name: "file__list",
          type: EInputType.File,
          validation: Yup.mixed().when(
            "employees_france_count",
            (value: number) => {
              return value && value >= 10 ? filesR : files;
            }
          ),
          max: constants.maxFile,
          visible: [
            {
              name: "employees_france_count",
              comparator: EComparator.supE,
              value: 10,
            },
          ],
        },
        {
          initialValue: [],
          label: "Plaquette de présentation (optionnel)",
          name: "file__presentation",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
      ],
    },
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__5",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "J’atteste sur l’honneur que toutes les données indiquées sur le dossier d’adhésion, rempli par mes soins, sont véridiques *",
              value:
                "J’atteste sur l’honneur que toutes les données indiquées sur le dossier d’adhésion, rempli par mes soins, sont véridiques *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__6",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "En cochant ce cas, j'accepte les conditions d'adhésion demandées par CNCEF France M&A.*",
              value:
                "En cochant ce cas, j'accepte les conditions d'adhésion demandées par CNCEF France M&A.*",
            },
          ],
          validation: checkboxGroup,
        },
      ],
    },
  ],
};

export default step8;
