import { IStep, EInputType, EInputSize } from "../../../types";
import Yup from "../../../i18n/yup";
import { stringR } from "../../../helpers/validation";

const step6: IStep = {
  title: "Autres activités",
  description: "Renseignements sur vos autres activités réglementées",
  fields: [
    {
      ///// SECTION
      title: "Activités réglementées",
      fields: [
        {
          initialValue: "",
          label: "",
          pdfLabel: "Activités réglementées",
          name: "activities",
          type: EInputType.Checkbox,
          size: EInputSize.Large,
          options: [
            {
              label:
                "Intermédiaire en opérations de banque et en services de paiement",
              value:
                "Intermédiaire en opérations de banque et en services de paiement",
            },
            {
              value: "Intermédiaire en assurance",
              label: "Intermédiaire en assurance",
            },
            {
              label: "Conseil en Investissements Financiers",
              value: "Conseil en Investissements Financiers",
            },
          ],
        },
        {
          initialValue: "",
          label:
            "Si conseil en Investissements Financiers, nom de l’association agréée par l’AMF",
          required: true,
          name: "activities__amf_association_name",
          type: EInputType.Text,
          validation: Yup.mixed().when("activities", {
            is: "Conseil en Investissements Financiers",
            then: stringR,
          }),
          visible: [
            {
              name: "activities",
              value: "Conseil en Investissements Financiers",
            },
          ],
        },
        // {
        //   initialValue: "",
        //   label:
        //     "Si intermédiaire en assurance, merci de préciser si celle-ci est exercée seulement en activité accessoire à celle du crédit",
        //   name: "activities__amf_association_name",
        //   type: EInputType.Radio,
        //   options: boolOptions,
        //   validation: Yup.mixed().when("activities", {
        //     is: "Intermédiaire en assurance",
        //     then: stringR,
        //   }),
        //   visible: [
        //     {
        //       name: "activities",
        //       value: "Intermédiaire en assurance",
        //     },
        //   ],
        // },
      ],
    },
  ],
};

export default step6;
