import styled from "styled-components";
import { breakpoints, colors, fonts } from "../../theme";
import { ERepeaterButtonVariant } from "../../types";

export const StyledRepeaterButton = styled.button<{
  type: string;
  remove: boolean;
  withBorderTop: boolean;
  variant: ERepeaterButtonVariant;
}>`
  width: calc(100% + 6rem);
  background-color: ${colors.secondaryBackgroundColor};
  display: flex;
  align-items: center;
  padding: 1rem 4rem;
  margin: 4rem -3rem 0 -3rem;
  cursor: pointer;

  ${(props) =>
    props.withBorderTop &&
    `
      border-top: solid 1rem ${colors.white};
    `}

  ${(props) =>
    props.remove &&
    `
      margin-bottom: 4rem;
    `}

  ${(props) =>
    props.variant === ERepeaterButtonVariant.Small &&
    `
        width: 35.2rem;
        margin: 2rem auto 0 33rem;
        padding: 1rem;

        ${breakpoints.max.lg} {
          margin-left: 40%;
        }

        ${breakpoints.max.md} {
          margin-left: 0;
          width: 100%;
        }

      `}
  ${(props) =>
    props.remove &&
    props.withBorderTop &&
    props.variant === ERepeaterButtonVariant.Small &&
    `
    border-top: 0;
    margin-top: 5rem;
    `}
`;

export const Label = styled.span`
  font-size: ${fonts.size.m};
  font-weight: 500;
  color: ${colors.primary};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  margin-right: 1rem;
`;
