import React from "react";
import { useTheme } from "styled-components";
import Icon from "../Icon/Icon";
import { EIconNames, ERepeaterButtonVariant } from "../../types";
import { Label, StyledRepeaterButton, IconWrapper } from "./styled-components";

interface Props {
  children: React.ReactNode;
  remove?: boolean;
  first?: boolean;
  variant?: ERepeaterButtonVariant;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
}

const RepeaterButton: React.FC<Props> = ({
  children,
  variant = ERepeaterButtonVariant.Large,
  type = "button",
  remove = false,
  first = false,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <StyledRepeaterButton
      onClick={onClick}
      type={type}
      remove={remove}
      withBorderTop={remove && !first}
      variant={variant}
    >
      <IconWrapper>
        <Icon
          name={remove ? EIconNames.CircleRemove : EIconNames.CircleAdd}
          height={14}
          width={14}
          color={theme.colors.accent}
        />
      </IconWrapper>
      <Label>{children}</Label>
    </StyledRepeaterButton>
  );
};

export default RepeaterButton;
