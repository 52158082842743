import styled from "styled-components";
import { colors, fonts } from "../../theme";
import { EInputSize, EInputType } from "../../types";
import { CheckIcon } from "../../assets/svg";

export const Wrapper = styled.div<{ center: boolean; size: EInputSize }>`
  margin-right: 2rem;
  padding-top: 0.8rem;
  display: flex;

  ${(props) =>
    props.size === EInputSize.Large &&
    `
    width: 100%;
  `}

  ${(props) =>
    props.center &&
    `
		justify-content: center;
    margin-right: 0;
    padding-top: 0;
  `}
`;

export const Label = styled.label<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 400;
  font-size: ${fonts.size.m};
  margin: 0;

  ${(props) =>
    props.disabled &&
    `
		cursor: not-allowed;
    opacity: 0.5;
  `}
`;
export const TooltipWrapper = styled.div`
  font-size: 0;
  margin-top: 0.3rem;
  margin-left: 1rem;
  align-items: center;
  display: flex;
`;
export const Input = styled.input`
  height: 0;
  width: 0;
  position: absolute;
  z-index: -1;

  &:focus + span {
    border-color: ${colors.primary};
  }
`;
export const Check = styled.span<{
  checked: boolean;
  disabled: boolean;
  type: EInputType;
}>`
  border: 2px solid ${colors.greyBorder};
  background-color: ${colors.white};
  width: 2.4rem;
  flex: 0 0 2.4rem;
  height: 2.4rem;

  ${(props) =>
    props.type === EInputType.Radio &&
    `
			border-radius: 100%;
      position: relative;

      &:before{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.2rem;
        border-radius: 100%;
        height: 1.2rem;
        transform: translate(-50%, -50%);
        background-color: ${colors.primary};
        opacity: 0;
  
      }
		`}

  ${(props) =>
    props.checked &&
    `
			border-color: ${colors.primary};
      &:before{
        opacity:1;
      }
		`}

    ${(props) =>
    props.checked &&
    props.type === EInputType.Checkbox &&
    `
			background: url(${CheckIcon}) 50% 50% no-repeat;
			background-size: 1.3rem;
      `}
`;

export const Text = styled.span<{ checked: boolean; disabled: boolean }>`
  font-size: ${fonts.size.m};
  margin-left: 1.6rem;
  ${(props) =>
    props.checked &&
    `
			color: ${colors.primary};
		`};

  a {
    text-decoration: underline;
  }
`;
