import {
  EInputType,
  EFormType,
  ESectionVariant,
  EComparator,
  ETextVariant,
} from "../../../../types";
import { files, filesR } from "../../../../helpers/validation";
import { constants } from "..";
import Yup from "../../../../i18n/yup";
import documents from "../../../documents";
import { isAssurance, isCredit } from "../../../../helpers";

const factoryRenewalStep3 = (type: EFormType = EFormType.Credit) => ({
  title: "Pièces justificatives",
  description:
    "Transmission des pièces justificatives et finalisation de la demande de renouvellement",
  fields: [
    {
      ///// SECTION
      title: "",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        {
          initialValue: [],
          label: (
            <>
              Liste des personnes physiques à jour (
              <a
                href={documents.listePersonnesPhysiques}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                télécharger le modèle
              </a>
              )
            </>
          ),
          pdfLabel: "Liste des personnes physiques à jour",
          name: "file__list",
          type: EInputType.File,
          validation: filesR,
          required: true,
          max: constants.maxFile,
        },
        ...(isAssurance(type)
          ? [
              {
                initialValue: [],
                label: (
                  <>
                    Attestation d’honorabilité de l'employeur pour ses salariés
                    IAS (
                    <a
                      href={documents.declarationHonneurAssurance}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      télécharger le modèle
                    </a>
                    )
                  </>
                ),
                pdfLabel:
                  "Attestation d’honorabilité de l'employeur pour ses salariés IAS",
                name: "file__declaration_ias",
                type: EInputType.File,
                validation: Yup.mixed().when(
                  "employees_count_ias",
                  (value: number) => {
                    return value && value >= 1 ? filesR : files;
                  }
                ),
                required: true,
                max: constants.maxFile,
                visible: [
                  {
                    comparator: EComparator.sup,
                    name: "employees_count_ias",
                    value: 0,
                  },
                ],
              },
            ]
          : []),
        ...(isCredit(type)
          ? [
              {
                initialValue: [],
                label: (
                  <>
                    Attestation d'honorabilité de l'employeur pour ses salariés
                    IOBSP (
                    <a
                      href={documents.declarationHonneurCredit}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      télécharger le modèle
                    </a>
                    )
                  </>
                ),
                pdfLabel:
                  "Attestation d'honorabilité de l'employeur pour ses salariés IOBSP",
                name: "file__declaration_iob",
                type: EInputType.File,
                validation: Yup.mixed().when(
                  "employees_count_iobsp",
                  (value: number) => {
                    return value && value >= 1 ? filesR : files;
                  }
                ),
                required: true,
                max: constants.maxFile,
                visible: [
                  {
                    comparator: EComparator.sup,
                    name: "employees_count_iobsp",
                    value: 0,
                  },
                ],
              },
            ]
          : []),
        {
          initialValue: [],
          label: "Mandat(s) d'encaissement",
          name: "file__mandat_ias",
          type: EInputType.File,
          required: false,
          max: constants.maxFile,
          visible: [
            {
              name: "entity__fond_encaissement_ias",
              value: "Oui",
            },
          ],
          validation: Yup.mixed().when("entity__fond_encaissement_ias", {
            is: "Oui",
            then: files,
          }),
        },
      ],
    },
    {
      // SECTION
      title: "",
      variant: ESectionVariant.Secondary,
      fields: [
        {
          variant: ETextVariant.Body2,
          content:
            "Votre demande de renouvellement ne sera traitée par nos services qu’après avoir complété le formulaire, payé votre cotisation et transmis les pièces justificatives. Toutefois, nous vous rappelons qu’il est possible de réaliser le renouvellement de l’immatriculation à l’Orias en parallèle du renouvellement CNCEF. ",
        },
      ],
    },
  ],
});

export default factoryRenewalStep3;
