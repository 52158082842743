import React from "react";
import { useTheme } from "styled-components";
import { EFormSubType, EFormType, EIconNames, ETextVariant } from "../../types";
import { BigSection, Text } from "../../components";
import FormHelper from "../../helpers/FormHelper";

interface Props {
  formHelper: FormHelper;
}
const FirstStepHeader: React.FC<Props> = ({ formHelper }) => {
  const theme = useTheme();
  return formHelper.subtype === EFormSubType.Classic ? (
    <BigSection color={formHelper.color} icon={EIconNames.Clock}>
      <Text variant={ETextVariant.Title3} color={theme.colors.accent}>
        Avant de commencer
      </Text>

      {formHelper.type === EFormType.Patrimoine ? (
        <Text variant={ETextVariant.Body2}>
          Le programme d'activité est complété par « ÉTAPES » afin que vous
          puissiez finaliser votre demande quand vous le souhaitez. Il vous sera
          demandé de vous identifier avec votre numéro de téléphone, lorsque
          vous reprendrez la saisie du programme.
        </Text>
      ) : (
        <Text variant={ETextVariant.Body2}>
          Votre demande d'adhésion est complétée par « ÉTAPES » afin que vous
          puissiez finaliser votre demande quand vous le souhaitez. Il vous sera
          demandé de vous identifier avec votre numéro de téléphone, lorsque
          vous reprendrez la saisie du formulaire.
        </Text>
      )}

      <Text variant={ETextVariant.Body2}>
        À noter : une fois qu'une étape est enregistrée, vous pouvez modifier
        les informations en cliquant sur "Etape précédente" (de 1 à{" "}
        {formHelper.steps.length - 2}).
      </Text>
    </BigSection>
  ) : formHelper.subtype === EFormSubType.Final ? (
    <BigSection color={formHelper.color} icon={EIconNames.Clock}>
      <Text variant={ETextVariant.Title3} color={theme.colors.accent}>
        Avant de commencer
      </Text>
      <Text variant={ETextVariant.Body2}>
        La demande d’adhésion est complétée par « ÉTAPES » afin que vous
        puissiez finaliser votre demande quand vous le souhaitez. Il vous sera
        demandé de vous identifier avec votre numéro de téléphone, lorsque que
        vous reprendrez la saisie du formulaire.
      </Text>
      <Text variant={ETextVariant.Body2}>
        Le formulaire est pré-rempli avec les informations renseignées lors de
        votre pré-inscription. Vous avez 2 mois, à compter de la date de
        validation de votre pré-inscription, pour remplir ce formulaire dans son
        intégralité.
      </Text>
    </BigSection>
  ) : formHelper.subtype === EFormSubType.Renewal ? (
    <BigSection color={formHelper.color} icon={EIconNames.Clock}>
      <Text variant={ETextVariant.Title3} color={theme.colors.accent}>
        Avant de commencer
      </Text>
      <Text variant={ETextVariant.Body1}>
        Le renouvellement de votre adhésion s'organise en « ÉTAPES » afin que
        vous puissiez finaliser votre formulaire quand vous le souhaitez.
      </Text>
      <Text variant={ETextVariant.Body2}>
        À noter : une fois qu'une étape est enregistrée, vous pouvez modifier
        les informations en cliquant sur "Etape précédente".
      </Text>
    </BigSection>
  ) : (
    <BigSection color={formHelper.color} icon={EIconNames.Clock}>
      <Text variant={ETextVariant.Title3} color={theme.colors.accent}>
        Avant de commencer
      </Text>
      <Text variant={ETextVariant.Body1}>
        Merci de l’intérêt que vous portez à la CNCEF {formHelper.label}.
      </Text>
      <Text variant={ETextVariant.Body1}>
        Ce formulaire est une pré-inscription qui vous ouvre d’ores et déjà les
        droits d’adhérent à la CNCEF {formHelper.label} et vous permettra une
        fois ce dernier complété et la cotisation réglée, d’accéder à votre
        espace adhérent. Il vous sera demandé dans un second temps de compléter
        votre demande d’adhésion en fournissant des informations
        complémentaires, dans un délai de deux mois.
      </Text>
      <Text variant={ETextVariant.Body1}>
        Pour cela, nous vous demanderons de vous identifier avec votre numéro de
        téléphone portable.
      </Text>
      <Text variant={ETextVariant.Body2}>
        Ce formulaire est réservé aux personnes déjà immatriculées à l'Orias,
        pour la catégorie demandée.
      </Text>
    </BigSection>
  );
};

export default FirstStepHeader;
