import React, { useState } from "react";
import { useTheme } from "styled-components";
import { Icon } from "../../components";
import { EIconNames } from "../../types";
import {
  Wrapper,
  IconWrapper,
  Overlay,
  TooltipWrapper,
} from "./styled-components";

interface Props {
  children: React.ReactNode;
}
const Tooltip: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Wrapper>
      <IconWrapper type="button" onClick={() => setIsOpen(true)}>
        <Icon
          name={EIconNames.CircleInfo}
          color={theme.colors.accent}
          width={20}
          height={20}
        />
      </IconWrapper>
      <Overlay onClick={() => setIsOpen(false)} isOpen={isOpen} />
      <TooltipWrapper isOpen={isOpen}>{children}</TooltipWrapper>
    </Wrapper>
  );
};
export default Tooltip;
