import { IStep, ESectionVariant, EHumanType } from "../../../types";
import { factoryHuman } from "../common";

const step4: IStep = {
  // ÉTAPE 4
  title: "Gouvernance et dirigeants",
  description: "Renseignements sur le ou les dirigeant(s)",
  fields: [
    {
      ///// SECTION
      title: "Dirigeant(s)",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        {
          repeatable: "director_cncef",
          pdfLabel: "Dirigeant",
          addButtonLabel: "Ajouter un(e) dirigeant(e)",
          fields: factoryHuman(EHumanType.DefaultDirector),
        },
      ],
    },
  ],
};
export default step4;
