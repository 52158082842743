import * as yup from "yup";

const required = "Ce champ est obligatoire.";
yup.setLocale({
  mixed: {
    required: required,
    defined: required,
  },
  array: {
    defined: required,
    min: required,
  },
  string: {
    email: "Cet email est invalide.",
    max: "Ce champ ne doit pas dépasser 9 caractères.",
    min: "Ce champ doit avoir 9 caractères.",
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    min: "Le nombre doit être supérieur ou égal à ${min}.",
  },
});

export default yup;
