import React, { useCallback, useEffect, useState } from "react";
import { EIconNames, ELotties, ESectionVariant, ETextVariant } from "../types";

import {
  Box,
  Section,
  Button,
  Text,
  Icon,
  LottieContainer,
} from "../components";
import FormHelper from "../helpers/FormHelper";
import api from "../config/api";

interface Props {
  formHelper: FormHelper;
  entryId: number;
}
const PaymentStep: React.FC<Props> = ({ formHelper, entryId }) => {
  const [paymentUrl, setPaymentUrl] = useState<string>();
  const [cotisationAmount, setCotisationAmount] = useState<string>();
  const [tryCount, setTryCount] = useState<number>(0);

  const sendEntry = useCallback(async () => {
    await api.sendEntry(entryId);
  }, [entryId]);

  const fetchPaymentUrl = useCallback(async () => {
    const res = await api.getEntryPaymentUrl(entryId);
    if (res?.data?.success) {
      setPaymentUrl(res?.data?.data?.url);
      setCotisationAmount(res?.data?.data?.amount);
    } else {
      await new Promise((r) => setTimeout(r, 3000)); // sleep 3s
      setTryCount((t) => t + 1);
    }
  }, [entryId]);

  useEffect(() => {
    if (tryCount <= 15) {
      fetchPaymentUrl();
    }
  }, [fetchPaymentUrl, tryCount]);

  useEffect(() => {
    sendEntry();
  }, [sendEntry]);

  return (
    <>
      <Section
        variant={ESectionVariant.Tertiary}
        titleIcon={EIconNames.CircleCheck}
        titleVariant={ETextVariant.Title5}
        title="Patience, vous avez bientôt terminé"
      >
        {tryCount > 15 && !paymentUrl ? (
          <>
            <LottieContainer url={ELotties.Error} />
            <Text variant={ETextVariant.Body2} center>
              Une erreur est survenue et nous n'avons pas pu calculer votre
              cotisation.
            </Text>
          </>
        ) : !paymentUrl ? (
          <>
            <LottieContainer loop url={ELotties.Metronome} />
            <Text variant={ETextVariant.Body2} center>
              Nous calculons le montant de votre cotisation.
            </Text>
          </>
        ) : (
          <>
            <LottieContainer url={ELotties.Success} />
            <Text variant={ETextVariant.Body2} center>
              Le montant de votre cotisation a été calculé et s'élève à{" "}
              {cotisationAmount}€.
            </Text>
          </>
        )}
        <Icon
          name={formHelper.icon}
          width={formHelper.iconDimensions.width}
          height={formHelper.iconDimensions.height}
        />
      </Section>
      {paymentUrl ? (
        <Box jc="center">
          <a href={paymentUrl}>
            <Button>Accéder au paiement</Button>
          </a>
        </Box>
      ) : null}
    </>
  );
};

export default PaymentStep;
