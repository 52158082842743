import { colors } from "../../../theme";
import { EFormSubType, EFormType } from "../../../types";
import factorySteps from "../common/renewal/factorySteps";
import { LogoAssurance as logoSrc } from "../../../assets/svg";

const assuranceRenewalSchema = {
  label: "Assurance",
  title:
    "Renouvellement de votre adhésion à l'association professionnelle CNCEF Assurance",
  tableOfContentTitle:
    "Parcours d’un renouvellement à l'association CNCEF Assurance",
  color: colors.greenLight,
  logo: logoSrc,
  steps: factorySteps(EFormType.Assurance),
  type: EFormType.Assurance,
  subtype: EFormSubType.Renewal,
  footerCertif: "Association professionnelle agréée par l'ACPR",
};
export default assuranceRenewalSchema;
