import React from "react";
import { ConfirmationModalContext } from "../context/ConfirmationModalContext";

function useConfirmationModal() {
  const context = React.useContext(ConfirmationModalContext);
  if (context === undefined) {
    throw new Error(
      "useConfirmationModal must be used within a ConfirmationModalProvider"
    );
  }
  return context;
}

export default useConfirmationModal;
