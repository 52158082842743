import React from "react";
import styled from "styled-components";
import { EContainerVariant } from "../../types";

const StyledContainer = styled.div<{ variant: EContainerVariant }>`
  max-width: 90%;
  display: block;
  margin: 0 auto;

  ${(props) => props.variant === EContainerVariant.Small && `width: 745px;`}
  ${(props) => props.variant === EContainerVariant.Medium && `width: 1087px;`}
  ${(props) => props.variant === EContainerVariant.Large && `width: 1280px;`}
  ${(props) =>
    props.variant === EContainerVariant.FullWidth &&
    `
    width: 100%;
    max-width: 100%;
  `}
`;

interface Props {
  variant?: EContainerVariant;
  children: React.ReactNode;
}
const Container: React.FC<Props> = ({
  variant = EContainerVariant.Medium,
  children,
  ...props
}) => {
  return (
    <StyledContainer variant={variant} {...props}>
      {children}
    </StyledContainer>
  );
};

export default Container;
