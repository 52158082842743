import styled from "styled-components";
import inputStyles from "../../theme/inputStyles";

import { SelectArrowIcon } from "../../assets/svg";

export const StyledSelect = styled.select`
  ${inputStyles};

  padding: 1rem 4rem 1rem 0.9rem !important;
  background-image: url(${SelectArrowIcon});
  background-position: right 1.5rem center;
  background-size: 1.6rem;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  padding-right: 8rem;
  text-overflow: ellipsis;
`;
