import React from "react";
import { colors } from "../../theme";

import { Wrapper, Item } from "./styled-components";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const Loader: React.FC<Props> = ({
  width = 50,
  height = 50,
  color = colors.primary,
}) => {
  return (
    <Wrapper width={width} height={height}>
      <Item color={color} />
      <Item color={color} />
      <Item color={color} />
      <Item color={color} />
      <Item color={color} />
      <Item color={color} />
    </Wrapper>
  );
};

export default Loader;
