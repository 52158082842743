import { colors } from "../../../theme";
import { EFormSubType, EFormType } from "../../../types";
import steps from "./steps";
import { LogoFrance as logoSrc } from "../../../assets/svg";

const franceSchema = {
  label: "France M&A",
  title: "Demande d’adhésion à la CNCEF France M&A",
  tableOfContentTitle:
    "Parcours d’une demande d’adhésion à la CNCEF France M&A",
  color: colors.bluePastel,
  logo: logoSrc,
  steps,
  type: EFormType.France,
  subtype: EFormSubType.Classic,
};
export default franceSchema;
