import React, { useState } from "react";
import Yup from "../../i18n/yup";
import { checkboxGroup, inscriptionPhone } from "../../helpers/validation";
import {
  ButtonVariant,
  ETextVariant,
  ESectionSize,
  ESectionVariant,
  IStartModal,
  EInputType,
  EFieldContainerVariant,
  EFormType,
} from "../../types";
import { Form, Formik } from "formik";
import { StartModal } from "../../containers";
import SMSCodeModal from "./SMSCodeModal";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import {
  Box,
  Container,
  Button,
  Section,
  Text,
  PhoneInput,
  OptionGroup,
  FieldContainer,
} from "../../components";
import api from "../../config/api";
import FormHelper from "../../helpers/FormHelper";

const INITIAL_VALUES = {
  phone: "",
  accept: [],
  has_read_document: "",
};

const INITIAL_VALUES__FORM_CONTINUE = {
  phone: "",
  accept: [],
  has_read_document: "",
};

const LOADERS = {
  startModal: false,
  submit1: false,
  submit2: false,
};

interface Props {
  formHelper: FormHelper;
}
const FirstStepFooter: React.FC<Props> = ({ formHelper }) => {
  const [loaders, setLoaders] = useState<any>(LOADERS);
  const [userPhone, setUserPhone] = useState<string>("");
  const [startModalProps, setStartModalProps] = useState<IStartModal>({});
  const [isSMSModalOpen, setIsSMSModalOpen] = useState<boolean>(false);
  const { setConfirmationModalProps } = useConfirmationModal();

  // build validation schema
  const VALIDATION_SCHEMA = Yup.object().shape({
    phone: inscriptionPhone,
    ...(formHelper.howToDocument !== ""
      ? {
          accept: checkboxGroup,
          has_read_document: Yup.string().required(
            "Veuillez ouvrir et prendre connaissance du document avant de pouvoir commencer le programme d'activité."
          ),
        }
      : {}),
  });

  const VALIDATION_SCHEMA__FORM_CONTINUE = Yup.object().shape({
    phone: inscriptionPhone,
  });

  const closeStartModal = () => setStartModalProps({});

  const removeSpace = (value: string) => value.replace(/\s/g, "");

  const handleStartFormSubmit = async (values: any) => {
    const cleanPhone = removeSpace(values.phone);
    startLoader("submit1");
    setUserPhone(cleanPhone);

    //si le forumulaire est "simple" on créé directement une entry
    if (formHelper.isSimple) {
      clearLoaders();
      createEntry(cleanPhone);
    } else {
      checkUserProgress({ phone: cleanPhone })
        .then((res) => {
          const { entry_in_progress, entry_completed } = res;
          if (entry_in_progress || entry_completed) {
            setStartModalProps({
              isOpen: true,
              entry_in_progress,
              entry_completed,
            });
          } else {
            createEntry(cleanPhone);
          }
        })
        .catch((error) => {
          handleError(error);
        })
        .finally(() => clearLoaders());
    }
  };

  const handleContinueFormSubmit = async (values: any) => {
    const cleanPhone = removeSpace(values.phone);
    startLoader("submit2");
    setUserPhone(cleanPhone);
    checkUserProgress({ phone: cleanPhone })
      .then((res) => {
        const { entry_in_progress } = res;
        if (entry_in_progress) {
          continueEntry(cleanPhone);
        } else {
          handleError(
            "Aucun formulaire en cours de saisie n'est associé à ce numéro de téléphone. Merci de vérifier celui-ci.",
            "Vérifier le numéro de téléphone"
          );
        }
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => clearLoaders());
  };

  const handleFinalFormSubmit = async (values: any) => {
    const cleanPhone = removeSpace(values.phone);
    const errorMessage =
      "Aucun formulaire en cours de saisie n'est associé à ce numéro de téléphone. Merci de vérifier celui-ci.";
    const buttonLabel = "Vérifier le numéro de téléphone";
    startLoader("submit2");
    setUserPhone(cleanPhone);
    startLoader("startModal");

    api
      .finalizeEntry({
        phone: cleanPhone,
        form: {
          type: formHelper.type,
          subtype: formHelper.subtype,
        },
      })
      .then((res) => {
        if (res && res.data && res.data.success) {
          setIsSMSModalOpen(true);
        } else {
          handleError(errorMessage, buttonLabel);
        }
      })
      .catch((error) => {
        handleError(errorMessage, buttonLabel);
      })
      .finally(() => {
        closeStartModal();
        clearLoaders();
      });
  };

  const checkUserProgress = async (values: any) => {
    const res = await api.getUserProgress({
      ...values,
      form_type: formHelper.type,
      form_subtype: formHelper.subtype,
    });
    const { entry_in_progress = false, entry_completed = false } = res?.data;
    return { entry_in_progress, entry_completed };
  };

  const clearLoaders = () => setLoaders(LOADERS);
  const startLoader = (name: string) =>
    setLoaders({
      ...loaders,
      [name]: true,
    });

  const createEntry = async (phone: string) => {
    startLoader("submit1");
    startLoader("startModal");
    const res = await api.createEntry({
      phone,
      form: {
        type: formHelper.type,
        subtype: formHelper.subtype,
      },
    });
    const url = res?.data?.url;
    if (url) {
      window.location.replace(url);
    } else {
      handleError("");
    }
  };

  const duplicateEntry = (phone: string) =>
    continueOrDuplicateEntry(phone, true);

  const continueEntry = (phone: string) =>
    continueOrDuplicateEntry(phone, false);

  const continueOrDuplicateEntry = async (
    phone: string,
    duplication: boolean
  ) => {
    const endpoint = duplication ? api.duplicateEntry : api.continueEntry;
    startLoader("startModal");

    endpoint({
      phone,
      form: {
        type: formHelper.type,
        subtype: formHelper.subtype,
      },
    })
      .then((res) => {
        if (res && res.data && res.data.success) {
          setIsSMSModalOpen(true);
        } else {
          handleError(res.data.error);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        closeStartModal();
        clearLoaders();
      });
  };

  const handleError = (errorMessage: any, buttonLabel: string = "Compris") => {
    setConfirmationModalProps({
      isOpen: true,
      title: "Une erreur est survenue",
      description:
        typeof errorMessage === "string"
          ? errorMessage
          : "Merci de réessayer plus tard.",
      button: buttonLabel,
      error: true,
    });
  };

  return (
    <>
      <Container>
        <Box>
          {!formHelper.isFinal && (
            <Section title="Commencer" size={ESectionSize.Half}>
              <Text variant={ETextVariant.Body2}>
                {formHelper.isSimple ? (
                  <>
                    Veuillez saisir ci-dessous votre numéro de portable avant de
                    commencer votre pré-inscription :
                  </>
                ) : formHelper.type === EFormType.Patrimoine ? (
                  <>
                    Avant de commencer, veuillez saisir votre numéro de
                    téléphone portable. Celui-ci vous sera demandé pour vous
                    identifier et revenir sur le programme d'activité pour
                    terminer la saisie.
                  </>
                ) : (
                  <>
                    Avant de commencer, veuillez saisir votre numéro de
                    téléphone portable. Celui-ci vous sera demandé pour vous
                    identifier et revenir sur ce formulaire pour terminer la
                    saisie.
                  </>
                )}
              </Text>
              <Text variant={ETextVariant.Body1}>
                ATTENTION : sélectionnez et vérifiez que le format rempli est
                correct avant de passer à l'étape suivante.
              </Text>
              <Formik
                initialValues={INITIAL_VALUES}
                onSubmit={handleStartFormSubmit}
                validationSchema={VALIDATION_SCHEMA}
              >
                {(props) => (
                  <Form>
                    <PhoneInput
                      label="Téléphone portable"
                      required
                      name="phone"
                      value={props.values.phone}
                      setFieldValue={props.setFieldValue}
                    />
                    {formHelper.howToDocument !== "" ? (
                      <>
                        <FieldContainer
                          label={""}
                          required={true}
                          variant={EFieldContainerVariant.FullWidth}
                          name={"accept"}
                        >
                          <OptionGroup
                            name="accept"
                            type={EInputType.Checkbox}
                            options={[
                              {
                                label: (
                                  <>
                                    J'affirme avoir pris connaissance de l'
                                    <a
                                      href={formHelper.howToDocument}
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={() =>
                                        props.setFieldValue(
                                          "has_read_document",
                                          "true"
                                        )
                                      }
                                    >
                                      aide à la complétude du programme
                                      d’activité
                                    </a>
                                  </>
                                ),
                                value: "accept",
                              },
                            ]}
                          />
                        </FieldContainer>

                        <FieldContainer
                          label={""}
                          required={true}
                          variant={EFieldContainerVariant.FullWidth}
                          name={"has_read_document"}
                        >
                          <input
                            type="hidden"
                            name="has_read_document"
                            value=""
                          />
                        </FieldContainer>
                      </>
                    ) : null}
                    <Button type="submit" mt isLoading={!!loaders.submit1}>
                      Commencer
                    </Button>
                  </Form>
                )}
              </Formik>
            </Section>
          )}
          {formHelper.isClassic && (
            <Section
              title="Continuer"
              variant={ESectionVariant.Secondary}
              size={ESectionSize.Half}
            >
              <Text variant={ETextVariant.Body2}>
                Pour reprendre la saisie de votre{" "}
                {formHelper.type === EFormType.Patrimoine
                  ? "programme d'activité"
                  : "demande d'adhésion"}
                , veuillez vous identifier :
              </Text>
              <Formik
                initialValues={INITIAL_VALUES__FORM_CONTINUE}
                onSubmit={handleContinueFormSubmit}
                validationSchema={VALIDATION_SCHEMA__FORM_CONTINUE}
              >
                {(props) => (
                  <Form>
                    <PhoneInput
                      label="Téléphone portable"
                      required
                      name="phone"
                      value={props.values.phone}
                      setFieldValue={props.setFieldValue}
                    />
                    <Button
                      type="submit"
                      variant={ButtonVariant.Secondary}
                      mt
                      isLoading={!!loaders.submit2}
                    >
                      Reprendre la saisie
                    </Button>
                  </Form>
                )}
              </Formik>
            </Section>
          )}
          {formHelper.isFinal && (
            <Section title="Continuer" size={ESectionSize.Half}>
              <Text variant={ETextVariant.Body2}>
                Pour finaliser votre demande d’adhésion, veuillez vous
                identifier :
              </Text>
              <Formik
                initialValues={INITIAL_VALUES__FORM_CONTINUE}
                onSubmit={handleFinalFormSubmit}
                validationSchema={VALIDATION_SCHEMA__FORM_CONTINUE}
              >
                {(props) => (
                  <Form>
                    <PhoneInput
                      label="Téléphone portable"
                      required
                      name="phone"
                      value={props.values.phone}
                      setFieldValue={props.setFieldValue}
                    />
                    <Button
                      type="submit"
                      variant={ButtonVariant.Secondary}
                      mt
                      isLoading={!!loaders.submit2}
                    >
                      Reprendre la saisie
                    </Button>
                  </Form>
                )}
              </Formik>
            </Section>
          )}
        </Box>
      </Container>
      <SMSCodeModal
        isOpen={isSMSModalOpen}
        userPhone={userPhone}
        handleClose={() => setIsSMSModalOpen(false)}
      />
      <StartModal
        isOpen={startModalProps.isOpen}
        hasEntryInProgress={startModalProps.entry_in_progress}
        hasEntryCompleted={startModalProps.entry_completed}
        continueEntry={() => continueEntry(userPhone)}
        createEntry={() => createEntry(userPhone)}
        duplicateEntry={() => duplicateEntry(userPhone)}
        handleClose={closeStartModal}
        isLoading={!!loaders.startModal}
      />
    </>
  );
};

export default FirstStepFooter;
