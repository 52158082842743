import React, { useCallback, useState } from "react";
import { ConfirmationModal } from "../containers";
import { IConfirmationModal } from "../types";
const ConfirmationModalContext = React.createContext<
  | {
      closeConfirmationModal: () => void;
      confirmationModalProps: IConfirmationModal;
      setConfirmationModalProps: React.Dispatch<
        React.SetStateAction<IConfirmationModal>
      >;
    }
  | undefined
>(undefined);

interface ProviderProps {
  children: React.ReactNode;
}
function ConfirmationModalProvider({ children }: ProviderProps) {
  const [
    confirmationModalProps,
    setConfirmationModalProps,
  ] = useState<IConfirmationModal>({});

  const closeConfirmationModal = useCallback(() => {
    setConfirmationModalProps({});
  }, [setConfirmationModalProps]);

  return (
    <ConfirmationModalContext.Provider
      value={{
        confirmationModalProps,
        closeConfirmationModal,
        setConfirmationModalProps,
      }}
    >
      {children}
      <ConfirmationModal />
    </ConfirmationModalContext.Provider>
  );
}
export { ConfirmationModalProvider, ConfirmationModalContext };
export default ConfirmationModalProvider;
