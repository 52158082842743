import {
  IStep,
  EInputType,
  ETextVariant,
  EFieldContainerVariant,
  ESectionVariant,
  EContainerVariant,
} from "../../../types";
import {
  stringR,
  files,
  filesR,
  checkboxGroup,
} from "../../../helpers/validation";
import { constants } from "../common";
import documents from "../../documents";

const step8: IStep = {
  // ÉTAPE 8
  title: "Pièces justificatives",
  description:
    "Transmission des pièces justificatives et finalisation de la demande",
  fields: [
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          variant: ETextVariant.Body1,
          content: "J'atteste sur l'honneur",
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__1",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "Ne faire l’objet d’aucune procédure contentieuse ou disciplinaire en cours *",
              value:
                "Ne faire l’objet d’aucune procédure contentieuse ou disciplinaire en cours *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__2",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "Je m’engage à informer la CNCEF Patrimoine de tout élément de nature à affecter ma situation *",
              value:
                "Je m’engage à informer la CNCEF Patrimoine de tout élément de nature à affecter ma situation *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__3",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label: (
                <>
                  Je reconnais avoir pris connaissance des{" "}
                  <a
                    href={documents.statutsPatrimoine}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    statuts
                  </a>
                  , du{" "}
                  <a
                    href={documents.bonneConduitePatrimoine}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    code de bonne conduite
                  </a>{" "}
                  et du{" "}
                  <a
                    href={documents.reglementInterieurPatrimoine}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    règlement intérieur
                  </a>{" "}
                  que j’approuve et m’engage à respecter *
                </>
              ),
              value:
                "Je reconnais avoir pris connaissance des statuts, du règlement intérieur, du code de bonne conduite et du règlement intérieur que j’approuve et m’engage à respecter *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          required: true,
          label:
            "J’autorise la CNCEF Patrimoine à faire figurer mes coordonnées dans l’annuaire de la CNCEF",
          name: "authorization__coordinates",
          type: EInputType.Radio,
          containerVariant: EFieldContainerVariant.BigLabel,
          options: [
            {
              label: "Oui",
              value: "Oui",
            },
            {
              label: "Non",
              value: "Non",
            },
          ],
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Pour la structure",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        {
          initialValue: [],
          label:
            "Extrait kbis datant de moins de 3 mois ou Avis de situation SIREN si non-inscrit au RCS",
          required: true,
          name: "file__structure__kbis_or_siren",
          type: EInputType.File,
          validation: filesR,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Attestation assurance RCP pour l'activité de CIF valable jusqu'au 29/02 / N + 1",
          name: "file__structure__rcp",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: "Attestation d'immatriculation ORIAS si déjà immatriculé(e)",
          name: "file__orias",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "En cas de changement d'association professionnelle CIF, le programme d'activité antérieurement vérifié par l'ancienne association professionnelle CIF",
          name: "file__structure__activity_program",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Facultatif, organigramme/tableau synthétique des moyens humains dont dispose(ra) le conseiller en investissements financiers",
          name: "file__structure__organigram",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Facultatif, schéma du réseau de distribution et analyse de la conformité réglementaire de ce schéma",
          name: "file__structure__schema_distribution",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: "Facultatif, organigramme du groupe",
          name: "file__structure__organigram_group",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
      ],
    },
    {
      ///// SECTION
      title: "Pour le ou les dirigeant(s) CIF",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        {
          initialValue: [],
          label: "CV",
          required: true,
          name: "file__director__cv",
          type: EInputType.File,
          validation: filesR,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Justificatif de capacité professionnelle : diplôme (s) / attestation de fonction / livret de formation ",
          required: true,
          name: "file__director__degree",
          type: EInputType.File,
          validation: filesR,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Copie d'une pièce d'identité recto / verso en cours de validité",
          required: true,
          name: "file__director__id",
          type: EInputType.File,
          validation: filesR,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Attestation d'obtention de l'examen AMF ou Attestation(s) des 3 modules (vérification AMF)",
          required: true,
          name: "file__director__AMF",
          type: EInputType.File,
          validation: filesR,
          max: constants.maxFile,
        },
      ],
    },
    {
      ///// SECTION
      title: "Pour le ou les salarié(s) CIF :",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        {
          initialValue: [],
          label: "CV",
          name: "file__employee__cv",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: (
            <>
              Déclaration sur l'honneur (
              <a
                href={documents.declarationHonneurCIF}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                télécharger le modèle d'attestation
              </a>
              )
            </>
          ),
          pdfLabel: "Déclaration sur l'honneur",
          name: "file__employee__declaration",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Justificatif de capacité professionnelle : Diplôme (s) / attestation(s) de fonction / livret de formation",
          name: "file__employee__degree",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Copie d'une pièce d'identité recto / verso en cours de validité",
          name: "file__employee__id",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Attestation d'obtention de l'examen AMF ou Attestation des 3 modules (vérification AMF)",
          name: "file__employee__amf",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
      ],
    },
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__4",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "J’atteste sur l’honneur que toutes les données indiquées sur le dossier d’adhésion, rempli par mes soins, sont véridiques *",
              value:
                "J’atteste sur l’honneur que toutes les données indiquées sur le dossier d’adhésion, rempli par mes soins, sont véridiques *",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label: "",
          name: "sworn_statement__5",
          type: EInputType.Checkbox,
          containerVariant: EFieldContainerVariant.FullWidth,
          options: [
            {
              label:
                "En cochant cette case, je confirme ma volonté d’accéder au statut de CIF et j’accepte les conditions d’adhésion demandées par la CNCEF Patrimoine, association disposant de l’agrément de l’Autorité des Marchés Financiers (AMF) *",
              value:
                "En cochant cette case, je confirme ma volonté d’accéder au statut de CIF et j’accepte les conditions d’adhésion demandées par la CNCEF Patrimoine, association disposant de l’agrément de l’Autorité des Marchés Financiers (AMF) *",
            },
          ],
          validation: checkboxGroup,
        },
      ],
    },
  ],
};

export default step8;
