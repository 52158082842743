import { number, numberR } from "../../../helpers/validation";
import Yup from "../../../i18n/yup";
import { EInputSize, EInputType } from "../../../types";

const franceClientTypes = [
  {
    name: "client__national",
    value: "Nationale",
  },
  {
    name: "client__foreign",
    value: "Internationale",
  },
].map(({ name, value }) => [
  {
    initialValue: "",
    label: "",
    name: name,
    type: EInputType.Checkbox,
    options: [
      {
        value: value,
        label: value,
      },
    ],
  },
  {
    initialValue: 0,
    label: "Répartition (en %)",
    required: true,
    name: `${name}__repartition`,
    type: EInputType.Number,
    size: EInputSize.Small,
    validation: Yup.mixed().when(name, (values: any) => {
      return values && values.includes(value) ? numberR : number.nullable(true);
    }),

    visible: [
      {
        name: name,
        value: value,
      },
    ],
  },
]);
const factoryFranceClientType = franceClientTypes.reduce(
  (acc, currentValue) => {
    acc.push(...currentValue);
    return acc;
  },
  []
);

export default factoryFranceClientType;
