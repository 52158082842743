import React from "react";
import { useFormikContext } from "formik";
import { TStepField } from "../types";
import {
  StepInput,
  StepSection,
  StepRepeater,
  StepFieldset,
  StepList,
} from "../containers";
import { Table, Text } from "../components";
import { checkConditions } from "../helpers";

interface Props {
  field: TStepField;
  prefix?: string;
}

const StepField: React.FC<Props> = ({ field, prefix = "" }) => {
  const { values } = useFormikContext();
  const visible =
    "visible" in field && typeof field.visible !== "undefined"
      ? field.visible
      : true;
  const required =
    "required" in field && typeof field.required !== "undefined"
      ? field.required
      : false;
  let requiredBoolean;

  // Si visible est différent de true, on vérifie que la condition n'est pas fausse
  if (visible !== true) {
    const hidden = checkConditions(visible, values);

    if (hidden) {
      return null;
    }
  }
  // Si required n'est pas un boolean, on vérifie que la condition n'est pas fausse
  if (typeof required == "boolean") {
    requiredBoolean = required;
  } else {
    requiredBoolean = !checkConditions(required, values);
  }
  const updatedField = { ...field, required: requiredBoolean };

  if ("col1" in updatedField) {
    return <Table {...updatedField} />;
  } else if ("type" in updatedField) {
    return <StepInput prefix={prefix} {...updatedField} />;
  } else if ("repeatable" in updatedField) {
    return <StepRepeater prefix={prefix} {...updatedField} />;
  } else if ("content" in updatedField) {
    return <Text variant={updatedField.variant}>{updatedField.content}</Text>;
  } else if ("fieldsetTitle" in updatedField) {
    return <StepFieldset prefix={prefix} {...updatedField} />;
  } else if ("listItems" in updatedField) {
    return <StepList {...updatedField} />;
  } else if ("title" in updatedField) {
    return <StepSection {...updatedField} />;
  } else {
    return <p>Je suis autre chose</p>;
  }
};

export default StepField;
