import { IStep, EInputType, EInputSize } from "../../../types";
import Yup from "../../../i18n/yup";
import {
  date,
  phone,
  stringR,
  emailR,
  naf,
  numberR,
  legalForm,
  number,
  phoneF,
} from "../../../helpers/validation";
import { dateMask } from "../../../helpers/masks";

const step2: IStep = {
  title: "Identité de la structure",
  description: "Renseignements sur votre structure",
  fields: [
    {
      ///// SECTION
      title: "Entité",
      fields: [
        {
          initialValue: [],
          label: "Qualité de",
          name: "entity__expert",
          type: EInputType.Checkbox,
          options: [
            {
              label: "Expert Financier",
              value: "Expert Financier",
            },
          ],
        },
        {
          initialValue: "",
          label: "Statut",
          required: true,
          name: "entity__type",
          type: EInputType.Radio,
          options: [
            {
              label: "Personne morale",
              value: "Personne morale",
            },
            {
              label: "Personne Physique indépendante (PPI)",
              value: "Personne Physique indépendante (PPI)",
            },
          ],
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Dénomination de la personne morale",
          required: true,
          name: "entity__denomination",
          placeholder: "Nom de votre entreprise, association…",
          type: EInputType.Text,
          size: EInputSize.Large,
          validation: Yup.mixed().when("entity__type", {
            is: "Personne morale",
            then: stringR,
          }),
          visible: [
            {
              name: "entity__type",
              value: "Personne morale",
            },
          ],
        },
        {
          initialValue: "",
          label: "Nom commercial",
          name: "entity__commercial_name",
          placeholder: "Nom de votre entreprise, association…",
          type: EInputType.Text,
          size: EInputSize.Large,
          validation: Yup.string(),
        },
        {
          initialValue: "",
          label: "Nom et prénom pour la personne physique",
          required: true,
          name: "entity__people_name",
          placeholder: "Dupond Jeanne",
          type: EInputType.Text,
          validation: Yup.mixed().when("entity__type", {
            is: "Personne Physique indépendante (PPI)",
            then: stringR,
          }),
          visible: [
            {
              name: "entity__type",
              value: "Personne Physique indépendante (PPI)",
            },
          ],
        },
        {
          initialValue: "",
          label: "Forme juridique",
          required: true,
          name: "entity__legal_name",
          tooltip:
            "Pour une SA, préciser le mode de direction (directoire et conseil de surveillance ou conseil d’administration)",
          placeholder: "Choisissez une forme juridique",
          type: EInputType.Select,
          options: [
            {
              label: "SA",
              value: "SA",
            },
            {
              label: "SAS",
              value: "SAS",
            },
            {
              label: "SARL",
              value: "SARL",
            },
            {
              label: "EURL",
              value: "EURL",
            },
            {
              label: "EI",
              value: "EI",
            },
            {
              label: "SASU",
              value: "SASU",
            },
            {
              label: "GIE",
              value: "GIE",
            },
            {
              label: "Association",
              value: "Association",
            },
          ],
          validation: legalForm,
        },
        {
          initialValue: "",
          label: "Si SA, préciser",
          name: "entity__sa__details",
          type: EInputType.Checkbox,
          size: EInputSize.Large,
          options: [
            {
              label: "Directoire et conseil de surveillance",
              value: "Directoire et conseil de surveillance",
            },
            {
              label: "Conseil d'administration",
              value: "Conseil d'administration",
            },
          ],
          visible: [
            {
              name: "entity__legal_name",
              value: "SA",
            },
          ],
        },
        {
          initialValue: "",
          label: "Code NAF",
          required: true,
          name: "entity__naf",
          placeholder: "0000X",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: naf,
        },
        {
          initialValue: "",
          label: "Date de création",
          required: true,
          name: "entity__created_at_date",
          placeholder: "JJ / MM / AAAA",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: date,
          mask: dateMask,
        },
        {
          initialValue: "",
          label: "Capital",
          name: "entity__capital",
          placeholder: "000000",
          type: EInputType.Number,
          size: EInputSize.Small,
          validation: number,
        },
        {
          initialValue: "",
          label: "Chiffre d'affaire N-1 global (K€)",
          required: true,
          name: "entity__CA",
          type: EInputType.Number,
          tooltip:
            "Cette information est nécessaire pour le calcul du montant de votre cotisation",
          validation: numberR,
        },
      ],
    },

    {
      ///// SECTION
      title: "Coordonnées",
      fields: [
        {
          initialValue: "",
          label: "Adresse du siège social",
          required: true,
          name: "contact_information__head_office__address",
          placeholder: "8 rue de l’Héronnière",
          type: EInputType.Text,
          size: EInputSize.Large,
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Code postal",
          required: true,
          name: "contact_information__head_office__zipcode",
          placeholder: "00000",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Ville",
          required: true,
          name: "contact_information__head_office__city",
          placeholder: "Nantes",
          type: EInputType.Text,
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Adresse du bureau administratif",
          name: "contact_information__administrative_office__address",
          placeholder: "8 rue de l’Héronnière",
          type: EInputType.Text,
          size: EInputSize.Large,
          validation: Yup.string(),
        },
        {
          initialValue: "",
          label: "Code postal du bureau administratif",
          name: "contact_information__administrative_office__zipcode",
          placeholder: "00000",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: Yup.string(),
        },
        {
          initialValue: "",
          label: "Ville du bureau administratif",
          name: "contact_information__administrative_office__city",
          placeholder: "Nantes",
          type: EInputType.Text,
          validation: Yup.string(),
        },
        {
          initialValue: "",
          label: "Téléphone fixe",
          name: "contact_information__phone",
          placeholder: "0102030405",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: phoneF,
        },
        {
          initialValue: "",
          label: "Téléphone mobile",
          required: true,
          name: "contact_information__mobile_phone",
          placeholder: "0102030405",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: phone,
        },
        {
          initialValue: "",
          label: "Courriel principal",
          required: true,
          name: "contact_information__email",
          placeholder: "jeandupont@courriel.fr",
          type: EInputType.Email,
          validation: emailR,
        },
        {
          initialValue: "",
          label: "Site internet",
          name: "contact_information__website",
          placeholder: "jeandupont.fr",
          type: EInputType.Text,
          validation: Yup.string(),
        },
      ],
    },
  ],
};

export default step2;
