import Yup from "../../../i18n/yup";
import { stringToSlug } from "../../../helpers";

const factoryTable = (
  prefix: string,
  fields: { label: string; tooltip?: string }[],
  required: boolean = false
) => {
  return fields.map(({ label, tooltip = "" }) => ({
    label,
    value: label,
    required,
    name: `${prefix}__${stringToSlug(label)}}`,
    initialValue: "",
    tooltip,
    validation: Yup.string().required(),
  }));
};
export default factoryTable;
