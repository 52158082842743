import {
  EInputType,
  EInputSize,
  EFormType,
  ERepeaterButtonVariant,
  ERepeaterContainerVariant,
  ETextVariant,
} from "../../../../types";
import Yup from "../../../../i18n/yup";
import { isAssurance, isCredit } from "../../../../helpers";
import {
  date,
  stringR,
  stringF,
  numberR,
  numberRNotNull,
  siren,
  checkboxGroup,
} from "../../../../helpers/validation";
import { boolOptions } from "../options";
import { dateMask } from "../../../../helpers/masks";

const factoryRenewalStep1 = (type: EFormType = EFormType.Credit) => ({
  title: "Identité de la structure",
  description: "Renseignements sur la structure",
  fields: [
    {
      ///// SECTION
      title: "Entité",
      fields: [
        {
          initialValue: "",
          label: "Nom de la structure",
          required: true,
          name: "entity__denomination",
          placeholder: "Nom de votre entreprise, association…",
          type: EInputType.Text,
          size: EInputSize.Large,
          validation: stringR,
          disabled: true,
        },
        {
          initialValue: "",
          label: "SIREN",
          required: true,
          name: "entity__siren",
          placeholder: "000000000",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: siren,
          disabled: true,
        },
        {
          initialValue: "",
          label: "Email principal de la structure",
          required: true,
          name: "entity__email",
          type: EInputType.Text,
          size: EInputSize.Large,
          validation: stringR,
          disabled: true,
        },

        ...(isAssurance(type)
          ? [
              {
                initialValue: [],
                label: "Catégorie IAS",
                required: true,
                name: "entity__category_ias",
                type: EInputType.Checkbox,
                options: [
                  {
                    label: "Courtier d’assurances",
                    value: "COA",
                  },
                  {
                    label: "Mandataire d’intermédiaire d’assurances",
                    value: "MIA",
                  },
                ],
                validation: checkboxGroup,
              },

              {
                repeatable: "entity__mandant_ias",
                pdfLabel: "Fonction",
                addButtonLabel: "Ajouter un mandant",
                initialEmpty: false,
                variant: ERepeaterButtonVariant.Small,
                containerVariant: ERepeaterContainerVariant.WithBackground,
                visible: [
                  {
                    name: "entity__category_ias",
                    value: "MIA",
                  },
                ],
                fields: [
                  {
                    initialValue: "",
                    label: "Mandant",
                    required: true,
                    name: "name",
                    placeholder: "Nom du mandant",
                    type: EInputType.Text,
                    size: EInputSize.Large,
                    validation: stringF,
                  },
                  {
                    initialValue: "",
                    label: "SIREN du Mandant",
                    required: true,
                    name: "siren",
                    placeholder: "",
                    type: EInputType.Text,
                    size: EInputSize.Large,
                    validation: stringF,
                  },
                ],
              },
            ]
          : []),
        ...(isCredit(type)
          ? [
              {
                initialValue: [],
                label: "Catégorie IOBSP",
                required: true,
                name: "entity__category_iobsp",
                type: EInputType.Checkbox,
                options: [
                  {
                    label:
                      "Courtier en opérations de banque et en services de paiement",
                    value: "COBSP",
                  },
                  {
                    label:
                      "Mandataire d’intermédiaire en opérations de banque et en services de paiement",
                    value: "MIOBSP",
                  },
                ],
                validation: checkboxGroup,
              },
              {
                repeatable: "entity__mandant_iobsp",
                pdfLabel: "Fonction",
                addButtonLabel: "Ajouter un mandant",
                initialEmpty: false,
                variant: ERepeaterButtonVariant.Small,
                containerVariant: ERepeaterContainerVariant.WithBackground,
                visible: [
                  {
                    name: "entity__category_iobsp",
                    value: "MIOBSP",
                  },
                ],
                fields: [
                  {
                    initialValue: "",
                    label: "Mandant",
                    required: true,
                    name: "name",
                    placeholder: "Nom du mandant",
                    type: EInputType.Text,
                    size: EInputSize.Large,
                    validation: stringF,
                  },
                  {
                    initialValue: "",
                    label: "SIREN du Mandant",
                    required: true,
                    name: "siren",
                    placeholder: "",
                    type: EInputType.Text,
                    size: EInputSize.Large,
                    validation: stringF,
                  },
                ],
              },
            ]
          : []),
        {
          initialValue: 0,
          label: `CA 2022 global`,
          required: true,
          name: "entity__ca_previous_year",
          type: EInputType.Number,
          size: EInputSize.Small,
          validation: numberR,
        },
        ...(isAssurance(type)
          ? [
              {
                initialValue: 0,
                label: `CA 2022 lié à l'activité IAS (K€)`,
                required: true,
                name: "entity__ca_previous_year_ias",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: numberR,
              },
              {
                initialValue: 0,
                label: `CA 2023 (estimé) lié à l'activité IAS (K€)`,
                required: true,
                name: "entity__ca_current_year_ias",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: numberR,
              },
            ]
          : []),
        ...(isCredit(type)
          ? [
              {
                initialValue: 0,
                label: `CA 2022 lié à l'activité IOBSP (K€)`,
                required: true,
                name: "entity__ca_previous_year_iobsp",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: numberR,
              },
              {
                initialValue: 0,
                label: `CA 2023 (estimé) lié à l'activité IOBSP (K€)`,
                required: true,
                name: "entity__ca_current_year_iobsp",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: numberR,
              },
            ]
          : []),
        {
          initialValue: 0,
          label: `Nombre de salariés total`,
          required: true,
          name: "employees_count_total",
          type: EInputType.Number,
          size: EInputSize.Small,
          validation: numberR,
        },
        ...(isAssurance(type)
          ? [
              {
                initialValue: 0,
                label: `Nombre de salarié(s) exerçant l'activité IAS`,
                required: true,
                name: "employees_count_ias",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: numberR,
              },
            ]
          : []),
        ...(isCredit(type)
          ? [
              {
                initialValue: 0,
                label: `Nombre de salarié(s) exerçant l'activité IOBSP`,
                required: true,
                name: "employees_count_iobsp",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: numberR,
              },
            ]
          : []),
        ...(isAssurance(type)
          ? [
              {
                initialValue: "",
                label: "Encaissement de fonds IAS",
                required: true,
                name: "entity__fond_encaissement_ias",
                type: EInputType.Radio,
                tooltip:
                  "Au titre de votre activité, encaissez-vous des fonds (primes de compagnies d’assurances ou de clients) ?",
                options: boolOptions,
                validation: stringR,
              },
            ]
          : []),
        ...(isCredit(type)
          ? [
              {
                initialValue: "",
                label: "Encaissement de fonds IOB",
                required: true,
                name: "entity__fond_encaissement_iobsp",
                type: EInputType.Radio,
                tooltip:
                  "Au titre de votre activité, vous voyez-vous confier des fonds ?",
                options: boolOptions,
                validation: stringR,
              },
            ]
          : []),
      ],
    },
    {
      ///// SECTION
      title: "Médiation",
      fields: [
        {
          initialValue: "Non",
          label: "Avez-vous un médiateur ?",
          required: true,
          name: "entity__has_mediator",
          type: EInputType.Radio,
          size: EInputSize.Large,
          options: [
            {
              label: "Je n'ai pas de médiateur",
              value: "Non",
            },
            {
              label: "J'ai un médiateur",
              value: "Oui",
            },
          ],
          validation: stringR,
        },
        {
          variant: ETextVariant.Notice,
          content:
            "Nous vous informons que vous êtes tenu de souscrire à un service de médiation pour votre/vos activité(s) cité(es) ci-dessus, il s'agit d'une obligation depuis le 1er janvier 2016. En effet, le professionnel doit permettre à son client d'accéder à un dispositif de médiation de la consommation en vue de la résolution à l'amiable d'un éventuel litige (Article L. 612-1 du code de la consommation). Votre renouvellement ne pourra être validé sans avoir souscrit à un service de médiation à la consommation.",
          visible: [
            {
              name: "entity__has_mediator",
              value: "Non",
            },
          ],
        },
        {
          initialValue: "",
          label:
            "Je n’ai pas de médiateur car ma clientèle est exclusivement professionnelle (aucun consommateur)",
          required: true,
          name: "entity__has_no_mediator_because_no_consumer",
          type: EInputType.Radio,
          options: boolOptions,
          validation: Yup.mixed().when("entity__has_mediator", {
            is: "Non",
            then: stringR,
          }),
          visible: [
            {
              name: "entity__has_mediator",
              value: "Non",
            },
          ],
        },
        {
          initialValue: "",
          label: "Nom du médiateur de la consommation",
          required: true,
          name: "entity__mediator_name",
          type: EInputType.Text,
          validation: Yup.mixed().when("entity__has_mediator", {
            is: "Oui",
            then: stringR,
          }),
          visible: [
            {
              name: "entity__has_mediator",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "Date de convention",
          required: true,
          name: "entity__mediator_date_convention",
          placeholder: "JJ / MM / AAAA",
          type: EInputType.Text,
          size: EInputSize.Small,
          mask: dateMask,
          validation: Yup.mixed().when("entity__has_mediator", {
            is: "Oui",
            then: date,
          }),
          visible: [
            {
              name: "entity__has_mediator",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "Date de fin de convention",
          required: true,
          name: "entity__mediator_date_convention_end",
          placeholder: "JJ / MM / AAAA",
          type: EInputType.Text,
          size: EInputSize.Small,
          mask: dateMask,
          validation: Yup.mixed().when("entity__has_mediator", {
            is: "Oui",
            then: date,
          }),
          visible: [
            {
              name: "entity__has_mediator",
              value: "Oui",
            },
          ],
        },
      ],
    },

    ...(isAssurance(type)
      ? [
          {
            ///// SECTION
            title: "RC Pro IAS",
            fields: [
              {
                variant: ETextVariant.Body1,
                content:
                  "Information relative à votre RC Pro 2024 valable jusqu'au 28/02/N+1",
              },
              {
                initialValue: "",
                label: "Nom de la compagnie",
                required: true,
                name: "entity__rc_pro__company_name_ias",
                type: EInputType.Text,
                validation: stringR,
              },
              {
                initialValue: "IAS",
                label: "Champ d'application",
                required: true,
                disabled: true,
                name: "entity__rc_pro__scope_ias",
                type: EInputType.Radio,
                options: [
                  {
                    label: "IAS",
                    value: "IAS",
                  },
                ],
                validation: stringR,
              },
              {
                initialValue: 0,
                label: `Montant des garanties €/année`,
                required: true,
                name: "entity__rc_pro__amount_year_ias",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: numberR,
              },
              {
                initialValue: 0,
                label: `Montant des garanties €/sinistre`,
                required: true,
                name: "entity__rc_pro__amount_sinister_ias",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: numberR,
              },
              {
                initialValue: 0,
                label: `Franchise IAS en €`,
                required: true,
                name: "entity__rc_pro__franchise_ias",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: numberRNotNull,
              },
            ],
          },
        ]
      : []),

    ...(isCredit(type)
      ? [
          {
            ///// SECTION
            title: "RC Pro IOB",
            fields: [
              {
                variant: ETextVariant.Body1,
                content:
                  "Information relative à votre RC Pro 2024 valable jusqu'au 28/02/N+1",
              },
              {
                initialValue: "",
                label: "Nom de la compagnie",
                required: true,
                name: "entity__rc_pro__company_name_iobsp",
                type: EInputType.Text,
                validation: stringR,
              },
              {
                initialValue: "IOBSP",
                label: "Champ d'application",
                required: true,
                disabled: true,
                name: "entity__rc_pro__scope_iobsp",
                type: EInputType.Radio,
                options: [
                  {
                    label: "IOBSP",
                    value: "IOBSP",
                  },
                ],
                validation: stringR,
              },
              {
                initialValue: 0,
                label: `Montant des garanties €/année`,
                required: true,
                name: "entity__rc_pro__amount_year_iobsp",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: numberR,
              },
              {
                initialValue: 0,
                label: `Montant des garanties €/sinistre`,
                required: true,
                name: "entity__rc_pro__amount_sinister_iobsp",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: numberR,
              },
              {
                initialValue: 0,
                label: `Franchise IOB en €`,
                required: true,
                name: "entity__rc_pro__franchise_iobsp",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: numberRNotNull,
              },
            ],
          },
        ]
      : []),

    ...(isAssurance(type)
      ? [
          {
            ///// SECTION
            title: "Garantie financière IAS",
            fields: [
              {
                initialValue: 0,
                label: `Montant de la Garantie financière IAS`,
                required: true,
                name: "entity__financial_guarantee__amount_ias",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: Yup.mixed().when("entity__fond_encaissement_ias", {
                  is: "Oui",
                  then: numberRNotNull,
                }),
              },
              {
                initialValue: 0,
                label: `Montant des fonds encaissés`,
                required: true,
                name: "entity__financial_guarantee__amount_collected_ias",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: Yup.mixed().when("entity__fond_encaissement_ias", {
                  is: "Oui",
                  then: numberR,
                }),
              },
              {
                initialValue: 0,
                label: `Montant des fonds de roulement`,
                required: true,
                name: "entity__financial_guarantee__amount_fdr_ias",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: Yup.mixed().when("entity__fond_encaissement_ias", {
                  is: "Oui",
                  then: numberR,
                }),
              },
              {
                initialValue: 0,
                label: `Montant total des fonds perçus dans le cadre de mandats écrits d'encaissement`,
                required: true,
                name: "entity__financial_guarantee__amount_mandate_ias",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: Yup.mixed().when("entity__fond_encaissement_ias", {
                  is: "Oui",
                  then: numberR,
                }),
              },
              {
                initialValue: "",
                label: "Nom de la compagnie",
                required: true,
                name: "entity__financial_guarantee__company_ias",
                type: EInputType.Text,
                validation: Yup.mixed().when("entity__fond_encaissement_ias", {
                  is: "Oui",
                  then: stringR,
                }),
              },
            ],
            visible: [
              {
                name: "entity__fond_encaissement_ias",
                value: "Oui",
              },
            ],
          },
        ]
      : []),
    ...(isCredit(type)
      ? [
          {
            ///// SECTION
            title: "Garantie financière IOB",
            fields: [
              {
                initialValue: 0,
                label: `Montant de la Garantie financière IOB`,
                required: true,
                name: "entity__financial_guarantee__amount_iobsp",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: Yup.mixed().when(
                  "entity__fond_encaissement_iobsp",
                  {
                    is: "Oui",
                    then: numberRNotNull,
                  }
                ),
              },
              {
                initialValue: 0,
                label: `Montant des fonds encaissés`,
                required: true,
                name: "entity__financial_guarantee__amount_collected_iobsp",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: Yup.mixed().when(
                  "entity__fond_encaissement_iobsp",
                  {
                    is: "Oui",
                    then: numberR,
                  }
                ),
              },
              {
                initialValue: 0,
                label: `Montant des fonds de roulement`,
                required: true,
                name: "entity__financial_guarantee__amount_fdr_iobsp",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: Yup.mixed().when(
                  "entity__fond_encaissement_iobsp",
                  {
                    is: "Oui",
                    then: numberR,
                  }
                ),
              },
              {
                initialValue: 0,
                label: `Montant total des fonds perçus dans le cadre de mandats écrits d'encaissement`,
                required: true,
                name: "entity__financial_guarantee__amount_mandate_iobsp",
                type: EInputType.Number,
                size: EInputSize.Small,
                validation: Yup.mixed().when(
                  "entity__fond_encaissement_iobsp",
                  {
                    is: "Oui",
                    then: numberR,
                  }
                ),
              },
              {
                initialValue: "",
                label: "Nom de la compagnie",
                required: true,
                name: "entity__financial_guarantee__company_iobsp",
                type: EInputType.Text,
                validation: Yup.mixed().when(
                  "entity__fond_encaissement_iobsp",
                  {
                    is: "Oui",
                    then: stringR,
                  }
                ),
              },
            ],
            visible: [
              {
                name: "entity__fond_encaissement_iobsp",
                value: "Oui",
              },
            ],
          },
        ]
      : []),
  ],
});

export default factoryRenewalStep1;
