import { AxiosResponse } from "axios";
import { EIconNames } from ".";
import {
  EContainerVariant,
  EFieldContainerVariant,
  EInputSize,
  ERepeaterButtonVariant,
  ERepeaterContainerVariant,
  ESectionSize,
  ESectionVariant,
  ETextVariant,
} from "./variant";

export interface IFormSchema {
  label: string;
  title: string | JSX.Element;
  tableOfContentTitle: string;
  parcoursDescription?: string | JSX.Element;
  type: EFormType;
  subtype: EFormSubType;
  icon?: EIconNames;
  iconDimensions?: {
    width: number;
    height: number;
  };
  color: string;
  logo: string;
  footerLogo?: string;
  footerCertif?: string;
  howToDocument?: string;
  steps: IStep[];
}

export interface IAttachmentLabelsSection {
  title: string;
  labels: string[];
}

export interface IFieldset {
  fieldsetTitle: string;
  fieldsetDescription?: string;
  tooltip?: string | React.ReactNode;
  prefix?: string;
  containerVariant?: EContainerVariant;
  extraMargin?: boolean;
  fields: TStepField[];
}

export interface IFieldTable {
  title: string;
  col1: string;
  col2: string;
  fields: IInput[];
  values: [string, string];
}

export interface IFieldRepeatable {
  repeatable: string;
  pdfLabel?: string; // n'est pas affiché en front mais utilisé dans la génération de pdf
  initialEmpty?: boolean; // Est vide au départ
  variant?: ERepeaterButtonVariant;
  containerVariant?: ERepeaterContainerVariant;
  addButtonLabel?: string;
  removeButtonLabel?: string;
  prefix?: string;
  fields: TStepField[];
  validation?: any;
}

export interface ISection {
  title?: string;
  variant?: ESectionVariant;
  size?: ESectionSize;
  fields: TStepField[];
  tooltip?: string | React.ReactNode;
  visible?: IVisibleCondition[] | true;
}

interface IText {
  variant: ETextVariant;
  content: React.ReactNode;
}

export interface IList {
  listItems: (string | React.ReactNode)[];
}

export interface IVisibleCondition {
  name: string;
  comparator?: EComparator;
  value: any;
}

export interface IOption {
  label: string | React.ReactNode;
  value: string;
  tooltip?: string | React.ReactNode;
}

export interface IInput {
  initialValue: any;
  name: string;
  label?: string | React.ReactNode;
  pdfLabel?: string; // n'est pas affiché en front mais utilisé dans la génération de pdf
  tooltip?: string | React.ReactNode;
  disabled?: boolean;
  visible?: IVisibleCondition[] | true;
  options?: IOption[] | (() => Promise<AxiosResponse>); // si cas de la fonction, la réponse doit retourner les données dans le champs data
  placeholder?: string;
  size?: EInputSize;
  containerVariant?: EFieldContainerVariant;
  type?: EInputType;
  validation?: any;
  required?: IVisibleCondition[] | boolean;
  max?: number;
  mask?: any;
}

export enum EInputType {
  Checkbox = "checkbox",
  Email = "email",
  Number = "number",
  File = "file",
  Hidden = "hidden",
  Radio = "radio",
  Select = "select",
  Tel = "tel",
  Text = "text",
  TextArea = "textarea",
}

export type TStepField =
  | ISection
  | IFieldRepeatable
  | IFieldset
  | IText
  | IList
  | IInput
  | IFieldTable;

export interface IStep {
  title: string;
  description?: string;
  fields: TStepField[];
}

export enum ESearchableProperty {
  Values = "initialValue",
  Validation = "validation",
}

export enum EComparator {
  inf = "<",
  infE = "<=",
  sup = ">",
  supE = ">=",
}

export enum EFormType {
  Patrimoine = "patrimoine",
  Assurance = "assurance",
  Credit = "credit",
  Immobilier = "immobilier",
  Cncef = "cncef",
  France = "france",
  AssuranceCredit = "assurance-credit",
}

export enum EFormSubType {
  Classic = "classic",
  Simple = "simple",
  Final = "final",
  Renewal = "renewal",
}

export enum EHumanType {
  Default,
  Minimal,
  DefaultDirector,
  DirectorIASIOS,
  DirectorCIF,
  DirectorImmo,
  EmployeeCIF,
  DirectorFrance,
  EmployeeFrance,
  EmployeeCredit,
  EmployeeAssurance,
}
