import React from "react";

import { ThemeProvider } from "styled-components";
import useThemeContext from "../hooks/useThemeContext";
import GlobalStyles from "./GlobalStyles";

interface Props {
  children: React.ReactNode;
}

const Theme: React.FC<Props> = ({ children }) => {
  const { theme } = useThemeContext();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
