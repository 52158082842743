import { colors } from "../../../theme";
import { EFormSubType, EFormType } from "../../../types";
import steps from "./steps";
import { LogoCredit as logoSrc } from "../../../assets/svg";

const creditFinalSchema = {
  label: "Crédit",
  title: "Finalisation de votre demande d’adhésion à la CNCEF Crédit",
  tableOfContentTitle: "Parcours d’une demande d’adhésion à la CNCEF Crédit",
  color: colors.greenLight,
  logo: logoSrc,
  steps,
  type: EFormType.Credit,
  subtype: EFormSubType.Final,
  footerCertif: "Agréée ACPR",
};
export default creditFinalSchema;
