import Yup from "../i18n/yup";
import {
  ESearchableProperty,
  TStepField,
  IInput,
  IFieldRepeatable,
  IStep,
  IFormSchema,
  EComparator,
  IVisibleCondition,
  EFormType,
} from "../types";
import { getIn } from "formik";

export const flattenProp = (
  searchedProperty: ESearchableProperty,
  field: TStepField | IStep
) => {
  return {
    ...("name" in field ? flattenPropOfField(searchedProperty, field) : {}),
    ...("repeatable" in field && "fields" in field
      ? flattenPropOfRepeatable(searchedProperty, field)
      : {}),
    ...(!("repeatable" in field) && "fields" in field
      ? flattenPropOfArray(searchedProperty, field.fields)
      : {}),
  };
};

const flattenPropOfField = (
  searchedProperty: ESearchableProperty,
  field: IInput
) => {
  let values: any = {};
  values[field.name] = field[searchedProperty];
  return values;
};

const flattenPropOfRepeatable = (
  searchedProperty: ESearchableProperty,
  field: IFieldRepeatable
) => {
  let value: any;
  const childrenValues = flattenPropOfArray(searchedProperty, field.fields);

  if (searchedProperty === ESearchableProperty.Validation) {
    if (field.validation) {
      // si le champ repeatable a une validation, on l'utilise en priorité
      value = field[ESearchableProperty.Validation];
    } else {
      value = Yup.array().of(Yup.object().shape(childrenValues));
    }
  } else if (
    searchedProperty === ESearchableProperty.Values &&
    field.initialEmpty
  ) {
    value = [];
  } else {
    value = [childrenValues];
  }
  return {
    [field.repeatable]: value,
  };
};

export const flattenPropOfArray = (
  searchedProperty: ESearchableProperty,
  fields: TStepField[] | IStep[]
) => {
  let values: any = {};
  fields.forEach((childrenField: TStepField) => {
    values = {
      ...values,
      ...flattenProp(searchedProperty, childrenField),
    };
  });
  return values;
};

export const populateValues = (template: any, values: any) => {
  let newObject = { ...template };

  for (const property in values) {
    if (property in newObject) {
      newObject[property] = values[property];
    }
  }
  return newObject;
};

export const getNested = (obj: any, ...args: string[]) => {
  return args.reduce((obj, level) => obj && obj[level], obj);
};

export const maskedDate = (value: string) => {
  return value
    .replace(/^(\d\d)(\d)$/g, "$1/$2")
    .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2") // eslint-disable-next-line  no-useless-escape
    .replace(/[^\d\/]/g, "");
};

export const cleanSchema = (schema: IFormSchema) => {
  const keys = [
    "validation",
    "placeholder",
    "size",
    "tooltip",
    "initialValue",
    "mask",
    "containerVariant",
  ];
  let obj = JSON.parse(
    JSON.stringify(schema, (k, v) => (keys.includes(k) ? undefined : v))
  );
  return obj;
};

export const stringToSlug = (str: string) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export const checkConditionOperator = (
  filterCondition: EComparator,
  leftValue: number,
  rightValue: number
) => {
  const operators = {
    "<=": function (a: number, b: number) {
      return a <= b;
    },
    ">=": function (a: number, b: number) {
      return a >= b;
    },
    "<": function (a: number, b: number) {
      return a < b;
    },
    ">": function (a: number, b: number) {
      return a > b;
    },
  };
  return operators[filterCondition](leftValue, rightValue);
};

// Utilisé dans le cas des formulaires simplifiés
// ATTENTION si la condition est modifié ici, il faut la modifier dans le back aussi
export const canUserPay = (values: { [key: string]: any }) => {
  const {
    employees_count = 0,
    network_head = "Non",
    additional_information__network_belong = "Non",
  } = values;

  const belong_to_network = additional_information__network_belong === "Oui";
  const is_network_head = network_head === "Oui";
  const has_many_employees = employees_count > 49;

  const cannot_user_pay =
    belong_to_network || (!is_network_head && has_many_employees);

  return !cannot_user_pay;
};

// checkVisibleCondition
export const checkConditions = (
  conditions: IVisibleCondition[],
  values: any
) => {
  let allConditionsMatched = false;
  // si value est un c'est un tableau
  conditions.forEach((visibleCondition) => {
    const currentValue = getIn(values, visibleCondition.name);

    if (visibleCondition.comparator) {
      if (
        !checkConditionOperator(
          visibleCondition.comparator,
          currentValue,
          visibleCondition.value
        )
      ) {
        allConditionsMatched = true;
      }
    } else {
      // si currentValue est un tableau
      if (
        Array.isArray(currentValue) &&
        !Array.isArray(visibleCondition.value) &&
        !currentValue.includes(visibleCondition.value)
      ) {
        allConditionsMatched = true;
      }

      // si visibleCondition.value est un tableau
      if (
        !Array.isArray(currentValue) &&
        Array.isArray(visibleCondition.value) &&
        !visibleCondition.value.includes(currentValue)
      ) {
        allConditionsMatched = true;
      }

      // pour le reste
      if (
        !Array.isArray(currentValue) &&
        !Array.isArray(visibleCondition.value) &&
        visibleCondition.value !== currentValue
      ) {
        allConditionsMatched = true;
      }
    }
  });

  return allConditionsMatched;
};

export const isAssurance = (type: EFormType) => {
  return [EFormType.Assurance, EFormType.AssuranceCredit].includes(type);
};
export const isCredit = (type: EFormType) => {
  return [EFormType.Credit, EFormType.AssuranceCredit].includes(type);
};
