import {
  IStep,
  ESearchableProperty,
  TStepField,
  IAttachmentLabelsSection,
  EFormType,
  EFormSubType,
  IFormSchema,
  EIconNames,
} from "../types";
import { flattenPropOfArray } from "./index";

class FormHelper {
  label: string;
  title: string | JSX.Element;
  tableOfContentTitle: string;
  parcoursDescription: string | JSX.Element;
  color: string;
  logo: string;
  footerLogo: string;
  type: EFormType;
  subtype: EFormSubType;
  steps: IStep[];
  footerCertif?: string;
  attachmentStepIndex: number;
  howToDocument?: string;
  icon: EIconNames;
  iconDimensions: {
    width: number;
    height: number;
  };

  constructor(schema: IFormSchema) {
    this.label = schema.label;
    this.title = schema.title;
    this.tableOfContentTitle = schema.tableOfContentTitle;
    this.parcoursDescription = schema.parcoursDescription ?? "";
    this.steps = schema.steps;
    this.color = schema.color;
    this.logo = schema.logo;
    this.footerLogo = schema.footerLogo ?? schema.logo;
    this.type = schema.type;
    this.footerCertif = schema.footerCertif;
    this.subtype = schema.subtype;
    this.attachmentStepIndex = schema.steps.length - 1;
    this.icon = schema.icon ?? EIconNames.LogoCNCEF;
    this.howToDocument = schema.howToDocument ?? "";
    this.iconDimensions = schema.iconDimensions ?? {
      width: 194,
      height: 54,
    };
  }
  get isSimple() {
    return this.subtype === EFormSubType.Simple;
  }

  get isClassic() {
    return this.subtype === EFormSubType.Classic;
  }

  get isFinal() {
    return this.subtype === EFormSubType.Final;
  }

  get isRenewal() {
    return this.subtype === EFormSubType.Renewal;
  }

  get stepTitles() {
    return this.steps.map((step) => step.title);
  }

  get initialValues() {
    return flattenPropOfArray(ESearchableProperty.Values, this.steps);
  }

  // Permet d'obtenir le libellé des pièces jointes à fournir dans le formulaire
  get attachmentSections(): IAttachmentLabelsSection[] {
    const reducer = this.steps[this.attachmentStepIndex].fields.reduce(
      (accumulator: any, currentField: TStepField) => {
        // seulement les sections nous intéressent
        if ("title" in currentField) {
          accumulator.push({
            title: currentField.title,
            // On récupère les labels
            labels: currentField.fields.map(
              (field: TStepField) => "label" in field && field.label
            ),
          });
        }
        return accumulator;
      },
      []
    );

    return reducer;
  }
}

export default FormHelper;
