import {
  EInputType,
  EInputSize,
  ERepeaterButtonVariant,
  EHumanType,
  ETextVariant,
  EContainerVariant,
} from "../../../types";
import Yup from "../../../i18n/yup";
import {
  date,
  stringR,
  emailR,
  numberR,
  stringF,
  number,
  phoneF,
  checkboxGroup,
} from "../../../helpers/validation";
import { dateMask } from "../../../helpers/masks";
import { regions, boolOptions } from "./options";

const isDirector = (type: EHumanType) => {
  return [
    EHumanType.DefaultDirector,
    EHumanType.DirectorIASIOS,
    EHumanType.DirectorCIF,
    EHumanType.DirectorImmo,
    EHumanType.DirectorFrance,
  ].includes(type);
};

export const factoryHuman = (type: EHumanType = EHumanType.Default) => {
  const requiredAddress = ![EHumanType.EmployeeCIF].includes(type);

  return [
    ...([EHumanType.DirectorCIF].includes(type)
      ? [
          {
            fieldsetTitle: "Identité",
            fields: [],
          },
        ]
      : []),
    {
      initialValue: "",
      label: "Nom d'usage",
      required: true,
      name: "lastname",
      placeholder: "Dupond",
      type: EInputType.Text,
      validation: stringR,
    },
    ...(![EHumanType.DirectorFrance].includes(type)
      ? [
          {
            initialValue: "",
            label: "Nom de naissance",
            name: "birthname",
            placeholder: "Dupont",
            type: EInputType.Text,
            validation: Yup.string(),
          },
        ]
      : []),
    {
      initialValue: "",
      label: "Prénom",
      required: true,
      name: "firstname",
      placeholder: "Jean",
      type: EInputType.Text,
      validation: stringR,
    },
    ...(![EHumanType.Minimal].includes(type)
      ? [
          {
            initialValue: "",
            label: "Date de naissance",
            required: true,
            name: "birthdate",
            placeholder: "JJ / MM / AAAA",
            type: EInputType.Text,
            size: EInputSize.Small,
            validation: date,
            mask: dateMask,
          },
        ]
      : []),
    ...(![EHumanType.DirectorFrance, EHumanType.Minimal].includes(type)
      ? [
          {
            initialValue: "",
            label: "Lieu de naissance",
            required: true,
            name: "birthplace",
            placeholder: "Nantes",
            type: EInputType.Text,
            size: EInputSize.Large,
            validation: stringR,
          },
        ]
      : []),
    ...(![
      EHumanType.DirectorFrance,
      EHumanType.EmployeeFrance,
      EHumanType.Minimal,
    ].includes(type)
      ? [
          {
            initialValue: "",
            label: "Adresse personnelle",
            required: requiredAddress,
            name: "address",
            placeholder: "12 rue de l’Héronnière",
            type: EInputType.Text,
            size: EInputSize.Large,
            validation: requiredAddress ? stringR : stringF,
          },
          {
            initialValue: "",
            label: "Code postal",
            required: requiredAddress,
            name: "zipcode",
            placeholder: "000000",
            type: EInputType.Text,
            size: EInputSize.Small,
            validation: requiredAddress ? stringR : stringF,
          },
          {
            initialValue: "",
            label: "Ville",
            required: requiredAddress,
            name: "city",
            placeholder: "Paris",
            type: EInputType.Text,
            size: EInputSize.Large,
            validation: requiredAddress ? stringR : stringF,
          },
        ]
      : []),
    ...(![EHumanType.Minimal].includes(type)
      ? [
          {
            initialValue: "",
            label: "Téléphone",
            required: false,
            name: "phone",
            placeholder: "0102030405",
            type: EInputType.Text,
            size: EInputSize.Small,
            validation: phoneF,
          },
          {
            initialValue: "",
            label: "Courriel",
            required: true,
            name: "email",
            placeholder: "jeandupont@courriel.fr",
            type: EInputType.Email,
            validation: emailR,
          },
        ]
      : []),

    ...([EHumanType.DirectorCIF].includes(type)
      ? [
          {
            fieldsetTitle: "Mandat social",
            fields: [],
          },
        ]
      : []),
    ...([EHumanType.DirectorCIF, EHumanType.DefaultDirector].includes(type)
      ? [
          {
            initialValue: "",
            label: "Nature du mandat social",
            required: true,
            name: "mandate_nature",
            placeholder: "Précisez la nature",
            type: EInputType.Text,
            validation: stringR,
          },
          {
            initialValue: "",
            label: "Date de nomination",
            required: true,
            name: "mandate_nomination_date",
            placeholder: "JJ / MM / AAAA",
            type: EInputType.Text,
            size: EInputSize.Small,
            validation: date,
            mask: dateMask,
          },
          {
            initialValue: "",
            label: "Durée du mandat (en année)",
            required: true,
            name: "mandate_duration",
            placeholder: "000",
            type: EInputType.Number,
            size: EInputSize.Small,
            validation: numberR,
          },
        ]
      : []),
    ...(![EHumanType.DirectorIASIOS, EHumanType.Minimal].includes(type)
      ? [
          {
            initialValue: "",
            label: "Fonction(s) opérationnelle(s)",
            required: true,
            name: "operational_function",
            placeholder: "Préciser la fonction",
            type: EInputType.Text,
            validation: stringR,
          },
          {
            initialValue: "",
            label: "Localisation géographique",
            required: requiredAddress,
            name: "region",
            placeholder: "Choisissez une région",
            type: EInputType.Select,
            options: regions,
            validation: requiredAddress ? stringR : stringF,
          },
        ]
      : []),
    ...([EHumanType.DirectorCIF].includes(type)
      ? [
          {
            initialValue: "",
            label: "Temps de présence % (activité CIF)",
            required: true,
            name: "presence_cif",
            placeholder: "15%",
            type: EInputType.Text,
            size: EInputSize.Small,
            validation: stringR,
          },
          {
            initialValue: "",
            label: "Si temps partiel indiquer les autres fonctions",
            name: "part_time",
            placeholder: "Préciser les fonctions",
            type: EInputType.Text,
            size: EInputSize.Large,
            validation: Yup.string(),
          },
          {
            fieldsetTitle: "Fonction(s) antérieure(s) en tant que cif",
            fieldsetDescription:
              "Préciser toute fonction antérieure en tant que CIF (dirigeants ou salariés), en renseignant la raison sociale des entreprises où cette activité a été exercée et les fonctions occupées.",
            fields: [
              {
                repeatable: "previous_cif_function",
                pdfLabel: "Fonction",
                addButtonLabel: "Ajouter une fonction",
                initialEmpty: true,
                variant: ERepeaterButtonVariant.Small,
                fields: [
                  {
                    initialValue: "",
                    label: "Raison sociale",
                    required: true,
                    name: "legal_entity",
                    placeholder: "Préciser la raison sociale",
                    type: EInputType.Text,
                    validation: stringR,
                  },
                  {
                    initialValue: "",
                    label: "Fonction occupée",
                    required: true,
                    name: "position_occupied",
                    placeholder: "Fonction occupée",
                    type: EInputType.Text,
                    validation: stringR,
                  },
                ],
              },
            ],
          },
        ]
      : []),
    ...(![EHumanType.DirectorFrance, EHumanType.Minimal].includes(type)
      ? [
          {
            initialValue: [],
            label: "Compétence professionnelle",
            name: "professional_competence",
            required: true,
            type: EInputType.Checkbox,
            tooltip: ![
              EHumanType.DirectorImmo,
              EHumanType.EmployeeFrance,
              EHumanType.EmployeeCredit,
              EHumanType.EmployeeAssurance,
            ].includes(type) ? (
              <>
                Les conditions d’accès à la profession sont vérifiées par
                l’Orias.
              </>
            ) : (
              ""
            ),
            options: [
              {
                label: "Diplôme enregistré au RNCP",
                value: "Diplôme",
              },
              {
                label: "Formation professionnelle",
                value: "Formation",
              },
              {
                label: "Expérience professionnelle",
                value: "Expérience professionnelle",
              },
            ],
            validation: checkboxGroup,
          },
        ]
      : []),
    ...([EHumanType.DirectorCIF, EHumanType.EmployeeCIF].includes(type)
      ? [
          {
            initialValue: "",
            label: "Date de l'obtention de l'examen AMF",
            required: true,
            name: "amf",
            placeholder: "JJ / MM / AAAA",
            type: EInputType.Text,
            size: EInputSize.Small,
            validation: date,
            mask: dateMask,
            tooltip:
              "Sauf si l’entité a bénéficié du dispositif transitoire de 2017-2019 en validant les 3 modules de vérification interne des connaissances minimales. A compter du 1er janvier 2020, la vérification du niveau de connaissances minimales des CIF personne physique, des dirigeants CIF personnes morales et des personnes physiques employées pour exercer l’activité de CIF, est établie exclusivement par la réussite de l’examen organisé par un organisme de formation certifié par l’AMF.",
          },
          {
            initialValue: "",
            label: "Nom de l'organisme de formation AMF",
            required: true,
            name: "amf_organization",
            placeholder: "",
            type: EInputType.Text,
            validation: stringR,
          },
        ]
      : []),
    ...([EHumanType.EmployeeCIF].includes(type)
      ? [
          {
            fieldsetTitle: "",
            containerVariant: EContainerVariant.Small,
            fields: [
              {
                variant: ETextVariant.Notice,
                content:
                  "La vérification de l'honorabilité sera demandée lors de la dernière étape.",
              },
            ],
          },
        ]
      : []),
    ...([EHumanType.DirectorCIF].includes(type)
      ? [
          {
            initialValue: "",
            label: "Indiquer si le dirigeant dispose de la CJA",
            tooltip: "CJA = Compétence Juridique Appropriée",
            required: true,
            name: "director_has_cja",
            type: EInputType.Radio,
            options: boolOptions,
            validation: stringR,
          },
        ]
      : []),
    ...([EHumanType.DirectorFrance].includes(type)
      ? [
          {
            fieldsetTitle: "Capacité professionnelle",
            extraMargin: true,
            fields: [
              {
                initialValue: "",
                label: "Diplôme",
                name: "professional_competence__diploma",
                type: EInputType.Text,
                validation: stringF,
              },
              {
                initialValue: "",
                label: "Formation",
                name: "professional_competence__training",
                type: EInputType.Text,
                validation: stringF,
              },
              {
                initialValue: "",
                label: "Expérience professionnelle",
                name: "professional_competence__experience",
                type: EInputType.Text,
                validation: stringF,
              },
            ],
          },
          {
            initialValue: "",
            label:
              "Autres activités (publications, formations, fonctions électives...)",
            name: "human_other_activies",
            type: EInputType.Text,
            size: EInputSize.Large,
          },
          {
            initialValue: 0,
            label:
              "Part de votre temps professionnel consacré à votre structure (en %)",
            name: "structure_time_share",
            type: EInputType.Number,
            size: EInputSize.Small,
            validation: number,
          },
          {
            initialValue: "",
            label: "Autres activités professionnelles",
            name: "other_pro_activies",
            type: EInputType.TextArea,
            size: EInputSize.Large,
          },
        ]
      : []),
    ...([
      EHumanType.Default,
      EHumanType.EmployeeCredit,
      EHumanType.EmployeeAssurance,
    ].includes(type)
      ? [
          {
            initialValue: "",
            label: "Niveau de capacité professionnelle",
            name: "professional_competence__level",
            placeholder: "",
            type: EInputType.Text,
            validation: Yup.string(),
            tooltip:
              "Le niveau de capacité professionnelle peut varier selon la fonction exercée : Les salariés responsables de production ou en charge d'animer un réseau de production doivent justifier d'un niveau 1. Les salariés effectuant des actes de distribution doivent justifier d'un niveau 2. Toutefois, s'ils effectuent des actes de distribution uniquement au siège ou dans un bureau dont le responsable est de niveau 1, ils doivent alors justifier d'un niveau 3.",
          },
        ]
      : []),
    ...(isDirector(type)
      ? []
      : [
          {
            initialValue: "",
            label: "Autoriser l'accès à l'espace salarié",
            required: true,
            name: "employee_has_access",
            type: EInputType.Radio,
            options: boolOptions,
            validation: stringR,
          },
        ]),
  ];
};
