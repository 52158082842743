import React from "react";
import { EInputType, EInputSize } from "../../types";
import { StyledInput, StyledMaskedInput } from "./styled-components";
import { Field } from "formik";

interface Props {
  name: string;
  placeholder?: string;
  type?: EInputType;
  size?: EInputSize;
  mask?: any;
  disabled?: boolean;
}
/*
 * Si la prop mask est passée, on utilise la librairie "react-text-mask"
 */
const Input: React.FC<Props> = ({
  name,
  placeholder = "",
  type = EInputType.Text,
  size = EInputSize.Regular,
  mask = false,
  disabled = false,
}) =>
  mask ? (
    <Field name={name}>
      {({ field }: any) => (
        <StyledMaskedInput
          {...field}
          size={size}
          type={type}
          mask={mask}
          name={name}
          placeholder={placeholder}
          guide={false}
          disabled={disabled}
        />
      )}
    </Field>
  ) : (
    <StyledInput
      size={size}
      type={type}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
    />
  );

export default Input;
