export { default as factoryClientType } from "./factoryClientType";
export { default as factoryFranceClientType } from "./factoryFranceClientType";
export { default as factoryActivities } from "./factoryActivities";
export { default as step1 } from "./step1";
export { default as factorySimpleStep } from "./factorySimpleStep";
export * from "./factoryHuman";
export { default as factoryTable } from "./factoryTable";

export const constants = {
  maxFile: 10,
};
