import {
  IStep,
  EInputType,
  EInputSize,
  EContainerVariant,
  ETextVariant,
} from "../../../types";
import Yup from "../../../i18n/yup";
import { stringR, checkboxGroup } from "../../../helpers/validation";
import { boolOptions } from "../common/options";
import documents from "../../documents";

const step5: IStep = {
  // ÉTAPE 5
  title: "Périmètre du programme d’activité",
  description: "Détails sur la nature de votre activité",
  fields: [
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          variant: ETextVariant.Body1,
          content:
            "Les lignes suivantes définissent le périmètre des activités qui seront exercées par le CIF.",
        },
      ],
    },
    {
      ///// SECTION
      title: "A. Activités de conseil en investissements financiers",
      fields: [
        {
          initialValue: "",
          label: "1. Conseil en investissement",
          name: "activities__cif_activity",
          type: EInputType.Checkbox,
          required: true,
          validation: checkboxGroup,
          options: [
            {
              label: "Conseil en investissement indépendant",
              value: "Indépendant",
              tooltip:
                'Attention : Un CGP dit "indépendant" est un conseiller qui prodigue des conseils et se rémunère par une commission de conseil directement payée par son client. Dans ce cas, il ne peut pas percevoir de rétrocessions.',
            },
            {
              label: "Conseil en investissement non-indépendant",
              value: "Non-indépendant",
            },
          ],
        },
        {
          fieldsetTitle: "Nature des instruments financiers traités *",
          fields: [
            {
              initialValue: "",
              label: "",
              pdfLabel: "Nature des instruments financiers traités",
              name: "activities__financial_instrument",
              type: EInputType.Checkbox,
              size: EInputSize.Large,
              options: [
                {
                  label: "Actions",
                  value: "Actions",
                },
                {
                  label: "Titres de créances (obligations, EMTN…)",
                  value: "Titres de créances (obligations, EMTN…)",
                },
                {
                  label: "Parts ou actions d’OPC",
                  value: "Parts ou actions d’OPC",
                  tooltip:
                    "OPCVM, fonds d’investissement à vocation générale, fonds de fonds alternatifs, fonds de capital investissement, fonds d’épargne salariale, OPCI, SCPI, SEF, organismes de titrisation, fonds professionnels à vocation générale, fonds professionnels spécialisés, fonds professionnels de capital investissement, organismes professionnels de placement collectif immobilier, SICAF)",
                },
                {
                  label: "RTO sur parts ou actions d’OPC",
                  value: "RTO sur parts ou actions d’OPC",
                },
                {
                  label: "Contrats financiers",
                  value: "Contrats financiers",
                },
              ],
              validation: checkboxGroup,
            },
            {
              initialValue: "",
              label:
                "2. Conseil portant sur la fourniture de Services d’investissement",
              required: true,
              name: "investisment_services",
              type: EInputType.Radio,
              options: boolOptions,
              validation: stringR,
            },
            {
              initialValue: "",
              label: (
                <>
                  Si oui, préciser les services d’investissement listés à l'
                  <a
                    href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000032786002"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    article L321-1
                  </a>{" "}
                  du Code monétaire et financier qui seront conseillés (exemple
                  : gestion sous mandat…)
                </>
              ),
              pdfLabel:
                "Si oui, préciser les services d’investissement listés à l’article L321-1 du Code monétaire et financier qui seront conseillés :",
              required: true,
              name: "investisment_services__details",
              type: EInputType.TextArea,
              placeholder: "Description des services d'investissement",
              validation: Yup.mixed().when("investisment_services", {
                is: "Oui",
                then: stringR,
              }),
              visible: [
                {
                  name: "investisment_services",
                  value: "Oui",
                },
              ],
            },
            {
              initialValue: "",
              label:
                "3. Conseil portant sur la réalisation d’opérations sur « biens divers » définies à l’article L550-1 du Code Monétaire et Financier",
              tooltip: (
                <>
                  Pour en savoir plus, consultez l'
                  <a
                    href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000033612585"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    article L550-1
                  </a>{" "}
                  du Code Monétaire et Financier et{" "}
                  <a
                    href="https://www.amf-france.org/fr/espace-epargnants/proteger-son-epargne/listes-blanches"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    les listes blanches
                  </a>{" "}
                  de l'Autorité des marchés financiers
                </>
              ),
              required: true,
              name: "investisment_biens_divers",
              type: EInputType.Radio,
              options: boolOptions,
              validation: stringR,
            },
          ],
        },
      ],
    },
    {
      ///// SECTION
      title: "B. Autres activités",
      fields: [
        {
          initialValue: [],
          label:
            "1. Activité relevant d'autres statuts/habilitation réglementée",
          required: true,
          name: "other_activities",
          type: EInputType.Checkbox,
          size: EInputSize.Large,
          options: [
            {
              label: "Intermédiaire en assurances",
              value: "Intermédiaire en assurances",
            },
            {
              label: "Mandat d’arbitrage de contrat d’assurance-vie",
              value: "Mandat d’arbitrage de contrat d’assurance-vie",
            },
            {
              label:
                "Intermédiaire en opérations de banque et services de paiement",
              value:
                "Intermédiaire en opérations de banque et services de paiement",
            },
            {
              label: "Immobilier (carte T/attestation d'habilitation)",
              value: "Immobilier (carte T/attestation d'habilitation)",
            },
            {
              label: "Aucune",
              value: "Aucune",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: "",
          label:
            "2. Autres activités de conseil en gestion de patrimoine (hors activités régulées par l’ACPR)",
          tooltip:
            "Toutes offres de souscription non représentées par des instruments financiers (par exemple, souscription à des parts sociales, des opérations de défiscalisation non représentées par des actions ou OPC, investissements atypiques non représentés par des instruments financiers...) et qui ne relèvent pas de l’intermédiation en « biens divers ».",
          required: true,
          name: "has_other_activities_cif",
          type: EInputType.Radio,
          options: boolOptions,
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Présentation des activités",
      fields: [
        {
          fieldsetTitle: "",
          containerVariant: EContainerVariant.Small,
          fields: [
            {
              variant: ETextVariant.Body1,
              content:
                "Fournir une description narrative des activités envisagées qui fasse apparaître à minima :",
            },
            {
              listItems: [
                "les motivations de la demande du statut de CIF",
                "la représentativité de l’activité CIF au sein de la structure",
                "la typologie des produits que le CIF envisage de conseiller",
                "les principaux partenaires envisagés",
                "les principales sources de revenus",
              ],
            },
          ],
        },
        {
          initialValue: "",
          label: "",
          pdfLabel: "Description narrative des activités envisagées",
          name: "presentation_activity",
          placeholder: "Description des activités envisagées",
          type: EInputType.TextArea,
          validation: stringR,
        },
      ],
    },

    {
      ///// SECTION
      title: "Conflits d’intérêts",
      fields: [
        {
          fieldsetTitle: "",
          containerVariant: EContainerVariant.Small,
          fields: [
            {
              variant: ETextVariant.Notice,
              content: (
                <>
                  Pour en savoir plus sur la complétion de ce bloc et comprendre
                  les conflits d'intérêts, <br />
                  consultez la{" "}
                  <a
                    href={documents.noteConflitInteret}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    note CNCEF
                  </a>
                  .
                </>
              ),
            },
          ],
        },
        {
          initialValue: "",
          label:
            "Décrire les éventuels conflits d'intérêts potentiels et les modalités de gestion de ceux-ci :",
          name: "conflict_of_interest",
          placeholder: "Description des conflits d'intérêts",
          type: EInputType.TextArea,
          required: true,
          validation: stringR,
        },
      ],
    },
    {
      ///// SECTION
      title: "Famille de métier de rattachement",
      fields: [
        {
          initialValue: [],
          label: "",
          pdfLabel: "Famille de métier de rattachement",
          name: "professions_families",
          type: EInputType.Checkbox,
          size: EInputSize.Large,
          options: [
            {
              label: "CIF CGP",
              value: "CIF CGP",
              tooltip:
                "Le CIF CGP fournit du conseil à vocation patrimoniale, généralement destiné à une population d’épargnants particuliers. Il peut fournir également ses conseils à des entreprises pour le placement de leur trésorerie dans un objectif patrimonial. Pour l’exercice de ses missions, le CIF CGP dispose généralement d'autres statuts en plus de celui de CIF (intermédiaire d’assurance, IOBSP, carte T... ).",
            },
            {
              label:
                "CIF Conseil aux investisseurs professionnels, sociétés de gestion et autres intermédiaires financiers",
              value:
                "CIF Conseil aux investisseurs professionnels, sociétés de gestion et autres intermédiaires financiers",
              tooltip:
                "Le CIF \"Conseil aux investisseurs institutionnels, sociétés de gestion et autres intermédiaires financiers\" conseille et accompagne des investisseurs institutionnels (fondations, caisses de retraite, compagnies d'assurance...) sur leurs problématiques d'investissements. Il intervient également en conseil pour les sociétés de gestion et pour d'autres intermédiaires financiers.",
            },
          ],
        },
      ],
    },
  ],
};
export default step5;
