import styled, { keyframes } from "styled-components";
import { colors, fonts, breakpoints } from "../../theme";

export const Wrapper = styled.div``;
export const DropZone = styled.div`
  display: block;
  border: 1px dashed ${colors.greyLight};
  border-radius: 2px;
  width: 25rem;
  height: 7rem;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  position: relative;
  &:hover {
    background-color: rgba(246, 246, 246);
  }
  & + div {
    margin-top: 1rem;
  }

  ${breakpoints.max.md} {
    width: 100%;
  }
`;
export const Error = styled.div`
  color: ${colors.red};
  font-size: ${fonts.size.xs};
  margin-top: 0.4rem;
`;
export const Notice = styled.div`
  color: ${colors.grey};
  font-size: ${fonts.size.xs};
  margin-top: 0.4rem;
`;

export const DropZoneContent = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: all 0.2s ease;
`;
export const SuccessIcon = styled.div`
  font-size: 0;
  margin-right: 1rem;
`;
export const RemoveButton = styled.button`
  font-size: 0;
  margin-left: auto;
`;
export const SelectedFile = styled.div`
  display: flex;
  margin-top: 0;
  align-items: center;
  max-width: 25rem;

  & + div {
    margin-top: 1rem;
  }

  ${breakpoints.max.md} {
    max-width: 100%;
  }
`;

export const SelectedFileName = styled.div`
  font-size: ${fonts.size.s};
  font-weight: 700;
  text-overflow: ellipsis;
  flex: 0 1 auto;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 1rem;
`;

export const loading = keyframes`
  from {
    width: 0;
  }
  to {
    width: 68%;
  }
`;
export const Progress = styled.div<{ isLoading: boolean }>`
  background: ${colors.grey};
  border-radius: 100px;
  align-items: center;
  position: relative;
  display: block;
  height: 0.6rem;
  width: 16rem;
  overfow: hidden;
  &:before {
    content: "";
    position: absolute;
    border-radius: 1rem;
    background: ${colors.primary};
    height: 0.6rem;
    width: 0;
    animation: ${loading} 3s normal forwards;
    display: ${(props) => (props.isLoading ? `block` : "none")};
  }
`;
