import Yup from "../i18n/yup";
import { ESearchableProperty, IStep } from "../types";
import { flattenProp } from "./index";

class StepHelper {
  step: IStep;

  constructor(step: IStep) {
    this.step = step;
  }

  get validationSchema() {
    return Yup.object().shape(
      flattenProp(ESearchableProperty.Validation, this.step)
    );
  }
}

export default StepHelper;
