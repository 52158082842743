import styled from "styled-components";
import { colors, fonts, breakpoints } from "../../theme";

export const Wrapper = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  right: 0;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 2;

  ${(props) =>
    props.open
      ? `
            display: flex;
    `
      : `
            display: none;
    `};
`;

export const Overlay = styled.button`
  background-color: ${colors.primary};
  cursor: pointer;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
`;
export const Content = styled.div`
  border-radius: 0.5rem;
  background: ${colors.white};
  padding: 5rem 6rem;
  box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.25);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  width: 90rem;
  position: relative;
  transition: all 0.2s ease;

  ${breakpoints.max.md} {
    padding: 2rem 2.5rem;
  }
`;
export const Header = styled.div`
  margin-bottom: 2.5rem;
`;
export const Body = styled.div``;
export const Title = styled.p`
  font-size: ${fonts.size.xl};
  font-family: ${fonts.family.secondary};
  font-weight: ${fonts.weight.heading};
  color: ${colors.primary};
  margin-bottom: 0.8rem;
`;
export const Description = styled.div``;
export const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  right: 2.1rem;
  top: 2.4rem;
  ${breakpoints.max.md} {
    right: 1rem;
    top: 1.5rem;
  }
`;

export const LoaderWrapper = styled.div<{ isLoading: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  right: 0;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: -1;
  opacity: 0;
  background-color: ${colors.white};

  ${(props) =>
    props.isLoading &&
    `
        z-index: 1;
        opacity: 1;
    `};
`;
