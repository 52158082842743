import React from "react";
import { Field } from "formik";
import { StyledSelect } from "./styled-components";
import { IOption } from "../../types";

interface Props {
  disabled?: boolean;
  name: string;
  placeholder?: string;
  options?: IOption[];
}
const Select: React.FC<Props> = ({
  options = [],
  placeholder = "Choisir une option",
  ...props
}) => {
  return (
    <Field as={StyledSelect} {...props}>
      <option value="">{placeholder}</option>
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Field>
  );
};

export default Select;
