import React, { useCallback, useEffect, useRef, useState } from "react";
import { StepperItem } from "../../components";
import { Wrapper, Corner } from "./styled-components";

interface Props {
  currentIndex: number;
  items?: Array<string>;
  onClick?: (number: number) => void;
}

const Stepper: React.FC<Props> = ({
  currentIndex,
  items = [],
  onClick = () => {},
  ...props
}) => {
  const [completion, setCompletion] = useState<number>(0);
  const parentRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);

  //get completion percentage
  const caclulateComplexion = useCallback(() => {
    const index = Math.min(currentIndex - 1, items.length);

    const currentRef = itemsRef.current[index];
    const { left = 0, width = 1 } = currentRef
      ? currentRef.getBoundingClientRect()
      : {};
    const currentItemCenter = left + width / 2;
    const { left: parentLeft = 0 } =
      parentRef && parentRef.current
        ? parentRef.current.getBoundingClientRect()
        : {};

    setCompletion(currentItemCenter - parentLeft);
  }, [currentIndex, itemsRef, parentRef, items.length]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, items.length);
  }, [items]);

  useEffect(() => {
    caclulateComplexion();
  }, [caclulateComplexion]);

  useEffect(() => {
    const resizeListener = () => caclulateComplexion();
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, [caclulateComplexion]);

  return (
    <div ref={parentRef}>
      <Wrapper completion={completion} {...props}>
        <Corner first />
        {items.map((item, index) => (
          <div ref={(el) => (itemsRef.current[index] = el)} key={index}>
            <StepperItem
              onClick={() => onClick(index)}
              index={index}
              label={item}
              active={currentIndex === index + 1}
              completed={currentIndex > index + 1}
            />
          </div>
        ))}
        <Corner />
      </Wrapper>
    </div>
  );
};

export default Stepper;
