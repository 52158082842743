import Yup from "../i18n/yup";
export const checkbox = Yup.string().oneOf(
  ["yes"],
  "Ce champ est obligatoire."
);

export const files = Yup.array().of(Yup.string());
export const filesR = Yup.array().of(Yup.string()).min(1).defined();
export const filesREmployee = Yup.array()
  .of(Yup.string())
  .min(1, "Obligatoire si salarié déclaré")
  .defined();

export const stringR = Yup.string().required();
export const stringF = Yup.string();

export const emailR = Yup.string()
  .email()
  .strict()
  .lowercase("Les majuscules ne sont pas acceptées.")
  .required();

export const date = Yup.string()
  .matches(
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    "Le format de date doit être JJ/MM/YYYY"
  )
  .required();

export const dateDayMonth = Yup.string()
  .matches(
    // eslint-disable-next-line  no-useless-escape
    /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])$/,
    "Le format de date doit être JJ/MM"
  )
  .required();
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const phoneF = Yup.string().matches(
  phoneRegExp,
  "Le format du numéro de téléphone est incorrect"
);

const inscriptionPhoneRegExp = /^(336|337|590|594|262|596|687|689|590|508|681)[\d]{7,10}$/;
export const inscriptionPhone = Yup.string()
  .matches(
    inscriptionPhoneRegExp,
    "Le format du numéro de téléphone est incorrect. Vérifiez que vous avez bien supprimé le 0 de votre numéro lors de votre saisie."
  )
  .required();

export const phone = phoneF.required();

export const number = Yup.number().min(0);
export const numberR = Yup.number().min(0).required();
export const numberRNotNull = Yup.number()
  .min(1, "Ce nombre ne peut pas être nul.")
  .required();

export const checkboxGroup = Yup.array().of(Yup.string()).min(1).defined();

export const siren = Yup.string()
  .required()
  .min(9)
  .max(9)
  .test(
    "Le SIREN est incorrect.",
    "Le SIREN est incorrect.",
    (value) => value !== "000000000" && value !== "123456789"
  );

export const naf = Yup.string()
  .matches(
    /^\d{4}[A-Za-z]{1}$/,
    "Le champ NAF doit être consitué de quatre chiffres et d'une lettre."
  )
  .required();

export const legalForm = Yup.mixed().when("entity__type", {
  is: "Personne Physique indépendante (PPI)",
  then: Yup.string()
    .matches(
      /^EI$/,
      "En tant que Personne Physique indépendante, le champ forme juridique doit être égal à EI."
    )
    .required(),
  otherwise: stringR,
});

// must be yes
export const mediatorR = Yup.string()
  .matches(
    /^Oui$/,
    "Nous vous informons que vous êtes tenu de souscrire à un service de médiation pour votre/vos activité(s) cité(es) ci-dessus, il s'agit d'une obligation depuis le 1er janvier 2016. En effet, le professionnel doit permettre à son client d'accéder à un dispositif de médiation de la consommation en vue de la résolution à l'amiable d'un éventuel litige (Article L. 612-1 du code de la consommation). Votre renouvellement ne pourra être validé sans avoir souscrit à un service de médiation à la consommation"
  )
  .required();

// must be a string of 8 numbers and required
export const oriasRegistrationNumberR = Yup.string()
  .matches(
    /^\d{8}$/,
    "Le numéro d'immatriculation Orias doit être constitué de 8 chiffres."
  )
  .required();
