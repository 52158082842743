import React, { useState, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { getNested } from "../helpers";
import api from "../config/api";
import { useFormikContext } from "formik";
import { FileInput } from "../components";

interface Props {
  name: string;
  max: number;
}
const FileInputContainer: React.FC<Props> = ({ name, max = 1 }) => {
  const multiple = useMemo(() => max > 1, [max]);
  const { values, setFieldValue }: any = useFormikContext();
  const [files, setFiles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const handleError = useCallback(
    (errorMessage: string) => {
      setErrors([...errors, errorMessage]);
    },
    [errors, setErrors]
  );

  const getErrorMessageFromCode = (errorCode: string) => {
    let message = "Une erreur est survenue.";
    switch (errorCode) {
      case "too-many-files":
        message = `Le nombre de fichier autorisée a été dépassé (maximum : ${max}).`;
        break;
      case "file-invalid-type":
        message =
          "Seuls les formats JPEG, PDF, PNG, GIF, DOC, DOCX et XLS sont autorisés.";
        break;
      case "file-too-large":
        message = "La taille du fichier ne doit pas dépasser 2mo.";
        break;
    }
    return message;
  };

  const onDrop = useCallback(
    async (rawAcceptedFiles) => {
      setErrors([]);

      // check that files has been added
      if (rawAcceptedFiles.length === 0) {
        return;
      }

      // check that accepted files are not already uploaded
      let acceptedFiles: { name: any; size: any }[] = [];
      let duplicateErrors: string[] = [];
      if (files.length > 0) {
        rawAcceptedFiles.forEach((file: { name: any; size: any }) => {
          const exists = files.some(
            (f: { name: any; size: any }) =>
              f.name === file.name && f.size === file.size
          );
          if (!exists) {
            acceptedFiles.push(file);
          } else {
            duplicateErrors.push(
              `Un fichier avec le nom "${file.name}" a déjà été téléchargé.`
            );
          }
        });
      } else {
        acceptedFiles = [...rawAcceptedFiles];
      }

      if (duplicateErrors.length > 0) {
        setErrors([...duplicateErrors, ...errors]);
      }

      // check that files need to be upload after previous validation
      if (acceptedFiles.length === 0) {
        return;
      }

      // send the new files
      setIsLoading(true);
      let data = new FormData();
      acceptedFiles.forEach((acceptedFile: any, index: number) =>
        data.append("files" + index, acceptedFile)
      );
      data.append("count", `${acceptedFiles.length}`);
      data.append("name", name);

      api
        .addFile(values.id, data)
        .then((res) => {
          if (res && res.data && res.data.success) {
            const newFilePaths = res.data.paths;
            setFiles([...files, ...acceptedFiles]);
            setFieldValue(name, values[name].concat(newFilePaths));
          } else {
            handleError(res.data.data);
          }
        })
        .catch(() => handleError("Une erreur est survenue."))
        .finally(() => {
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleError, name, files, setFiles, setFieldValue, values.id]
  );

  const onDropRejected = useCallback((files) => {
    setIsLoading(false);
    console.log({ files });
    let newErrors: string[] = [];
    files.forEach((file: any) => {
      const code = getNested(file, "errors", "0", "code");
      const message = getErrorMessageFromCode(code);
      newErrors.push(message);
    });
    setErrors(newErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // https://developer.mozilla.org/fr/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    multiple: multiple,
    accept:
      "image/jpeg, application/pdf, image/png, image/gif, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    disabled: isLoading,
    maxFiles: multiple ? max : 1,
    maxSize: 2e6, // === 2 MB
  });

  const removeFile = async (removeIndex: number) => {
    const path = values[name][removeIndex];
    await api.removeFile(values.id, { path: path });
    setFiles(files.filter((_, index) => index !== removeIndex));
    setFieldValue(
      name,
      values[name].filter((_: any, index: number) => index !== removeIndex)
    );
  };

  return (
    <FileInput
      files={files}
      errors={errors}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      removeFile={removeFile}
      isLoading={isLoading}
      multiple={multiple}
    />
  );
};

export default FileInputContainer;
