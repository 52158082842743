import { colors } from "../../../theme";
import { EFormSubType, EFormType } from "../../../types";
import steps from "./steps";
import { LogoPatrimoine as logoSrc } from "../../../assets/svg";
import documents from "../../documents";

const patrimoineSchema = {
  label: "Patrimoine",
  title: "Demande d’adhésion CNCEF Patrimoine",
  tableOfContentTitle: "Parcours du programme d'activité CNCEF PATRIMOINE",
  parcoursDescription: (
    <>
      Afin de préparer la complétion de votre programme d'activité, nous vous
      invitons à visualiser un exemple en{" "}
      <a
        href={documents.patrimoineExemple}
        target="_blank"
        rel="noopener noreferrer"
      >
        cliquant ici
      </a>{" "}
      (attention : le programme d'activité est à compléter en ligne en passant à
      l'étape suivante sur cette page, et non pas par le biais de ce modèle
      non-utilisable) .
    </>
  ),
  color: colors.red,
  logo: logoSrc,
  steps,
  type: EFormType.Patrimoine,
  footerCertif: "Agréée AMF",
  subtype: EFormSubType.Classic,
  howToDocument: documents.patrimoineAideCompletude,
};
export default patrimoineSchema;
