import {
  EInputType,
  EInputSize,
  EHumanType,
  EFormType,
  EContainerVariant,
  ESectionVariant,
  ETextVariant,
} from "../../../types";
import Yup from "../../../i18n/yup";
import {
  phone,
  stringR,
  emailR,
  numberR,
  files,
  filesR,
  legalForm,
  naf,
  phoneF,
  siren,
  filesREmployee,
} from "../../../helpers/validation";
import { boolOptions } from "./options";
import { factoryHuman, constants } from ".";
import api from "../../api";
import documents from "../../documents";

const factorySimpleStep = (type: EFormType = EFormType.Credit) => ({
  title: "",
  description: "",
  fields: [
    {
      ///// SECTION
      title: "Entité",
      fields: [
        {
          initialValue: "",
          label: "SIREN",
          required: true,
          name: "entity__siren",
          placeholder: "000000000",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: siren,
        },

        ...([EFormType.Credit].includes(type)
          ? [
              {
                initialValue: "",
                label: "Catégorie",
                required: true,
                name: "entity__credit_category",
                type: EInputType.Radio,
                options: [
                  {
                    label:
                      "Courtier en opérations de banque et en services de paiement",
                    value: "COBSP",
                  },
                  {
                    label:
                      "Mandataire d’intermédiaire en opérations de banque et en services de paiement",
                    value: "MIOBSP",
                  },
                ],
                validation: stringR,
              },
              {
                initialValue: "",
                label: "Mandant",
                required: true,
                name: "entity__mandant",
                placeholder: "Nom du mandant",
                type: EInputType.Text,
                size: EInputSize.Large,
                validation: Yup.mixed().when("entity__credit_category", {
                  is: "MIOBSP",
                  then: stringR,
                }),
                visible: [
                  {
                    name: "entity__credit_category",
                    value: "MIOBSP",
                  },
                ],
              },
              {
                initialValue: "",
                label: "SIREN du Mandant",
                required: true,
                name: "entity__mandant_siren",
                placeholder: "",
                type: EInputType.Text,
                size: EInputSize.Large,
                validation: Yup.mixed().when("entity__credit_category", {
                  is: "MIOBSP",
                  then: stringR,
                }),
                visible: [
                  {
                    name: "entity__credit_category",
                    value: "MIOBSP",
                  },
                ],
              },
            ]
          : []),

        ...([EFormType.Assurance].includes(type)
          ? [
              {
                initialValue: "",
                label: "Catégorie",
                required: true,
                name: "entity__category",
                type: EInputType.Radio,
                options: [
                  {
                    label: "Courtier d’assurances",
                    value: "COA",
                  },
                  {
                    label: "Mandataire d’intermédiaire d’assurances",
                    value: "MIA",
                  },
                ],
                validation: stringR,
              },
              {
                initialValue: "",
                label: "Mandant",
                required: true,
                name: "entity__mandant",
                placeholder: "Nom du mandant",
                type: EInputType.Text,
                size: EInputSize.Large,
                validation: Yup.mixed().when("entity__category", {
                  is: "MIA",
                  then: stringR,
                }),
                visible: [
                  {
                    name: "entity__category",
                    value: "MIA",
                  },
                ],
              },
              {
                initialValue: "",
                label: "SIREN du Mandant",
                required: true,
                name: "entity__mandant_siren",
                placeholder: "",
                type: EInputType.Text,
                size: EInputSize.Large,
                validation: Yup.mixed().when("entity__category", {
                  is: "MIA",
                  then: stringR,
                }),
                visible: [
                  {
                    name: "entity__category",
                    value: "MIA",
                  },
                ],
              },
            ]
          : []),

        {
          initialValue: "",
          label: "Statut",
          required: true,
          name: "entity__type",
          type: EInputType.Radio,
          options: [
            {
              label: "Personne morale",
              value: "Personne morale",
            },
            {
              label: "Personne Physique indépendante (PPI)",
              value: "Personne Physique indépendante (PPI)",
            },
          ],
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Dénomination de la personne morale",
          required: true,
          name: "entity__denomination",
          placeholder: "Nom de votre entreprise, association…",
          type: EInputType.Text,
          size: EInputSize.Large,
          validation: Yup.mixed().when("entity__type", {
            is: "Personne morale",
            then: stringR,
          }),
          visible: [
            {
              name: "entity__type",
              value: "Personne morale",
            },
          ],
        },
        {
          initialValue: "",
          label: "Nom commercial",
          name: "entity__commercial_name",
          placeholder: "Nom de votre entreprise, association…",
          type: EInputType.Text,
          size: EInputSize.Large,
          validation: Yup.string(),
        },
        {
          initialValue: "",
          label: "Nom et prénom pour la personne physique",
          required: true,
          name: "entity__people_name",
          placeholder: "Dupond Jeanne",
          type: EInputType.Text,
          validation: Yup.mixed().when("entity__type", {
            is: "Personne Physique indépendante (PPI)",
            then: stringR,
          }),
          visible: [
            {
              name: "entity__type",
              value: "Personne Physique indépendante (PPI)",
            },
          ],
        },
        {
          initialValue: "",
          label: "Forme juridique",
          required: true,
          name: "entity__legal_name",
          tooltip:
            "Pour une SA, préciser le mode de direction (directoire et conseil de surveillance ou conseil d’administration)",
          placeholder: "Choisissez une forme juridique",
          type: EInputType.Select,
          options: [
            {
              label: "SA",
              value: "SA",
            },
            {
              label: "SAS",
              value: "SAS",
            },
            {
              label: "SARL",
              value: "SARL",
            },
            {
              label: "EURL",
              value: "EURL",
            },
            {
              label: "EI",
              value: "EI",
            },
            {
              label: "SASU",
              value: "SASU",
            },
            {
              label: "GIE",
              value: "GIE",
            },
            {
              label: "Association",
              value: "Association",
            },
          ],
          validation: legalForm,
        },
        {
          initialValue: "",
          label: "Si SA, préciser",
          name: "entity__sa__details",
          type: EInputType.Checkbox,
          size: EInputSize.Large,
          options: [
            {
              label: "Directoire et conseil de surveillance",
              value: "Directoire et conseil de surveillance",
            },
            {
              label: "Conseil d'administration",
              value: "Conseil d'administration",
            },
          ],
          visible: [
            {
              name: "entity__legal_name",
              value: "SA",
            },
          ],
        },
        {
          initialValue: "",
          label: "Code NAF",
          required: true,
          name: "entity__naf",
          placeholder: "0000X",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: naf,
        },
        {
          initialValue: 0,
          label: `Nombre de salariés ${
            [EFormType.Credit].includes(type) ? "IOB" : "IAS"
          }`,
          required: true,
          name: "employees_count",
          type: EInputType.Number,
          size: EInputSize.Small,
          validation: numberR,
        },
      ],
    },

    {
      ///// SECTION
      title: "Coordonnées",
      fields: [
        {
          initialValue: "",
          label: "Adresse du siège social",
          required: true,
          name: "contact_information__head_office__address",
          placeholder: "8 rue de l’Héronnière",
          type: EInputType.Text,
          size: EInputSize.Large,
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Code postal",
          required: true,
          name: "contact_information__head_office__zipcode",
          placeholder: "00000",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Ville",
          required: true,
          name: "contact_information__head_office__city",
          placeholder: "Nantes",
          type: EInputType.Text,
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Adresse du bureau administratif",
          name: "contact_information__administrative_office__address",
          placeholder: "8 rue de l’Héronnière",
          type: EInputType.Text,
          size: EInputSize.Large,
          validation: Yup.string(),
        },
        {
          initialValue: "",
          label: "Code postal du bureau administratif",
          name: "contact_information__administrative_office__zipcode",
          placeholder: "00000",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: Yup.string(),
        },
        {
          initialValue: "",
          label: "Ville du bureau administratif",
          name: "contact_information__administrative_office__city",
          placeholder: "Nantes",
          type: EInputType.Text,
          validation: Yup.string(),
        },
        {
          initialValue: "",
          label: "Téléphone fixe",
          name: "contact_information__phone",
          placeholder: "0102030405",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: phoneF,
        },
        {
          initialValue: "",
          label: "Téléphone mobile",
          required: true,
          name: "contact_information__mobile_phone",
          placeholder: "0102030405",
          type: EInputType.Text,
          size: EInputSize.Small,
          validation: phone,
        },
        {
          initialValue: "",
          label: "Courriel principal",
          required: true,
          name: "contact_information__email",
          placeholder: "jeandupont@courriel.fr",
          type: EInputType.Email,
          validation: emailR,
        },
        {
          initialValue: "",
          label: "Site internet",
          name: "contact_information__website",
          placeholder: "jeandupont.fr",
          type: EInputType.Text,
          validation: Yup.string(),
        },
        {
          initialValue: "",
          label: "N°Orias (si inscrit à l’Orias)",
          name: "additional_information__orias",
          placeholder: "00 00 00 00",
          type: EInputType.Text,
          size: EInputSize.Small,
          required: true,
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Appartenez-vous à un réseau ?",
          required: true,
          name: "additional_information__network_belong",
          type: EInputType.Radio,
          options: boolOptions,
          validation: stringR,
        },
        {
          initialValue: "",
          label: "Si oui : Nom du réseau",
          placeholder: "Choisissez un un réseau",
          required: true,
          name: "additional_information__network_name",
          options: api.getCatalog,
          type: EInputType.Select,
          validation: Yup.mixed().when(
            "additional_information__network_belong",
            {
              is: "Oui",
              then: stringR,
            }
          ),
          visible: [
            {
              name: "additional_information__network_belong",
              value: "Oui",
            },
          ],
        },
        {
          initialValue: "",
          label: "Tête de réseau",
          required: true,
          name: "network_head",
          type: EInputType.Radio,
          options: [
            {
              label: "Oui",
              value: "Oui",
            },
            {
              label: "Non",
              value: "Non",
            },
          ],
          validation: stringR,
        },
        {
          initialValue: "",
          required: true,
          label: "Activité principale",
          name: "activities__is_main",
          type: EInputType.Radio,
          options: boolOptions,
          validation: stringR,
        },
      ],
    },

    ...([EFormType.Assurance].includes(type)
      ? [
          {
            ///// SECTION
            title: "Dirigeant(s) IAS",
            variant: ESectionVariant.NoPaddingBottom,
            fields: [
              {
                repeatable: "director_ias",
                pdfLabel: "Dirigeant IAS",
                addButtonLabel: "Ajouter un(e) dirigeant(e)",
                fields: factoryHuman(EHumanType.Minimal),
              },
            ],
          },
        ]
      : []),

    ...([EFormType.Credit].includes(type)
      ? [
          {
            ///// SECTION
            title: "Dirigeant(s) IOB",
            variant: ESectionVariant.NoPaddingBottom,
            fields: [
              {
                repeatable: "director_iob",
                pdfLabel: "Dirigeant IOB",
                addButtonLabel: "Ajouter un(e) dirigeant(e)",
                fields: factoryHuman(EHumanType.Minimal),
              },
            ],
          },
        ]
      : []),

    {
      ///// SECTION
      title: "Pièces à joindre au dossier",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        {
          initialValue: [],
          label: "Attestation d'immatriculation ORIAS",
          name: "file__orias",
          type: EInputType.File,
          validation: filesR,
          required: true,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label:
            "Extrait Kbis datant de moins de 3 mois ou avis de situation SIREN si non-inscrit au RCS",
          name: "file__kbis_or_siren",
          type: EInputType.File,
          validation: filesR,
          required: true,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: [EFormType.Credit].includes(type) ? (
            <>
              Attestation RC PRO ou attestation de mandat si MIOBSP (
              <a
                href={documents.attestationIOBSP}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                télécharger le modèle
              </a>
              )
            </>
          ) : (
            <>
              Attestation RC PRO ou attestation de mandat si MIA (
              <a
                href={documents.attestationIAS}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                télécharger le modèle
              </a>
              )
            </>
          ),
          pdfLabel: "Attestation RC PRO ou attestation de mandat",
          name: "file__attestation_rcpro_or_mandat",
          type: EInputType.File,
          validation: filesR,
          required: true,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: "Attestation de Garantie financière si encaissement de fonds",
          name: "file__garantie",
          type: EInputType.File,
          validation: files,
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: [EFormType.Credit].includes(type) ? (
            <>
              Liste du personnel IOBSP à jour (
              <a
                href={documents.listePersonnel}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                télécharger le modèle
              </a>
              )
            </>
          ) : (
            <>
              Liste du personnel IAS à jour (
              <a
                href={documents.listePersonnel}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                télécharger le modèle
              </a>
              )
            </>
          ),
          pdfLabel: [EFormType.Credit].includes(type)
            ? "Liste du personnel IOBSP à jour"
            : "Liste du personnel IAS à jour",
          name: "file__list",
          type: EInputType.File,
          validation: Yup.mixed().when("employees_count", (value: number) => {
            return value && value >= 1 ? filesREmployee : files;
          }),
          max: constants.maxFile,
        },
        {
          initialValue: [],
          label: (
            <>
              Déclaration sur l'honneur attestant du respect de la condition
              d'honorabilité des salariés (
              <a
                href={
                  [EFormType.Credit].includes(type)
                    ? documents.declarationHonneurCredit
                    : documents.declarationHonneurAssurance
                }
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                télécharger le modèle
              </a>
              )
            </>
          ),
          pdfLabel:
            "Déclaration sur l’honneur concernant le contrôle d’honorabilité des salariés signée par le dirigeant",
          name: "file__declaration",
          type: EInputType.File,
          validation: Yup.mixed().when("employees_count", (value: number) => {
            return value && value >= 1 ? filesREmployee : files;
          }),
          max: constants.maxFile,
        },
      ],
    },
    {
      fieldsetTitle: "",
      containerVariant: EContainerVariant.Small,
      fields: [
        {
          variant: ETextVariant.Body1,
          content:
            "Attention, ceci est une pré-inscription, vous avez deux mois afin de compléter votre demande d’adhésion dans son intégralité.",
        },
      ],
    },
  ],
});

export default factorySimpleStep;
