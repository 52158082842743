import { IStep, EInputType, ESectionVariant, EInputSize } from "../../../types";
import { checkboxGroup } from "../../../helpers/validation";
import { factoryHuman } from "../common";

const step7: IStep = {
  // ÉTAPE 7
  title: "Organisation et moyen(s)",
  description:
    "Renseignements sur les moyens mis en place pour exercer votre activité au sein de votre structure dont les salariés",
  fields: [
    {
      ///// SECTION
      title: "Type de clientèle",
      fields: [
        {
          initialValue: [],
          label: "Type",
          required: true,
          name: "client_type__type",
          type: EInputType.Checkbox,
          size: EInputSize.Large,
          options: [
            { label: "Particuliers", value: "Particuliers" },
            {
              label: "Entreprises/Professionnels",
              value: "Entreprises/Professionnels",
            },
            {
              label: "Institutionnels",
              value: "Institutionnels",
            },
          ],
          validation: checkboxGroup,
        },
        {
          initialValue: [],
          label: "Location",
          required: true,
          name: "client_type__location",
          type: EInputType.Checkbox,
          size: EInputSize.Large,
          options: [
            { label: "France", value: "France" },
            {
              label: "Union Européenne Institutionnels",
              value: "Union Européenne Institutionnels",
            },
            {
              label: "International",
              value: "International",
            },
          ],
          validation: checkboxGroup,
        },
      ],
    },
    {
      ///// SECTION
      title: "Salarié(s)",
      variant: ESectionVariant.NoPaddingBottom,
      fields: [
        {
          repeatable: "employees_cncef",
          pdfLabel: "Salarié(e)",
          addButtonLabel: "Ajouter un(e) salarié(e)",
          initialEmpty: true,
          fields: factoryHuman(),
        },
      ],
    },
  ],
};

export default step7;
