import React from "react";
import { EContainerVariant, IFieldset } from "../types";
import { StepField } from "../containers";
import { Fieldset } from "../components";

const StepFieldset: React.FC<IFieldset> = ({
  fieldsetTitle,
  fieldsetDescription = "",
  tooltip = "",
  prefix = "",
  extraMargin = false,
  containerVariant = EContainerVariant.FullWidth,
  fields,
}) => {
  return (
    <Fieldset
      title={fieldsetTitle}
      description={fieldsetDescription}
      tooltip={tooltip}
      containerVariant={containerVariant}
      extraMargin={extraMargin}
    >
      {fields.map((field, index) => (
        <StepField key={index} field={field} prefix={prefix} />
      ))}
    </Fieldset>
  );
};

export default StepFieldset;
