import { colors } from "../../../theme";
import { EFormSubType, EFormType } from "../../../types";
import steps from "./steps";
import { LogoImmo as logoSrc } from "../../../assets/svg";

const immobilierSchema = {
  label: "Immobilier",
  title: "Demande d’adhésion à la CNCEF Immobilier",
  tableOfContentTitle:
    "Parcours d’une demande d’adhésion à la CNCEF Immobilier",
  color: colors.salmon,
  logo: logoSrc,
  steps,
  type: EFormType.Immobilier,
  subtype: EFormSubType.Classic,
};
export default immobilierSchema;
