import styled from "styled-components";
import { ErrorMessage } from "formik";
import { colors, fonts, breakpoints } from "../../theme";

export const StyledTable = styled.table`
  background-color: ${colors.greyLightest};

  &,
  th,
  td {
    border: 0.1rem solid #1c253e19;
    border-collapse: collapse;
  }
  thead {
    background-color: ${(props) => props.theme.colors.accent};
    color: ${colors.white};
    font-weight: 700;
    text-transform: uppercase;
  }
  thead th {
    padding: 2rem 3rem;
  }

  th {
    padding: 1rem 3rem;
  }
  th + th {
    text-align: center;
  }

  tbody tr:hover {
    background-color: ${colors.secondaryBackgroundColor};
  }
  ${breakpoints.max.md} {
    th {
      font-size: ${fonts.size.xs};
      padding: 1rem 1.5rem;
    }
    thead th {
      padding: 1rem 1.5rem;
      font-size: ${fonts.size.xxs};
    }
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const TooltipWrapper = styled.div`
  margin-left: 1rem;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  color: ${colors.red};
  font-size: ${fonts.size.xs};
  font-weight: 400;
  margin-top: 0.4rem;
`;
