import React from "react";
import { useFormikContext, FieldArray, getIn } from "formik";
import {
  ESearchableProperty,
  IFieldRepeatable,
  ERepeaterButtonVariant,
  ERepeaterContainerVariant,
} from "../types";
import { StepField } from "../containers";
import { RepeaterContainer, RepeaterButton } from "../components";
import { flattenPropOfArray } from "../helpers";

const StepRepeater: React.FC<IFieldRepeatable> = ({
  repeatable,
  fields,
  prefix = "",
  addButtonLabel = "Ajouter",
  removeButtonLabel = "Supprimer",
  initialEmpty = false,
  variant = ERepeaterButtonVariant.Large,
  containerVariant = ERepeaterContainerVariant.Default,
}) => {
  const { values }: any = useFormikContext();
  const name = prefix + repeatable;
  const elements = getIn(values, name);

  return (
    <FieldArray name={name}>
      {(arrayHelpers) => (
        <RepeaterContainer variant={containerVariant}>
          {elements.map((_: any, elementIndex: number) => {
            return (
              <div key={elementIndex}>
                {(elementIndex > 0 || initialEmpty) && (
                  <RepeaterButton
                    first={elementIndex === 0}
                    remove
                    variant={variant}
                    onClick={() =>
                      window.confirm("Confirmer la suppression ?") &&
                      arrayHelpers.remove(elementIndex)
                    } // remove a friend from the list
                  >
                    {removeButtonLabel}
                  </RepeaterButton>
                )}
                {fields.map((field, index) => (
                  <StepField
                    key={index}
                    field={field}
                    prefix={`${name}.${elementIndex}.`}
                  />
                ))}
              </div>
            );
          })}
          <RepeaterButton
            type="button"
            variant={variant}
            onClick={() =>
              arrayHelpers.push(
                flattenPropOfArray(ESearchableProperty.Values, fields)
              )
            }
          >
            {addButtonLabel}
          </RepeaterButton>
        </RepeaterContainer>
      )}
    </FieldArray>
  );
};

export default StepRepeater;
