import React from "react";
import { StyledList } from "./styled-components";

interface Props {
  children: React.ReactNode;
}

const List: React.FC<Props> = ({ children }) => {
  return <StyledList>{children}</StyledList>;
};

export default List;
