import React from "react";
import { Button, Text, Modal, Box } from "../../components";
import { ButtonVariant, IBeforeLastWarningModal } from "../../types";
import { useFormikContext } from "formik";

const BeforeLastWarningModal: React.FC<IBeforeLastWarningModal> = ({
  isOpen = false,
  handleCancel,
  handleConfirm,
}) => {
  const { submitForm } = useFormikContext();
  const handleSubmit = () => {
    handleConfirm();
    submitForm();
  };
  return (
    <Modal
      title="Attention, vous arrivez à la dernière étape"
      isLoading={false}
      open={isOpen}
      handleClose={handleCancel}
    >
      <Text>
        Après cette étape, il vous sera impossible de revenir en arrière.
        Veuillez vérifier toutes les informations que vous avez précédemment
        saisies avant de passer à l'étape suivante.
      </Text>

      <Box jc="space-between">
        <Button
          type="submit"
          variant={ButtonVariant.Secondary}
          onClick={handleSubmit}
          mt
        >
          Confirmer et passer à la dernière étape
        </Button>
        <Button
          type="submit"
          variant={ButtonVariant.Primary}
          onClick={handleCancel}
          mt
        >
          Annuler
        </Button>
      </Box>
    </Modal>
  );
};

export default BeforeLastWarningModal;
