import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { ButtonVariant, ETextVariant } from "../../types";
import { Box, Container, Button, Text } from "../../components";
import api from "../../config/api";
import FormHelper from "../../helpers/FormHelper";

const LOADERS = {
  button: false,
  createButton: false,
  continueButton: false,
};

interface Props {
  formHelper: FormHelper;
}
const FirstStepRenewalFooter: React.FC<Props> = ({ formHelper }) => {
  const [loaders, setLoaders] = useState<any>(LOADERS);
  const [code, setCode] = useState<string>("");
  const [userHash, setUserHash] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);

  const clearLoaders = () => setLoaders(LOADERS);
  const startLoader = (name: string) =>
    setLoaders({
      ...loaders,
      [name]: true,
    });

  const getRenewalCode = useCallback(() => {
    setErrorMessage("");
    startLoader("button");
    api
      .getRenewalCode({
        user_hash: userHash,
        form_type: formHelper.type,
      })
      .then((res) => {
        const { code } = res.data;
        setCode(code);
      })
      .catch((error) => {
        const message = error?.response?.data?.message;
        setErrorMessage(message);
      })
      .finally(() => clearLoaders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHelper.type, userHash]);

  const createEntry = async () => {
    startLoader("createButton");
    const res = await api.createEntry({
      user_hash: userHash,
      form: {
        type: formHelper.type,
        subtype: formHelper.subtype,
      },
    });
    clearLoaders();
    const url = res?.data?.url;
    if (url) {
      window.location.replace(url);
    } else {
      setErrorMessage("");
    }
  };

  const continueEntry = async () => {
    setErrorMessage("");
    startLoader("continueButton");
    api
      .verify({
        code,
        user_hash: userHash,
      })
      .then((res) => {
        const url = res?.data?.url;
        if (url) {
          window.location.href = url;
        } else {
          setErrorMessage("Une erreur est survenue");
        }
      })
      .catch((error) => {
        const message = error?.response?.data?.message;
        setErrorMessage(message);
      })
      .finally(() => clearLoaders());
  };

  useEffect(() => {
    if (userHash) {
      getRenewalCode();
    } else {
      setErrorMessage(
        "Veuillez accéder à ce formulaire depuis votre espace membre sur le site du groupe CNCEF"
      );
    }
  }, [userHash, getRenewalCode]);

  useEffect(() => {
    const userRenewalHash = query.get("user_hash");
    if (userRenewalHash) {
      setUserHash(userRenewalHash);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Box jc="center">
        {errorMessage ? (
          <Text variant={ETextVariant.Body2}>{errorMessage}</Text>
        ) : loaders.button ? (
          <Button
            type="submit"
            variant={ButtonVariant.Secondary}
            mt
            isLoading={!!loaders.button}
          >
            Récupération de vos données
          </Button>
        ) : code ? (
          <Button
            type="submit"
            variant={ButtonVariant.Secondary}
            mt
            isLoading={!!loaders.continueButton}
            onClick={continueEntry}
          >
            Reprendre votre formulaire
          </Button>
        ) : (
          <Button
            type="submit"
            variant={ButtonVariant.Secondary}
            mt
            isLoading={!!loaders.continueButton}
            onClick={createEntry}
          >
            Commencer le formulaire
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default FirstStepRenewalFooter;
