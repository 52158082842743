import React from "react";
import { FieldContainer } from "../../components";
import ReactPhoneInput from "react-phone-input-2";
import fr from "react-phone-input-2/lang/fr.json";
import { StyledWrapper } from "./styled-components";
import "react-phone-input-2/lib/style.css";

interface Props {
  label: string;
  required: boolean;
  name: string;
  value: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}
const PhoneInput: React.FC<Props> = ({
  value,
  label,
  required = true,
  name,
  setFieldValue,
}) => {
  return (
    <FieldContainer label={label} required={required} name={name}>
      <StyledWrapper>
        <ReactPhoneInput
          country={"fr"}
          localization={fr}
          onlyCountries={[
            "fr",
            "re",
            "gf",
            "pf",
            "mq",
            "nc",
            "wf",
            "gp",
            "mf",
            "pm",
            "bl",
          ]}
          value={value}
          enableTerritories
          inputProps={{ name }}
          onChange={(phoneNumber, country, e) => {
            setFieldValue(name, phoneNumber);
          }}
        />
      </StyledWrapper>
    </FieldContainer>
  );
};

export default PhoneInput;
